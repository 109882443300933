import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ERepaymentTypesActionTypes } from '../../enums';
import { IIdAndName } from '../../models';
import { repaymentTypesService } from '../../services';
import { loadingAction, repaymentTypesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ERepaymentTypesActionTypes.GET_REPAYMENT_TYPES as any, watchGetRepaymentTypes)]);
}

function* watchGetRepaymentTypes() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        repaymentTypesGettingFlag: true,
      }),
    );

    const { data } = yield call(repaymentTypesService.endpoint_get_repayment_types);

    yield put(repaymentTypesAction.setRepaymentTypes(data as Array<IIdAndName>));
  } catch (error: any) {
    console.error('watchGetRepaymentTypes: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        repaymentTypesGettingFlag: false,
      }),
    );
  }
}
