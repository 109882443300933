import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';

import { IIdAndName } from '../models';
import {
  selectLenders,
  selectOtherLoanOffersSelectedLenderId,
  selectOtherLoanOffersSelectedYear,
} from '../store/selectors';
import { loanOffersAction } from '../store/actions';
import { getYearsList } from '../utils';

interface OtherLoanOffersFilterProps {}

export const OtherLoanOffersFilter: React.FC<OtherLoanOffersFilterProps> = () => {
  const dispatch = useDispatch();
  const lenders = useSelector(selectLenders);
  const otherOffersSelectedLenderId = useSelector(selectOtherLoanOffersSelectedLenderId);
  const selectedYear = useSelector(selectOtherLoanOffersSelectedYear);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const definedYears = getYearsList();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Autocomplete
          value={[
            {
              id: -1,
              name: 'All',
            } as IIdAndName,
            ...lenders,
          ].find((item) => item.id === otherOffersSelectedLenderId)}
          options={[
            {
              id: -1,
              name: 'All',
            } as IIdAndName,
            ...lenders,
          ]}
          getOptionLabel={(item) => (item.id === -1 ? 'All' : item.name)}
          onChange={(event: any, newValue: any) => {
            const lenderId = newValue?.id;
            dispatch(loanOffersAction.setOtherLoanOffersSelectedLenderId(lenderId));
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                style={
                  option.id === -1
                    ? {
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: theme.palette.divider,
                      }
                    : {}
                }
              >
                {option.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Lender"
              placeholder="Select a Lender"
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            />
          )}
          disableClearable
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Autocomplete
          value={[
            {
              id: -1,
              value: -1,
              label: 'All',
            },
            ...definedYears,
          ].find((year) => year.id === selectedYear)}
          options={[
            {
              id: -1,
              value: -1,
              label: 'All',
            },
            ...definedYears,
          ]}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                style={
                  option.id === -1
                    ? {
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: theme.palette.divider,
                      }
                    : {}
                }
              >
                {option.label}
              </li>
            );
          }}
          onChange={(event: any, newValue: any) => {
            const year = newValue?.id;
            dispatch(loanOffersAction.setOtherLoanOffersSelectedYear(year));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Year"
              placeholder="Year"
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            />
          )}
          disableClearable
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
