/* eslint-disable */
import { createSelector } from 'reselect';

import { selectEfcs } from './efcs.selector';
import { IUserState, IRootState } from '../../models';

export const selectUserState = (rootState: IRootState): IUserState => rootState.userState;

export const selectUser = createSelector([selectUserState], (userState) => userState.user);

export const selectUserId = createSelector([selectUser], (user) => user?.id);
export const selectUserEmail = createSelector([selectUser], (user) => user?.email);
export const selectUserRole = createSelector([selectUser], (user) => user?.role);
export const selectUserTermsOfService = createSelector([selectUser], (user) => user?.termsOfService);
export const selectUserNewsletters = createSelector([selectUser], (user) => user?.newsletters);
export const selectUserStateId = createSelector([selectUser], (user) => user?.stateId);
export const selectUserHighschoolId = createSelector([selectUser], (user) => user?.highschoolId);
export const selectUserEfcId = createSelector([selectUser], (user) => user?.efcId);
export const selectUserGpa = createSelector([selectUser], (user) => user?.gpa);
export const selectUserEntranceExam = createSelector([selectUser], (user) => user?.entranceExam);
export const selectUserSat = createSelector([selectUser], (user) => user?.sat);
export const selectUserAct = createSelector([selectUser], (user) => user?.act);
export const selectUserGender = createSelector([selectUser], (user) => user?.gender);
export const selectUserAthlete = createSelector([selectUser], (user) => user?.athlete);
export const selectUserYearGraduation = createSelector([selectUser], (user) => user?.yearOfHighSchoolGraduation);

export const selectUserProfileCompleted = createSelector(
  [selectUser],
  (user) =>
    user &&
    user.id &&
    user.email &&
    user.role &&
    user.stateId &&
    user.highschoolId &&
    user.efcId &&
    user.gpa &&
    (user.entranceExam ? user.sat || user.act : true) &&
    user.gender && user.athlete !== null && user.athlete !== undefined,
);

export const selectUserEfcName = createSelector(
  [selectUserEfcId, selectEfcs],
  (efcId, efcs) => efcs.find((efc) => efc.id === efcId)?.name,
);

export const selectUserAvaliableYearGraduation = createSelector(
  [selectUser],
  (user) =>
    user?.yearOfHighSchoolGraduation!==null &&
    user?.yearOfHighSchoolGraduation!==undefined &&
    +user?.yearOfHighSchoolGraduation<=new Date().getFullYear()+2
);
