import * as React from 'react';
import { Box, Typography } from '@mui/material';

interface TermsOfServicePageProps {}

export const TermsOfServicePage: React.FC<TermsOfServicePageProps> = () => {
  return (
    <Box
      sx={{
        paddingTop: 4,
        height: 600,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
        }}
      >
        Terms of Service
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          textAlign: 'center',
          marginTop: 2,
        }}
      >
        - Coming soon -
      </Typography>
    </Box>
  );
};
