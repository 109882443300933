import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { AdminEditCollegeForm } from '../forms';
import { dialogsAction } from '../store/actions';
import { selectDialogsState, selectLoadingState, selectSelectedCollege } from '../store/selectors';

interface AdminEditCollegeDialogProps {}

export const AdminEditCollegeDialog: React.FC<AdminEditCollegeDialogProps> = () => {
  const dispatch = useDispatch();
  const { adminEditCollegeDialog = false } = useSelector(selectDialogsState);
  const { collegeUpdatingFlag = false } = useSelector(selectLoadingState);
  const college = useSelector(selectSelectedCollege);
  const formRef = React.useRef<any>();
  const theme = useTheme();

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      dispatch(
        dialogsAction.updateDialogsStatus({
          adminEditCollegeDialog: false,
        }),
      );
    }
  };

  const onSubmit = () => {
    formRef.current.onSubmit();
  };

  return (
    <Dialog fullWidth maxWidth={'md'} scroll={'paper'} onClose={onClose} open={adminEditCollegeDialog}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          paddingY: 1.5,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Edit College</Typography>
          <IconButton
            sx={{
              color: 'white',
            }}
            onClick={() => onClose(null, 'anyway')}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box paddingTop={3}>
          <AdminEditCollegeForm
            ref={formRef}
            college={college}
            next={() =>
              dispatch(
                dialogsAction.updateDialogsStatus({
                  adminEditCollegeDialog: false,
                }),
              )
            }
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          bgcolor: 'grey.A200',
          paddingY: 1.5,
          paddingX: 3,
          justifyContent: 'flex-start',
        }}
      >
        <Button
          onClick={onSubmit}
          color="secondary"
          variant="contained"
          style={{
            color: 'white',
          }}
        >
          Update
          {collegeUpdatingFlag && (
            <CircularProgress
              color="inherit"
              size={16}
              sx={{
                marginLeft: 2,
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
