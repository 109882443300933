import { produce } from 'immer';

import { createReducer } from '.';
import { offersActionTypes } from '../../consts';
import { IAction, ILoanOffer, IOffer, IOfferAccepted, IOfferPublished, IOffersState } from '../../models';
import { offersState } from '../states/offers.state';

export const offersReducer = createReducer<IOffersState>(offersState, {
  [offersActionTypes.SET_MY_OFFERS_SELECTED_OFFER_ID]: setMyOffersSelectedOfferId,
  [offersActionTypes.SET_LOAN_OFFERS_SELECTED_OFFER_ID]: setLoanOffersSelectedOfferId,
  [offersActionTypes.SET_OFFER]: setOffer,
  [offersActionTypes.SET_OFFERS]: setOffers,
  [offersActionTypes.SET_OTHER_OFFERS_SELECTED_COLLEGE_ID]: setOtherOffersSelectedCollegeId,
  [offersActionTypes.SET_OTHER_OFFERS_SELECTED_YEAR]: setOtherOffersSelectedYear,
  [offersActionTypes.SET_OFFERS_ACCEPTED]: setOffersAccepted,
  [offersActionTypes.SET_OFFERS_SELECTED_OFFER_ID]: setOffersSelectedOfferId,
  [offersActionTypes.SET_OFFERS_PUBLISHED]: setOffersPublished,
  [offersActionTypes.SET_PUBLIC_OFFERS]: setPublicOffers,
  [offersActionTypes.SET_LOAN_OFFERS]: setLoanOffers,
});

function setMyOffersSelectedOfferId(state: IOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.myOffersSelectedOfferId = payload;
  });
}

function setLoanOffersSelectedOfferId(state: IOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.loanOffersSelectedOfferId = payload;
  });
}

function setOffer(state: IOffersState, { payload }: IAction<any>) {
  return produce(state, (draft) => {
    if (draft.offers && draft.offers.length > 0) {
      const index = draft.offers?.findIndex((val) => val.id === payload.id);

      draft.offers[index] = { ...payload };
    }
  });
}

function setOffers(state: IOffersState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.offers = payload;
  });
}

function setOtherOffersSelectedCollegeId(state: IOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.otherOffersSelectedCollegeId = payload;
  });
}

function setOtherOffersSelectedYear(state: IOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.otherOffersSelectedYear = payload;
  });
}

function setOffersAccepted(state: IOffersState, { payload }: IAction<Array<IOfferAccepted>>) {
  return produce(state, (draft) => {
    draft.offersAccepted = payload;
  });
}

function setOffersSelectedOfferId(state: IOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.offersSelectedOfferId = payload;
  });
}

function setOffersPublished(state: IOffersState, { payload }: IAction<Array<IOfferPublished>>) {
  return produce(state, (draft) => {
    draft.offersPublished = payload;
  });
}

function setPublicOffers(state: IOffersState, { payload }: IAction<Array<IOffer>>) {
  return produce(state, (draft) => {
    draft.publicOffers = payload;
  });
}

function setLoanOffers(state: IOffersState, { payload }: IAction<Array<ILoanOffer>>) {
  return produce(state, (draft) => {
    draft.loanOffers = payload;
  });
}
