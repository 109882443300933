import { Switch } from '@mui/material';
import { isTodayData, numberWithCommas } from '.';
import { START_YEAR } from '../consts';
import { IOffer, IOfferDetails } from '../models';

export const convertMyOffersToTableData = (offers: Array<IOffer>) => {
  return offers.map((offer) => convertMyOfferToTableData(offer));
};

export const convertMyOfferToTableData = (offer: IOffer) => {
  const { onUpdateAccpeted = () => {} } = offer;

  return {
    id: offer?.id,
    collegeName: offer.collegeName,
    admissionTypeName: offer.admissionTypeName,
    grantFinancialNeed: `$${numberWithCommas(offer.grantFinancialNeed)}`,
    grantFinancialNeedRenewableLabel: offer?.grantFinancialNeedRenewableLabel,
    grantNonFinancialNeed: `$${numberWithCommas(offer.grantNonFinancialNeed)}`,
    grantNonFinancialNeedRenewableLabel: offer.grantNonFinancialNeedRenewableLabel,
    totalPellAndStateGrants: `$${numberWithCommas(offer.totalPellAndStateGrants)}`,
    totalUnsubsidiziedFederalLoans: `$${numberWithCommas(offer.totalUnsubsidiziedFederalLoans)}`,
    totalSubsidizedFederalLoans: `$${numberWithCommas(offer.totalSubsidizedFederalLoans)}`,
    offerCoa: offer.offerCoa ? `$${numberWithCommas(offer.offerCoa)}` : '-',
    netCost: offer.netCost ? `$${numberWithCommas(offer.netCost)}` : '-',
    accepted: <Switch checked={offer.accepted} onChange={() => onUpdateAccpeted(offer.id)} />,
    action: ['edit', 'delete'],
  };
};

export const convertOtherOffersToTableData = (offers: Array<IOffer>) => {
  return offers.map((offer) => convertOtherOfferToTableData(offer));
};

export const convertOtherOfferToTableData = (offer: IOffer) => {
  return {
    collegeNameWithState: offer.collegeNameWithState,
    year: offer.yearLabel,
    acceptedLabel: offer.acceptedLabel,
    userEfcMin: offer.userEfcName,
    userStateCode: offer.userStateCode,
    userGpa: offer.userGpa ? offer.userGpa.toFixed(2) : '',
    userSat: offer.userSat || '',
    userAct: offer.userAct || '',
    admissionTypeName: offer.admissionTypeName,
    grantFinancialNeed: `$${numberWithCommas(Number(offer?.grantFinancialNeed))}`,
    grantNonFinancialNeed: `$${numberWithCommas(Number(offer?.grantNonFinancialNeed))}`,
    totalPellAndStateGrants: `$${numberWithCommas(Number(offer?.totalPellAndStateGrants))}`,
    totalPrivateScholarship: `$${numberWithCommas(Number(offer?.totalPrivateScholarship))}`,
    acceptMoneyLabel: offer.acceptMoneyLabel,
    honorsProgramLabel: offer.honorsProgramLabel,
    updatedAt: offer.updatedAtLabel,
  };
};

export const convertOffersToTableData = (users: Array<IOffer>) => {
  return users.map((offer) => convertOfferToTableData(offer));
};

export const convertOfferToTableData = (offer: IOffer) => {
  const { onUpdatePublished = () => {} } = offer;

  return {
    id: offer.id,
    collegeNameWithState: offer.collegeNameWithState,
    userEmail: offer.userEmail,
    acceptedLabel: offer.acceptedLabel,
    userEfcName: offer.userEfcName,
    grantFinancialNeed: `$${numberWithCommas(Number(offer?.grantFinancialNeed))}`,
    grantNonFinancialNeed: `$${numberWithCommas(Number(offer?.grantNonFinancialNeed))}`,
    yearLabel: offer.yearLabel,
    publishedLabel: offer.publishedLabel,
    published: <Switch checked={offer.published} onChange={() => onUpdatePublished(offer.id)} />,
    action: ['edit'],
  };
};

export const convertTopOffersToTableData = (users: Array<IOffer>) => {
  return users.map((offer) => convertOfferToTableData(offer));
};

export const convertTopOfferToTableData = (offer: IOffer) => {
  return {
    collegeNameWithState: offer.collegeNameWithState,
    userEfcName: offer.userEfcName,
    grantFinancialNeed: `$${numberWithCommas(Number(offer?.grantFinancialNeed))}`,
    grantNonFinancialNeed: `$${numberWithCommas(Number(offer?.grantNonFinancialNeed))}`,
    yearLabel: offer.yearLabel,
  };
};

export const emptyOffer = () => {
  return {
    grantFinancialNeed: 0,
    grantFinancialNeedRenewable: false,
    grantNonFinancialNeed: 0,
    grantNonFinancialNeedRenewable: false,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: 0,
    totalGrantsAndScholarship: 0,
    totalSubsidizedFederalLoans: 0,
    totalUnsubsidiziedFederalLoans: 0,
    totalCostOfAttendance: 0,
    totalCostOfYou: 0,
    workStudyProjectedEarning: 0,
    entraneExam: false,
    acceptMoney: false,
    honorsProgram: false,
    year: getEndYear(),
    grantFinancialNeedRenewableLabel: '',
    grantNonFinancialNeedRenewableLabel: '',
    stackableLabel: '',
  } as IOffer;
};

export const getEndYear = () => {
  const currentYear = new Date().getFullYear() + 1;
  const currentMonth = new Date().getMonth();
  const endYear = currentMonth >= 9 ? currentYear + 1 : currentYear;

  return endYear;
};

export const getNetCost = (
  offerCoa: number,
  grantFinancialNeed: number,
  grantNonFinancialNeed: number,
  totalPellAndStateGrants: number,
) => {
  return offerCoa ? offerCoa - grantFinancialNeed - grantNonFinancialNeed - totalPellAndStateGrants : 0;
};

export const getOfferDetails = (offer: IOffer) => {
  const grantFinancialNeed = offer.grantFinancialNeed
    ? offer.grantFinancialNeed > 500
      ? offer.grantFinancialNeed
      : 0
    : 0;
  const grantNonFinancialNeed = offer.grantNonFinancialNeed
    ? offer.grantNonFinancialNeed > 500
      ? offer.grantNonFinancialNeed
      : 0
    : 0;
  const totalPellAndStateGrants = offer.totalPellAndStateGrants || 0;
  const totalUnsubsidiziedFederalLoans = offer.totalUnsubsidiziedFederalLoans || 0;
  const totalSubsidizedFederalLoans = offer.totalSubsidizedFederalLoans || 0;

  const collegeCoaInState = offer.college?.coaInState || 0;
  const collegeCoaOutState = offer.college?.coaOutState || 0;
  const collegeCoaPrivate = offer.college?.coaPrivate || 0;

  const collegeStateId = offer.college?.stateId;
  const userStateId = offer.user?.stateId;
  const collegeCoa =
    (userStateId && collegeStateId && userStateId === collegeStateId ? collegeCoaInState : collegeCoaOutState) ||
    collegeCoaPrivate ||
    0;
  const myCoa = offer.totalCostOfAttendance || 0;
  const offerCoa = myCoa || collegeCoa;

  const netCost = getNetCost(offerCoa, grantFinancialNeed, grantNonFinancialNeed, totalPellAndStateGrants);

  return {
    grantFinancialNeed,
    grantNonFinancialNeed,
    totalPellAndStateGrants,
    totalUnsubsidiziedFederalLoans,
    totalSubsidizedFederalLoans,
    collegeCoa,
    myCoa,
    offerCoa,
    netCost,
  } as IOfferDetails;
};

export const getYearsList = () => {
  const startYear = START_YEAR;
  const endYear = getEndYear();
  const yearsList = [];

  for (let i = endYear; i >= startYear; i--) {
    yearsList.push({
      id: i,
      value: i,
      label: `${i} - ${i + 1}`,
    });
  }

  return yearsList;
};

export const getOffersCsvHeaders = () => {
  return [
    // User data
    { label: 'User Id', key: 'userId' },
    { label: 'Email', key: 'email' },
    { label: 'Role', key: 'role' },
    { label: 'State of Residence', key: 'userState' },
    { label: 'GPA', key: 'gpa' },
    { label: 'Highschool', key: 'highschool' },
    { label: 'EFC Range', key: 'efc' },
    { label: 'SAT', key: 'sat' },
    { label: 'ACT', key: 'act' },
    { label: 'Student did not take entrance exams (y/n)', key: 'entranceExam' },
    { label: 'Gender', key: 'gender' },
    { label: 'Athlete', key: 'athlete' },

    // College data
    { label: 'College Name', key: 'collegeName' },
    { label: 'Accepted Offer?', key: 'acceptedLabel' },
    { label: 'College State', key: 'collegeState' },
    { label: 'Admission Type', key: 'admissionTypeName' },
    { label: 'Did Student apply without sending test scores (y/n)', key: 'offerEntranceExam' },
    { label: 'Was student accepted but received no money (y/n)', key: 'acceptMoneyLabel' },
    { label: 'Need-Based Aid', key: 'grantFinancialNeed' },
    { label: 'Renewable (y/n)', key: 'grantFinancialNeedRenewableLabel' },
    { label: 'Merit-Based Scholarships', key: 'grantNonFinancialNeed' },
    { label: 'Renewable (y/n)', key: 'grantNonFinancialNeedRenewableLabel' },
    { label: 'Subsidized Loans', key: 'totalSubsidizedFederalLoans' },
    { label: 'Unsubsidized Loans', key: 'totalUnsubsidiziedFederalLoans' },
    { label: 'Pell & State Grants', key: 'totalPellAndStateGrants' },
    { label: 'Work Study', key: 'workStudyProjectedEarning' },
    { label: 'Private Scholarships', key: 'totalPrivateScholarship' },
    { label: 'Stackable', key: 'stackableLabel' },
    { label: 'Cost of Attendance', key: 'offerCoa' },
    { label: 'Net Cost', key: 'totalCostOfYou' },
    { label: 'Got into an honors program', key: 'honorsProgramLabel' },
    { label: 'Year', key: 'year' },
    { label: 'Last Updated At', key: 'updatedAt' },
  ];
};

export const getOffersCsvData = (payload: { data: Array<any>; factor?: string }) => {
  const { data, factor } = payload; // eslint-disable-line

  return data.map((item) => ({
    // User data
    userId: item?.userId || '',
    email: item?.user?.email || '',
    role: item?.user?.role || '',
    userState: item?.userStateCode || '',
    highschool: item?.user?.highschool?.name || '',
    efc: item?.userEfcName || '',
    gpa: item?.userGpa || '',
    entranceExam: item?.user?.entranceExam ? 'Yes' : 'No',
    sat: item?.userSat || '',
    act: item?.userAct || '',
    gender: item?.user?.gender || '',
    athlete: item?.user?.athlete ? 'Yes' : 'No',

    // College data
    collegeName: item?.collegeName || '',
    acceptedLabel: item?.acceptedLabel || '',
    collegeState: item?.collegeStateCode || '',
    admissionTypeName: item?.admissionTypeName || '',
    offerEntranceExam: item?.entranceExam ? 'Yes' : 'No',
    acceptMoneyLabel: item?.acceptMoneyLabel,
    grantFinancialNeed: item?.grantFinancialNeed || 0,
    grantFinancialNeedRenewableLabel: item?.grantFinancialNeedRenewableLabel || '',
    grantNonFinancialNeed: item?.grantNonFinancialNeed || 0,
    grantNonFinancialNeedRenewableLabel: item?.grantNonFinancialNeedRenewableLabel || 0,
    stackableLabel: item?.stackableLabel || '',
    totalPellAndStateGrants: item?.totalPellAndStateGrants || 0,
    totalPrivateScholarship: item?.totalPrivateScholarship || 0,
    totalSubsidizedFederalLoans: item?.totalSubsidizedFederalLoans || 0,
    totalUnsubsidiziedFederalLoans: item?.totalUnsubsidiziedFederalLoans || 0,
    offerCoa: item?.offerCoa || 0,
    totalCostOfYou: item?.totalCostOfYou || 0,
    workStudyProjectedEarning: item?.workStudyProjectedEarning || 0,
    honorsProgramLabel: item?.honorsProgramLabel || '',
    year: item?.yearLabel || '',
    updatedAt: item?.updatedAtLabel || '',
  }));
};

export const offerscsvDataOtherProps = {};

export const isTodayOffer = (offer: IOffer) => {
  if (!offer.createdAt) {
    return false;
  }

  return isTodayData(new Date(offer.createdAt));
};
