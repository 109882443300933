import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const loanTypesService = (function () {
  const endpoint_get_loan_types = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_loan_types);
  };

  return {
    endpoint_get_loan_types,
  };
})();
