import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { selectAdmissionTypes, selectColleges, selectUser } from '../store/selectors';
import { offersAction } from '../store/actions';
import { IOffer } from '../models';
import { emptyCollege, getEndYear, getNetCost, getOfferDetails, getYearsList, numberWithCommas } from '../utils';

interface AdminEditOfferFormProps {
  offer?: IOffer;
  next?: Function;
}

export const AdminEditOfferForm = React.forwardRef((props: AdminEditOfferFormProps, ref) => {
  const { offer, next = () => {} } = props;

  let initialCollegeCoa = 0;
  let myCoa = 0;
  let initialNetCost = 0;

  if (offer) {
    const offerDetails = getOfferDetails(offer);

    initialCollegeCoa = offerDetails.collegeCoa;
    myCoa = offerDetails.myCoa;
    initialNetCost = offerDetails.netCost;
  }

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const colleges = useSelector(selectColleges);
  const admissionTypes = useSelector(selectAdmissionTypes);
  const [isMyCoa, setIsMyCoa] = React.useState<boolean>(!!myCoa);
  const [collegeCoa, setCollegeCoa] = React.useState<number>(initialCollegeCoa);
  const [netCost, setNetCost] = React.useState<number | null>(initialNetCost || null);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const definedYears = getYearsList();

  const refDisabledWheel1 = React.useRef<any>();
  const refDisabledWheel2 = React.useRef<any>();
  const refDisabledWheel3 = React.useRef<any>();
  const refDisabledWheel4 = React.useRef<any>();
  const refDisabledWheel5 = React.useRef<any>();
  const refDisabledWheel6 = React.useRef<any>();
  const refDisabledWheel7 = React.useRef<any>();
  const refDisabledWheel8 = React.useRef<any>();
  const refDisabledWheel9 = React.useRef<any>();

  const refDisabledWheels = [
    refDisabledWheel1,
    refDisabledWheel2,
    refDisabledWheel3,
    refDisabledWheel4,
    refDisabledWheel5,
    refDisabledWheel6,
    refDisabledWheel7,
    refDisabledWheel8,
    refDisabledWheel9,
  ];

  const formManager = useFormik({
    initialValues: {
      collegeId: offer?.collegeId || null,
      entraneExam: offer?.entraneExam || false,
      acceptMoney: offer?.acceptMoney || false,
      admissionTypeId: offer?.admissionTypeId || null,
      honorsProgram: offer?.honorsProgram || false,
      grantFinancialNeed: offer?.grantFinancialNeed || '',
      grantFinancialNeedRenewable: offer?.grantFinancialNeedRenewable || false,
      grantNonFinancialNeed: offer?.grantNonFinancialNeed || '',
      grantNonFinancialNeedRenewable: offer?.grantNonFinancialNeedRenewable || false,
      totalPellAndStateGrants: offer?.totalPellAndStateGrants || 0,
      totalPrivateScholarship: offer?.totalPrivateScholarship || 0,
      totalSubsidizedFederalLoans: offer?.totalSubsidizedFederalLoans || 0,
      totalUnsubsidiziedFederalLoans: offer?.totalUnsubsidiziedFederalLoans || 0,
      workStudyProjectedEarning: offer?.workStudyProjectedEarning || 0,
      totalCostOfAttendance: myCoa || '',
      year: offer?.year || getEndYear(),
    },
    validationSchema: Yup.object({
      collegeId: Yup.number().nullable().required('Select a college'),
      admissionTypeId: Yup.number().nullable().required('Select a admission type'),
      grantFinancialNeed: Yup.number().when(['acceptMoney'], {
        is: (acceptMoney: boolean) => acceptMoney === true,
        then: Yup.number().nullable(),
        otherwise: Yup.number().nullable().min(0, 'The value must be greater than 0'),
      }),
      grantNonFinancialNeed: Yup.number().when(['acceptMoney'], {
        is: (acceptMoney: boolean) => acceptMoney === true,
        then: Yup.number().nullable(),
        otherwise: Yup.number().nullable().min(0, 'The value must be greater than 0'),
      }),
      totalCostOfAttendance: isMyCoa
        ? Yup.number()
            .nullable()
            .min(0, 'The value must be greater than 0')
            .required('Input valid merit-based-scholarship value')
        : Yup.number().nullable(),
      totalPellAndStateGrants: Yup.number().when(['acceptMoney'], {
        is: (acceptMoney: boolean) => acceptMoney === true,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .nullable()
          .min(0, 'The value must be greater than 0')
          .required('Input valid pell-state-grants value'),
      }),
      totalPrivateScholarship: Yup.number().when(['acceptMoney'], {
        is: (acceptMoney: boolean) => acceptMoney === true,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .nullable()
          .min(0, 'The value must be greater than 0')
          .required('Input valid private-scholarship value'),
      }),
      totalUnsubsidiziedFederalLoans: Yup.number().when(['acceptMoney'], {
        is: (acceptMoney: boolean) => acceptMoney === true,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .nullable()
          .min(0, 'The value must be greater than 0')
          .required('Input valid unsubsidized-loans value'),
      }),
      totalSubsidizedFederalLoans: Yup.number().when(['acceptMoney'], {
        is: (acceptMoney: boolean) => acceptMoney === true,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .nullable()
          .min(0, 'The value must be greater than 0')
          .required('Input valid subsidized-loans value'),
      }),
      year: Yup.number().nullable().positive('Offer year must be selected').required('Offer year must be selected'),
    }),
    onSubmit: (values) => {
      const newValue = {
        ...values,
        grantFinancialNeed: Number(values.grantFinancialNeed),
        grantNonFinancialNeed: Number(values.grantNonFinancialNeed),
        totalCostOfAttendance: Number(values.totalCostOfAttendance),
        totalPellAndStateGrants: Number(values.totalPellAndStateGrants),
        totalPrivateScholarship: Number(values.totalPrivateScholarship),
        totalSubsidizedFederalLoans: Number(values.totalSubsidizedFederalLoans),
        totalUnsubsidiziedFederalLoans: Number(values.totalUnsubsidiziedFederalLoans),
        workStudyProjectedEarning: Number(values.workStudyProjectedEarning),
      };

      const newOffer = {
        ...offer,
        ...newValue,
        totalCostOfAttendance: isMyCoa ? newValue.totalCostOfAttendance || null : null,
      } as IOffer;

      dispatch(
        offersAction.updateOffer({
          offer: newOffer,
          next,
        }),
      );
    },
  });

  const onChangeNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else if (val.includes('.')) {
      if (Number(val) > 0) {
        formManager.setFieldValue(fieldName, Number(val));
      } else {
        formManager.setFieldValue(fieldName, val);
      }
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  const onBlurNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  React.useEffect(() => {
    const handleWheel = (e: any) => e.preventDefault();

    refDisabledWheels.forEach((refDisabledWheel) => refDisabledWheel?.current?.addEventListener('wheel', handleWheel));

    return () => {
      refDisabledWheels.forEach((refDisabledWheel) =>
        refDisabledWheel?.current?.removeEventListener('wheel', handleWheel),
      );
    };
  }, []);

  React.useEffect(() => {
    if (formManager.values.acceptMoney) {
      formManager.setFieldValue('grantFinancialNeed', '');
      formManager.setFieldValue('grantNonFinancialNeed', '');
    }
  }, [formManager.values.acceptMoney]);

  React.useEffect(() => {
    if (formManager.values.collegeId) {
      const college = colleges.find((college) => college.id === formManager.values.collegeId);

      if (college) {
        const collegeCoa =
          (college.stateId === user?.stateId ? college.coaInState : college.coaOutState) || college.coaPrivate || 0;
        setCollegeCoa(collegeCoa);
      }
    }
  }, [formManager.values.collegeId]);

  React.useEffect(() => {
    const netCost = getNetCost(
      isMyCoa ? Number(formManager.values.totalCostOfAttendance) || collegeCoa : collegeCoa,
      Number(formManager.values.grantFinancialNeed),
      Number(formManager.values.grantNonFinancialNeed),
      Number(formManager.values.totalPellAndStateGrants),
    );

    if (netCost) {
      setNetCost(netCost);
    } else if (formManager.values.totalCostOfAttendance && isMyCoa) {
      setNetCost(netCost);
    } else {
      setNetCost(null);
    }
  }, [
    isMyCoa,
    formManager.values.totalCostOfAttendance,
    formManager.values.grantFinancialNeed,
    formManager.values.grantNonFinancialNeed,
    formManager.values.totalPellAndStateGrants,
  ]);

  React.useImperativeHandle(
    ref,
    () => ({
      onSubmit() {
        formManager.handleSubmit();
      },
    }),
    [], // eslint-disable-line
  );

  return (
    <Box paddingY={2}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            value={definedYears.find((year) => year.value === formManager.values.year)}
            options={definedYears}
            getOptionLabel={(option) => option.label}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('year', newValue.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year"
                placeholder="Year"
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
              />
            )}
            disableClearable
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={(colleges || []).find((college) => college.id === formManager.values.collegeId) || emptyCollege()}
            options={colleges}
            getOptionLabel={(option) =>
              `${option?.name}${option?.name && option?.stateCode ? ' - ' : ''}${option?.stateCode || ''}` || ''
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option?.name}${option?.name && option?.stateCode ? ' - ' : ''}${option?.stateCode || ''}` || ''}
                </li>
              );
            }}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('collegeId', newValue?.id || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="College"
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
                error={!!(formManager.errors.collegeId && formManager.touched.collegeId)}
                helperText={formManager.touched.collegeId ? formManager.errors.collegeId : null}
              />
            )}
            autoHighlight
            disableClearable
          />

          <Box marginTop={1} marginLeft={0.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!formManager.values.entraneExam}
                  onChange={(event, checked) => {
                    formManager.setFieldValue('entraneExam', !checked);
                  }}
                  sx={{
                    padding: 0.5,
                  }}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: 1.3,
                  }}
                >
                  Applied without submitted ACT and/or SAT scores
                </Typography>
              }
            />
          </Box>

          <Box marginLeft={0.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formManager.values.acceptMoney}
                  onChange={(event, checked) => {
                    formManager.setFieldValue('acceptMoney', checked);
                  }}
                  sx={{
                    padding: 0.5,
                  }}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: 1.3,
                  }}
                >
                  Accepted but didn’t receive any money
                </Typography>
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={admissionTypes.find((item) => item.id === formManager.values.admissionTypeId)}
            options={admissionTypes}
            getOptionLabel={(option) => option.name || ''}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('admissionTypeId', newValue?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Admission Types"
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
                error={!!(formManager.errors.admissionTypeId && formManager.touched.admissionTypeId)}
                helperText={formManager.touched.admissionTypeId ? formManager.errors.admissionTypeId : null}
              />
            )}
            autoHighlight
            disableClearable
          />

          <Box marginTop={1} marginLeft={0.5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formManager.values.honorsProgram}
                  onChange={(event, checked) => {
                    formManager.setFieldValue('honorsProgram', checked);
                  }}
                  sx={{
                    padding: 0.5,
                  }}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: 1.3,
                  }}
                >
                  Accepted to Honors Program
                </Typography>
              }
            />
          </Box>
        </Grid>

        <Tooltip
          title={`"Accepted but didn’t receive any money" option must be unchecked to edit this value.`}
          disableHoverListener={!formManager.values.acceptMoney}
          disableFocusListener={!formManager.values.acceptMoney}
          disableTouchListener={!formManager.values.acceptMoney}
          placement="top"
          arrow
        >
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
              error={
                !!(formManager.errors.grantFinancialNeed && formManager.touched.grantFinancialNeed) &&
                !formManager.values.acceptMoney
              }
              disabled={formManager.values.acceptMoney}
            >
              <InputLabel>Total Grants & Scholarships based on Financial Need</InputLabel>
              <OutlinedInput
                ref={refDisabledWheel1}
                value={formManager.values.grantFinancialNeed}
                onChange={(event) => onChangeNumber(event.target.value, 'grantFinancialNeed')}
                onBlur={(event) => onBlurNumber(event.target.value, 'grantFinancialNeed')}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                type="number"
                label="Total Grants & Scholarships based on Financial Need"
                placeholder="Need Based Aid"
              />
              {!formManager.values.acceptMoney && (
                <FormHelperText>
                  {formManager.touched.grantFinancialNeed ? formManager.errors.grantFinancialNeed : null}
                </FormHelperText>
              )}
            </FormControl>

            <Box marginTop={1} marginLeft={0.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formManager.values.grantFinancialNeedRenewable}
                    onChange={(event, checked) => {
                      formManager.setFieldValue('grantFinancialNeedRenewable', checked);
                    }}
                    sx={{
                      padding: 0.5,
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color={formManager.values.acceptMoney ? 'textSecondary' : 'textPrimary'}
                    sx={{
                      lineHeight: 1.3,
                    }}
                  >
                    Yes, it’s renewable. (I expect to get this every year.)
                  </Typography>
                }
                disabled={formManager.values.acceptMoney}
              />
            </Box>
          </Grid>
        </Tooltip>

        <Tooltip
          title={`"Accepted but didn’t receive any money" option must be unchecked to edit this value.`}
          disableHoverListener={!formManager.values.acceptMoney}
          disableFocusListener={!formManager.values.acceptMoney}
          disableTouchListener={!formManager.values.acceptMoney}
          placement="top"
          arrow
        >
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
              error={
                !!(formManager.errors.grantNonFinancialNeed && formManager.touched.grantNonFinancialNeed) &&
                !formManager.values.acceptMoney
              }
              disabled={formManager.values.acceptMoney}
            >
              <InputLabel>Total Grants & Scholarships *NOT* based on Financial Need</InputLabel>
              <OutlinedInput
                ref={refDisabledWheel2}
                value={formManager.values.grantNonFinancialNeed}
                onChange={(event) => onChangeNumber(event.target.value, 'grantNonFinancialNeed')}
                onBlur={(event) => onBlurNumber(event.target.value, 'grantNonFinancialNeed')}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                type="number"
                label="Total Grants & Scholarships *NOT* based on Financial Need"
                placeholder="Merit Based Scholarship"
              />
              {!formManager.values.acceptMoney && (
                <FormHelperText>
                  {formManager.touched.grantNonFinancialNeed ? formManager.errors.grantNonFinancialNeed : null}
                </FormHelperText>
              )}
            </FormControl>

            <Box marginTop={1} marginLeft={0.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formManager.values.grantNonFinancialNeedRenewable}
                    onChange={(event: any, checked) => {
                      formManager.setFieldValue('grantNonFinancialNeedRenewable', checked);
                    }}
                    sx={{
                      padding: 0.5,
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color={formManager.values.acceptMoney ? 'textSecondary' : 'textPrimary'}
                    sx={{
                      lineHeight: 1.3,
                    }}
                  >
                    Yes, it’s renewable. (I expect to get this every year.)
                  </Typography>
                }
                disabled={formManager.values.acceptMoney}
              />
            </Box>
          </Grid>
        </Tooltip>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={!!(formManager.errors.totalPellAndStateGrants && formManager.touched.totalPellAndStateGrants)}
          >
            <InputLabel>Total Pell & State Grants</InputLabel>
            <OutlinedInput
              ref={refDisabledWheel3}
              value={formManager.values.totalPellAndStateGrants}
              onChange={(event) => onChangeNumber(event.target.value, 'totalPellAndStateGrants')}
              onBlur={(event) => onBlurNumber(event.target.value, 'totalPellAndStateGrants')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              label="Total Pell & State Grants"
            />
            <FormHelperText>
              {formManager.touched.totalPellAndStateGrants ? formManager.errors.totalPellAndStateGrants : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={!!(formManager.errors.totalPrivateScholarship && formManager.touched.totalPrivateScholarship)}
          >
            <InputLabel>Total Private Scholarship</InputLabel>
            <OutlinedInput
              ref={refDisabledWheel4}
              value={formManager.values.totalPrivateScholarship}
              onChange={(event) => onChangeNumber(event.target.value, 'totalPrivateScholarship')}
              onBlur={(event) => onBlurNumber(event.target.value, 'totalPrivateScholarship')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              label="Total Private Scholarship"
            />
            <FormHelperText>
              {formManager.touched.totalPrivateScholarship ? formManager.errors.totalPrivateScholarship : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={
              !!(formManager.errors.totalSubsidizedFederalLoans && formManager.touched.totalSubsidizedFederalLoans)
            }
          >
            <InputLabel>Total Subsidized Federal Loans</InputLabel>
            <OutlinedInput
              ref={refDisabledWheel5}
              value={formManager.values.totalSubsidizedFederalLoans}
              onChange={(event) => onChangeNumber(event.target.value, 'totalSubsidizedFederalLoans')}
              onBlur={(event) => onBlurNumber(event.target.value, 'totalSubsidizedFederalLoans')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              label="Total Subsidized Federal Loans"
            />
            <FormHelperText>
              {formManager.touched.totalSubsidizedFederalLoans ? formManager.errors.totalSubsidizedFederalLoans : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={
              !!(
                formManager.errors.totalUnsubsidiziedFederalLoans && formManager.touched.totalUnsubsidiziedFederalLoans
              )
            }
          >
            <InputLabel>Total Unsubsidized Federal Loans</InputLabel>
            <OutlinedInput
              ref={refDisabledWheel6}
              value={formManager.values.totalUnsubsidiziedFederalLoans}
              onChange={(event) => onChangeNumber(event.target.value, 'totalUnsubsidiziedFederalLoans')}
              onBlur={(event) => onBlurNumber(event.target.value, 'totalUnsubsidiziedFederalLoans')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              label="Total Unsubsidized Federal Loans"
            />
            <FormHelperText>
              {formManager.touched.totalUnsubsidiziedFederalLoans
                ? formManager.errors.totalUnsubsidiziedFederalLoans
                : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={!!(formManager.errors.workStudyProjectedEarning && formManager.touched.workStudyProjectedEarning)}
          >
            <InputLabel>Projected Earnings</InputLabel>
            <OutlinedInput
              ref={refDisabledWheel7}
              value={formManager.values.workStudyProjectedEarning}
              onChange={(event) => onChangeNumber(event.target.value, 'workStudyProjectedEarning')}
              onBlur={(event) => onBlurNumber(event.target.value, 'workStudyProjectedEarning')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              label="Projected Earnings"
            />
            <FormHelperText>
              {formManager.touched.workStudyProjectedEarning ? formManager.errors.workStudyProjectedEarning : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginBottom: 1,
          }}
        >
          <Divider textAlign="left">Net Cost</Divider>
        </Grid>

        <Grid item xs={6}>
          {collegeCoa ? (
            <FormControl fullWidth size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}>
              <InputLabel>Standard Cost of Attendance</InputLabel>
              <OutlinedInput
                ref={refDisabledWheel8}
                value={collegeCoa || ''}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                type="number"
                label="Standard Cost of Attendance"
                disabled
              />
            </FormControl>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 56,
              }}
            >
              <Typography color="textSecondary" variant="body2">
                No standard cost of attendence
              </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={6}>
          {isMyCoa ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <FormControl
                fullWidth
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
                error={!!(formManager.errors.totalCostOfAttendance && formManager.touched.totalCostOfAttendance)}
              >
                <InputLabel>User Cost of Attendance</InputLabel>
                <OutlinedInput
                  ref={refDisabledWheel9}
                  value={formManager.values.totalCostOfAttendance}
                  onChange={(event) => onChangeNumber(event.target.value, 'totalCostOfAttendance')}
                  onBlur={(event) => onBlurNumber(event.target.value, 'totalCostOfAttendance')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  type="number"
                  label="My Cost of Attendance"
                />
                <FormHelperText>
                  {formManager.touched.totalCostOfAttendance ? formManager.errors.totalCostOfAttendance : null}
                </FormHelperText>
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 56,
                  marginLeft: 1,
                }}
              >
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setIsMyCoa(false);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ color: 'white' }}
                onClick={() => {
                  setIsMyCoa(true);
                }}
              >
                Add my cost of attendance
              </Button>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography color="textSecondary">Net Cost: </Typography>
            <Typography
              variant="h5"
              color="secondary"
              sx={{
                marginLeft: 1,
              }}
            >
              {netCost !== null ? `$${numberWithCommas(netCost)}` : '-'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});
