import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { EnhancedTable } from '../../components';
import { usersTableColumns } from '../../configs';
import { DEFAULT_ROWS_OPTIONS_PER_PAGE } from '../../consts';
import { dialogsAction, usersAction } from '../../store/actions';
import { selectUsers } from '../../store/selectors';
import { convertUsersToTableData } from '../../utils';

interface UsersPageProps {}

export const UsersPage: React.FC<UsersPageProps> = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const columns = usersTableColumns;
  const columnKeys = columns.map((item) => item.id);

  const onEdit = (id: number) => {
    dispatch(usersAction.setSelectedUserId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        adminEditUserDialog: true,
      }),
    );
  };

  return (
    <Box>
      <EnhancedTable
        config={{
          toolbar: true,
          rowCheckable: false,
          rowActionVisible: true,
        }}
        loading={false}
        loadingLabel={'Loading Users'}
        noDataLabel={'No User'}
        noDataSectionClassnames={'h-24'}
        toolbarTitle={'Users'}
        data={users}
        convertFunctionForDataToTableData={convertUsersToTableData}
        headColumns={columns}
        columnKeys={columnKeys}
        styles={{
          tableStyle: {
            minWidth: 0,
          },
        }}
        initialRowsPerPage={10}
        visiblePagination={true}
        exportable={false}
        exporting={false}
        filename={''}
        getCsvHeaders={() => {}}
        getCsvData={() => {}}
        csvDataOtherProps={() => {}}
        rowActions={{
          onEdit,
        }}
        definedRowsPerPageOptions={[
          ...DEFAULT_ROWS_OPTIONS_PER_PAGE,
          {
            value: 50,
            label: '50',
          },
          {
            value: 100,
            label: '100',
          },
        ]}
      />
    </Box>
  );
};
