import { produce } from 'immer';

import { createReducer } from '.';
import { loadingActionTypes } from '../../consts';
import { IAction, ILoadingState } from '../../models';
import { loadingState } from '../states/loading.state';

export const loadingReducer = createReducer<ILoadingState>(loadingState, {
  [loadingActionTypes.UPDATE_LOADING_STATUS]: updateLoadingStatus,
});

function updateLoadingStatus(
  state: ILoadingState,
  {
    payload,
  }: IAction<{
    [key: string]: boolean;
  }>,
) {
  return produce(state, (draft) => {
    Object.keys(payload).forEach((key) => {
      draft[key as keyof ILoadingState] = payload[key];
    });
  });
}
