import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const admissionTypesService = (function () {
  const endpoint_get_admission_types = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_admission_types);
  };

  return {
    endpoint_get_admission_types,
  };
})();
