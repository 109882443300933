import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from '../consts';

import { AuthUserLayout } from '../layouts';
import { OnboardingPage } from '../pages';

interface ProfileUncompletedNavigatorProps {}

export const ProfileUncompletedNavigator: React.FC<ProfileUncompletedNavigatorProps> = () => {
  return (
    <AuthUserLayout>
      <Routes>
        <Route path={ROUTES.ONBOARDING} element={<OnboardingPage />} />
        <Route path="/*" element={<Navigate replace to={ROUTES.ONBOARDING} />} />
      </Routes>
    </AuthUserLayout>
  );
};
