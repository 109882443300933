import { isTodayData } from '.';
import { EGender, EUserRoles } from '../enums';
import { IUser } from '../models';

export const convertUsersToTableData = (users: Array<IUser>) => {
  return users.map((user) => convertUserToTableData(user));
};

export const convertUserToTableData = (user: IUser) => {
  return {
    id: user.id,
    userStateCode: user.userStateCode,
    email: user.email,
    name: user.name || '-',
    role: user.role,
    createdAtLabel: user.createdAtLabel,
    userEfcName: user.userEfcName,
    yearOfHighSchoolGraduation: user.yearOfHighSchoolGraduation || '-',
    offers: user.offers,
    action: ['edit'],
  };
};

export const emptyUser = () => {
  return {
    role: EUserRoles.STUDENT,
    termsOfService: false,
    newsletters: false,
    stateId: null,
    highschoolId: null,
    efcId: null,
    gpa: null,
    yearOfHighSchoolGraduation: null,
    entranceExam: false,
    sat: null,
    act: null,
    gender: EGender.FEMALE,
    athlete: false,
  } as IUser;
};

export const isTodayUser = (user: IUser) => {
  if (!user.createdAt) {
    return false;
  }

  return isTodayData(new Date(user.createdAt));
};
