export const apiUrls = {
  // User
  post_get_user: '/user',
  put_user: '/user',

  // Users
  get_users: '/users',

  // States
  get_states: '/states',

  // Efcs
  get_efcs: '/efcs',

  // Admission Types
  get_admission_types: '/admission-types',

  // Lenders
  get_lenders: '/lenders',

  // Loan Terms
  get_loan_terms: '/loan-terms',

  // Loan Types
  get_loan_types: '/loan-types',

  // Repayment Types
  get_repayment_types: '/repayment-types',

  // Highschools
  get_highschools_by_state_id: (stateId: number) => `/highschools?stateId=${stateId}`,

  // Colleges
  get_colleges: '/colleges',
  put_college: (collegeId: number) => `/colleges/${collegeId}`,

  // Credit Score Ranges
  get_credit_score_ranges: '/credit-score-ranges',

  // Offers
  get_all_offers: '/offers',
  get_offers: (userId: number) => `/offers/${userId}`,
  get_public_offers: '/public-offers',
  post_offer: '/offers',
  put_offer: (offerId: number) => `/offers/${offerId}`,
  delete_offer: (offerId: number) => `/offers/${offerId}`,
  get_offers_accepted: '/offers-accepted',
  post_offers_accepted: '/offers-accepted',
  get_offers_published: '/offers-published',
  post_offers_published: '/offers-published',

  // Loan Offers
  get_all_loan_offers: '/loan-offers',
  get_user_loan_offers: (userId: number) => `/loan-offers/${userId}`,
  post_loan_offer: '/loan-offers',
  put_loan_offer: (loanOfferId: number) => `/loan-offers/${loanOfferId}`,
  delete_loan_offer: (loanOfferId: number) => `/loan-offers/${loanOfferId}`,
};
