import { ENotificationActionTypes } from '../../enums';
import { INotificationState } from '../../models';

const updateNotificationStatus = (payload: INotificationState) => {
  return {
    type: ENotificationActionTypes.UPDATE_NOTIFICATION_STATUS,
    payload,
  };
};

export const notificationAction = {
  updateNotificationStatus,
};
