/* eslint-disable */
import './Nav.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { useEffect, useRef, useState } from 'react';

import { ROUTES } from '../consts';

import road2CollegeIcon from '../assets/images/icons/road2college-icon.svg';
import userGratuatedBlack from '../assets/images/icons/graduate-icon-black.svg';
import userGratuatedWhite from '../assets/images/icons/graduate-icon-white.svg';
import { selectUser } from '../store/selectors';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const Nav = () => {
  //Consts
  const user = useSelector(selectUser);

  //Auth0
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  //Dropwdowns
  const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);
  const toggleSupport = () => setSupportDropdownOpen((prevState) => !prevState);

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const toggleProfile = () => setProfileDropdownOpen((prevState) => !prevState);

  const [profileIconActive, setProfileIconActive] = useState(false);
  return (
    <div className="nav-component-container">
      <div className="nav-router">
        <div className="nav-flex">
          <div className="road2CollegeIcon__box">
            <img className="road2CollegeIcon" src={road2CollegeIcon} alt={'title'} />
          </div>

          <div className="nav-link-box">
            <a href={`${process.env.REACT_APP_LEARN_URL}`} target="_blank" className="nav-link">
              LEARN
            </a>
          </div>
          <div className="nav-link-box">
            <a href={`${process.env.REACT_APP_INSIGHTS_URL}`} target="_blank" className="nav-link">
              INSIGHTS
            </a>
          </div>
          <div className="nav-link-box active">
            <a href={`${process.env.REACT_APP_COMPARE_OFFERS_URL}`} target="_blank" className="nav-link">
              COMPARE OFFERS
            </a>
          </div>
        </div>

        <div className="nav-mobile-noAuthenticated nav-flex">
          {!isAuthenticated && (
            <>
              <div className="nav-link-box">
                <span className="nav-link" onClick={loginWithRedirect}>
                  LOGIN
                </span>
              </div>

              <div className="nav-link-button">
                <button
                  onClick={() => {
                    loginWithRedirect({ screen_hint: 'signup' });
                  }}
                  className="btn-get-started"
                >
                  GET STARTED FOR FREE
                </button>
              </div>
            </>
          )}
          {isAuthenticated && (
            <>
              <div
                className="custom-nav"
                onMouseOver={() => setSupportDropdownOpen(true)}
                onFocus={() => setSupportDropdownOpen(true)}
                onMouseLeave={() => setSupportDropdownOpen(false)}
                onBlur={() => setSupportDropdownOpen(false)}
              >
                <Dropdown
                  isOpen={supportDropdownOpen}
                  toggle={() => setSupportDropdownOpen(!supportDropdownOpen)}
                  direction="down"
                  className="dropdown-insights dropdown-insights-support dropdown-bg-none text-black"
                >
                  <DropdownToggle caret className="title-dropdown">
                    SUPPORT
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="https://www.road2college.com/college-insights-faqs/">Contact Us</DropdownItem>
                    <DropdownItem href="https://www.road2college.com/college-insights-faqs/">FAQ</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div
                className="custom-nav"
                onMouseOver={() => {
                  setProfileDropdownOpen(true);
                  setProfileIconActive(!profileIconActive);
                }}
                onFocus={() => {
                  setProfileDropdownOpen(true);
                  setProfileIconActive(!profileIconActive);
                }}
                onMouseLeave={() => setProfileDropdownOpen(false)}
                onBlur={() => setProfileDropdownOpen(false)}
              >
                <Dropdown
                  isOpen={profileDropdownOpen}
                  toggle={() => setProfileDropdownOpen(!profileDropdownOpen)}
                  onClick={() => {
                    setProfileIconActive(!profileIconActive);
                  }}
                  direction="down"
                  className="dropdown-insights dropdown-profile dropdown-bg-none text-black"
                >
                  <DropdownToggle caret>
                    <img
                      src={profileDropdownOpen ? userGratuatedWhite : userGratuatedBlack}
                      alt="profile"
                      className="profile-user"
                    />
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem href={ROUTES.PROFILE}>Your Profile</DropdownItem>
                    <DropdownItem onClick={() => logout()}>Log Out</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
