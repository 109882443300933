export const ROUTES = {
  // Admin pages
  COLLEGES: '/colleges',
  DASHBOARD: '/dashboard',
  OFFERS: '/offers',
  LOAN_OFFERS: '/loan-offers',
  USERS: '/users',

  // User pages
  COMPARE_MY_OFFERS: '/compare-my-offers',
  COMPARE_OTHER_OFFERS: '/compare-other-offers',
  COMPARE_MY_LOANS: '/compare-my-loans',
  COMPARE_OTHER_LOANS: '/compare-other-loans',
  ONBOARDING: '/onboarding',
  PROFILE: '/profile',

  // Unauthenticated pages
  HOME: '/',
  TERMS_OF_SERVICE: '/terms-of-service',
};
