import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Add } from '@mui/icons-material';

import { PageMainTabs, EnhancedTable, OtherLoanOffersFilter, TitleSection } from '../../components';
import { otherLoanOffersTableColumns, palette } from '../../configs';
import { convertOtherLoanOffersToTableData } from '../../utils';
import { dialogsAction } from '../../store/actions';
import { selectLoadingState, selectLoanOffersFiltered } from '../../store/selectors';

interface CompareOtherLoansPageProps {}

export const CompareOtherLoansPage: React.FC<CompareOtherLoansPageProps> = () => {
  const dispatch = useDispatch();
  const offers = useSelector(selectLoanOffersFiltered);
  const { loanOffersGettingFlag = false } = useSelector(selectLoadingState);

  const columns = otherLoanOffersTableColumns;
  const columnKeys = columns.map((item) => item.id);

  return (
    <Box sx={{ width: '100%' }}>
      <PageMainTabs />

      <Box sx={{ py: 5 }}>
        <TitleSection
          title={'Other Loan Offers'}
          color={palette.primary.dark}
          createButtonLabel="Add Loan"
          startIcon={<Add />}
          onCreateButton={() => {
            dispatch(
              dialogsAction.updateDialogsStatus({
                loanOfferCreateDialog: true,
              }),
            );
          }}
        />

        <Box marginTop={4}>
          <OtherLoanOffersFilter />
        </Box>

        <Box paddingY={4}>
          <EnhancedTable
            config={{
              toolbar: true,
              rowCheckable: false,
              rowActionVisible: false,
            }}
            loading={loanOffersGettingFlag}
            loadingLabel={'Loading Loan Offers'}
            noDataLabel={'No Loan Offer'}
            noDataSectionClassnames={'h-24'}
            toolbarTitle="Loan Offers"
            data={offers}
            convertFunctionForDataToTableData={convertOtherLoanOffersToTableData}
            headColumns={columns}
            columnKeys={columnKeys}
            styles={{
              tableStyle: {
                minWidth: 0,
              },
            }}
            initialRowsPerPage={10}
            visiblePagination={true}
            rowActions={{}}
          />
        </Box>
      </Box>
    </Box>
  );
};
