import { EDialogsActionTypes } from '../../enums';
import { IDialogsState } from '../../models';

const updateDialogsStatus = (payload: IDialogsState) => {
  return {
    type: EDialogsActionTypes.UPDATE_DIALOGS_STATUS,
    payload,
  };
};

export const dialogsAction = {
  updateDialogsStatus,
};
