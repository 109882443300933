import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { START_YEAR } from '../consts';
import { selectUsers, selectOffersDetailed } from '../store/selectors';

interface DashboardChartProps {}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const todayYear = new Date().getFullYear();
const todayMonth = new Date().getMonth();

const years: Array<number> = [];
for (let i = START_YEAR; i <= todayYear; i++) {
  years.push(i);
}

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const generateLabels = (year: number, month: number) => {
  if (year === -1) {
    return years.map((y) => `${y}`);
  }

  if (month === -1) {
    return monthLabels;
  }

  const labels: Array<string> = [];
  const lastday = new Date(year, month + 1, 0).getDate();

  for (let i = 1; i <= lastday; i++) {
    labels.push(`${i}`);
  }

  return labels;
};

export const DashboardChart: React.FC<DashboardChartProps> = () => {
  const users = useSelector(selectUsers);
  const offers = useSelector(selectOffersDetailed);
  const [year, setYear] = React.useState<number>(todayYear);
  const [month, setMonth] = React.useState<number>(todayMonth);
  const [data, setData] = React.useState<any>(null);
  const theme = useTheme();

  React.useEffect(() => {
    const labels = generateLabels(year, month);

    if (year === -1) {
      const usersCounts: Array<number> = [];
      const offersCounts: Array<number> = [];

      for (let i = 0; i < years.length; i++) {
        const y = years[i];

        const usersCount = users.filter(
          (item) => item.createdAt && new Date(item.createdAt).getFullYear() === y,
        ).length;
        const offersCount = offers.filter(
          (item) => item.createdAt && new Date(item.createdAt).getFullYear() === y,
        ).length;

        usersCounts.push(usersCount);
        offersCounts.push(offersCount);
      }

      const data = {
        labels,
        datasets: [
          {
            label: 'Users',
            data: usersCounts,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          },
          {
            label: 'Offers',
            data: offersCounts,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
          },
        ],
      };

      setData(data);
    } else if (month === -1) {
      const usersCounts: Array<number> = [];
      const offersCounts: Array<number> = [];

      for (let i = 0; i < months.length; i++) {
        const m = months[i];

        const usersCount = users.filter(
          (item) =>
            item.createdAt &&
            new Date(item.createdAt).getFullYear() === year &&
            new Date(item.createdAt).getMonth() === m,
        ).length;
        const offersCount = offers.filter(
          (item) =>
            item.createdAt &&
            new Date(item.createdAt).getFullYear() === year &&
            new Date(item.createdAt).getMonth() === m,
        ).length;

        usersCounts.push(usersCount);
        offersCounts.push(offersCount);
      }

      const data = {
        labels,
        datasets: [
          {
            label: 'Users',
            data: usersCounts,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          },
          {
            label: 'Offers',
            data: offersCounts,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
          },
        ],
      };

      setData(data);
    } else {
      const lastday = new Date(year, month + 1, 0).getDate();

      const usersCounts: Array<number> = [];
      const offersCounts: Array<number> = [];

      for (let i = 1; i <= lastday; i++) {
        const d = i;

        const usersCount = users.filter(
          (item) =>
            item.createdAt &&
            new Date(item.createdAt).getFullYear() === year &&
            new Date(item.createdAt).getMonth() === month &&
            new Date(item.createdAt).getDate() === d,
        ).length;
        const offersCount = offers.filter(
          (item) =>
            item.createdAt &&
            new Date(item.createdAt).getFullYear() === year &&
            new Date(item.createdAt).getMonth() === month &&
            new Date(item.createdAt).getDate() === d,
        ).length;

        usersCounts.push(usersCount);
        offersCounts.push(offersCount);
      }

      const data = {
        labels,
        datasets: [
          {
            label: 'Users',
            data: usersCounts,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          },
          {
            label: 'Offers',
            data: offersCounts,
            borderColor: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
          },
        ],
      };

      setData(data);
    }
  }, [year, month]);

  if (!data) {
    return null;
  }

  return (
    <Paper>
      <Box
        sx={{
          paddingX: 6,
          paddingTop: 5,
          paddingBottom: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Users and Offers</Typography>
        </Box>

        <FormControl
          sx={{
            width: 120,
            marginLeft: 'auto',
          }}
          size="small"
        >
          <InputLabel>Year</InputLabel>
          <Select label="Year" value={year} onChange={(event) => setYear(Number(event.target.value))}>
            <MenuItem value={-1}>All</MenuItem>
            {years.map((y) => (
              <MenuItem key={`year-${y}`} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {year !== -1 && (
          <FormControl
            sx={{
              width: 120,
              marginLeft: 2,
            }}
            size="small"
          >
            <InputLabel>Month</InputLabel>
            <Select label="Month" value={month} onChange={(event) => setMonth(Number(event.target.value))}>
              <MenuItem value={-1}>All</MenuItem>
              {months.map((m) => (
                <MenuItem key={`month-${m}`} value={m}>
                  {monthLabels[m]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: 12,
              backgroundColor: theme.palette.primary.main,
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginLeft: 1,
            }}
          >
            Users
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 4,
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: 12,
              backgroundColor: theme.palette.secondary.main,
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginLeft: 1,
            }}
          >
            Offers
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          paddingX: 4,
          paddingTop: 3,
          paddingBottom: 4,
        }}
      >
        <Line options={options} data={data} />
      </Box>
    </Paper>
  );
};

export const options = {
  responsive: true,
  aspectRatio: 3.7,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
