import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ECollegesActionTypes } from '../../enums';
import { ICollege } from '../../models';
import { collegeService } from '../../services';
import { loadingAction, collegesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ECollegesActionTypes.GET_COLLEGES as any, watchGetColleges)]);
  yield all([takeLatest(ECollegesActionTypes.UPDATE_COLLEGE as any, watchUpdateCollege)]);
}

function* watchGetColleges() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getCollegesLoading: true,
      }),
    );

    const { data } = yield call(collegeService.endpoint_get_colleges);

    yield put(collegesAction.setColleges(data as Array<ICollege>));

    yield put(
      loadingAction.updateLoadingStatus({
        getCollegesLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetColleges: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getCollegesLoading: false,
      }),
    );
  }
}

function* watchUpdateCollege(action: {
  type: string;
  payload: {
    college: ICollege;
    next: Function;
  };
}) {
  try {
    const { college, next } = action.payload;

    yield put(
      loadingAction.updateLoadingStatus({
        collegeUpdatingFlag: true,
      }),
    );

    const { data } = yield call(collegeService.endpoint_update_college, {
      collegeId: college.id,
      data: college,
    });

    yield put(collegesAction.setCollege(data as ICollege));

    next();
  } catch (error: any) {
    console.error('watchUpdateOffer: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        collegeUpdatingFlag: false,
      }),
    );
  }
}
