import { ELoadingActionTypes } from '../../enums';
import { ILoadingState } from '../../models';

const updateLoadingStatus = (payload: ILoadingState) => {
  return {
    type: ELoadingActionTypes.UPDATE_LOADING_STATUS,
    payload,
  };
};

export const loadingAction = {
  updateLoadingStatus,
};
