import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Circle } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { definedLifeOfLoans, palette } from '../configs';
import { EUserRoles } from '../enums';
import { ILoanOffer } from '../models';
import { loanOffersAction } from '../store/actions';
import {
  selectColleges,
  selectCreditScoreRanges,
  selectLenders,
  selectLoanTerms,
  selectLoanTypes,
  selectMyOffersColleges,
  selectRepaymentTypes,
  selectUserRole,
} from '../store/selectors';
import { emptyCollege, getCreditScoreRangeColor, getEndYear, getYearsList } from '../utils';

interface MyLoanOfferFormProps {
  offer?: ILoanOffer;
  next?: Function;
}

export const MyLoanOfferForm = React.forwardRef((props: MyLoanOfferFormProps, ref) => {
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);
  const colleges = useSelector(role === EUserRoles.ADMIN ? selectColleges : selectMyOffersColleges);
  const creditScoreRanges = useSelector(selectCreditScoreRanges);
  const lenders = useSelector(selectLenders);
  const loanTerms = useSelector(selectLoanTerms);
  const repaymentTypes = useSelector(selectRepaymentTypes);
  const loanTypes = useSelector(selectLoanTypes);

  const { offer, next = () => {} } = props;
  const theme = useTheme();
  const windowSize = useWindowSize();

  const loanLifes = definedLifeOfLoans;
  const definedYears = getYearsList();

  const formManager = useFormik({
    initialValues: {
      year: offer?.year || getEndYear(),
      collegeId: offer?.collegeId || null,
      lenderId: offer?.lenderId || null,
      interestRate: offer?.interestRate || null,
      repaymentTypeId: offer?.repaymentTypeId || null,
      loanTermId: offer?.loanTermId || null,
      loanLife: offer?.loanLife || 5,
      coSignor: offer?.coSignor || false,
      verifySchool: offer?.verifySchool || false,
      monthlyPaymentAmount: offer?.monthlyPaymentAmount || null,
      totalLoanAmount: offer?.totalLoanAmount || null,
      loanTypeId: offer?.loanTypeId || null,
      creditScoreRangeId: offer?.creditScoreRangeId || null,
    },
    validationSchema: Yup.object({
      year: Yup.number()
        .nullable()
        .positive('Loan offer year must be selected')
        .required('Loan offer year must be selected'),
      collegeId: Yup.number().nullable().required('Select a college'),
      lenderId: Yup.number().nullable().required('Select a lender'),
      interestRate: Yup.number()
        .nullable()
        .required('The value is required')
        .min(0, 'The value must be greater than 0')
        .max(100, 'The value must be less than 100'),
      repaymentTypeId: Yup.number().nullable().required('Select a repayment type'),
      loanTermId: Yup.number().nullable().required('Select a term of loan'),
      monthlyPaymentAmount: Yup.number().nullable().min(0, 'The value must be greater than 0'),
      totalLoanAmount: Yup.number()
        .nullable()
        .required('The value is required')
        .min(0, 'The value must be greater than 0'),
      loanTypeId: Yup.number().nullable().required('Select a type of loan'),
      creditScoreRangeId: Yup.number().nullable().required('Select a credit score range'),
    }),
    onSubmit: (values) => {
      const newValue = {
        ...values,
        interestRate: Number(values.interestRate),
        monthlyPaymentAmount: Number(values.monthlyPaymentAmount),
        totalLoanAmount: Number(values.totalLoanAmount),
      };

      if (offer) {
        dispatch(
          loanOffersAction.updateLoanOffer({
            data: newValue as Partial<ILoanOffer>,
            next,
          }),
        );
      } else {
        dispatch(
          loanOffersAction.createLoanOffer({
            data: newValue as Partial<ILoanOffer>,
            next,
          }),
        );
      }
    },
  });

  React.useImperativeHandle(
    ref,
    () => ({
      onSubmit() {
        formManager.handleSubmit();
      },
    }),
    [],
  );

  const onChangeNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else if (val.includes('.')) {
      if (Number(val) > 0) {
        formManager.setFieldValue(fieldName, Number(val));
      } else {
        formManager.setFieldValue(fieldName, val);
      }
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  const onBlurNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  return (
    <Box paddingY={2}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            value={definedYears.find((year) => year.value === formManager.values.year)}
            options={definedYears}
            getOptionLabel={(option) => option.label}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('year', newValue.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Academic School Year"
                placeholder="Academic School Year"
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
              />
            )}
            disableClearable
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={(colleges || []).find((college) => college.id === formManager.values.collegeId) || emptyCollege()}
            options={colleges}
            getOptionLabel={(option) =>
              `${option?.name}${option?.name && option?.stateCode ? ' - ' : ''}${option?.stateCode || ''}` || ''
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option?.name}${option?.name && option?.stateCode ? ' - ' : ''}${option?.stateCode || ''}` || ''}
                </li>
              );
            }}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('collegeId', newValue?.id || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="College"
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
                error={!!(formManager.errors.collegeId && formManager.touched.collegeId)}
                helperText={formManager.touched.collegeId ? formManager.errors.collegeId : null}
              />
            )}
            autoHighlight
            disableClearable
            disabled={role === EUserRoles.ADMIN ? false : !!offer}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={(lenders || []).find((lender) => lender.id === formManager.values.lenderId)}
            options={lenders}
            getOptionLabel={(option) => option.name}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('lenderId', newValue?.id || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lender"
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
                error={!!(formManager.errors.lenderId && formManager.touched.lenderId)}
                helperText={formManager.touched.lenderId ? formManager.errors.lenderId : null}
              />
            )}
            autoHighlight
            disableClearable
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={!!(formManager.errors.interestRate && formManager.touched.interestRate)}
          >
            <InputLabel>APR</InputLabel>
            <OutlinedInput
              value={formManager.values.interestRate}
              onChange={(event) => onChangeNumber(event.target.value, 'interestRate')}
              onBlur={(event) => onBlurNumber(event.target.value, 'interestRate')}
              startAdornment={<InputAdornment position="start">%</InputAdornment>}
              type="number"
              label="APR"
              placeholder="Enter APR"
            />
            <FormHelperText>{formManager.touched.interestRate ? formManager.errors.interestRate : null}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!(formManager.errors.loanTermId && formManager.touched.loanTermId)}>
            <InputLabel>Rate Type</InputLabel>
            <Select
              label="Rate Type"
              value={formManager.values.loanTermId}
              onChange={(event) => formManager.setFieldValue('loanTermId', event.target.value)}
            >
              {loanTerms.map((item, index) => (
                <MenuItem key={`term-of-loan-${index}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formManager.touched.loanTermId ? formManager.errors.loanTermId : null}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!(formManager.errors.repaymentTypeId && formManager.touched.repaymentTypeId)}>
            <InputLabel>Repayment Type</InputLabel>
            <Select
              label="Repayment Type"
              value={formManager.values.repaymentTypeId}
              onChange={(event) => formManager.setFieldValue('repaymentTypeId', event.target.value)}
            >
              {repaymentTypes.map((item, index) => (
                <MenuItem key={`repayment-type-${index}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formManager.touched.repaymentTypeId ? formManager.errors.repaymentTypeId : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Repayment Length</InputLabel>
            <Select
              label="Repayment Length"
              value={formManager.values.loanLife}
              onChange={(event) => formManager.setFieldValue('loanLife', Number(event.target.value))}
            >
              {loanLifes.map((item) => (
                <MenuItem key={`life-of-loan-${item}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600,
              marginTop: 1,
            }}
          >
            Cosigner
          </Typography>
          <RadioGroup
            value={formManager.values.coSignor}
            onChange={(event, value) => {
              formManager.setFieldValue('coSignor', value);
            }}
          >
            <Grid container spacing={{ xs: 1, md: 4 }}>
              <Grid item xs={6}>
                <FormControlLabel
                  value={true}
                  control={<Radio style={{ color: palette.primary.dark }} />}
                  label={
                    <Typography
                      sx={{
                        lineHeight: 1.3,
                      }}
                    >
                      Yes
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  value={false}
                  control={<Radio style={{ color: palette.primary.dark }} />}
                  label={
                    <Typography
                      sx={{
                        lineHeight: 1.3,
                      }}
                    >
                      No
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={6}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
                marginTop: 1,
              }}
            >
              Accepted School
            </Typography>
            <Switch
              checked={formManager.values.verifySchool}
              onChange={(_, checked) => formManager.setFieldValue('verifySchool', checked)}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={!!(formManager.errors.monthlyPaymentAmount && formManager.touched.monthlyPaymentAmount)}
          >
            <InputLabel>Monthly Payment Amount</InputLabel>
            <OutlinedInput
              // ref={refDisabledWheel2}
              value={formManager.values.monthlyPaymentAmount}
              onChange={(event) => onChangeNumber(event.target.value, 'monthlyPaymentAmount')}
              onBlur={(event) => onBlurNumber(event.target.value, 'monthlyPaymentAmount')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              label="Monthly Payment Amount"
              placeholder="Monthly Payment Amount"
            />
            <FormHelperText>
              {formManager.touched.monthlyPaymentAmount ? formManager.errors.monthlyPaymentAmount : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl
            fullWidth
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            error={!!(formManager.errors.totalLoanAmount && formManager.touched.totalLoanAmount)}
          >
            <InputLabel>Total Loan Amount</InputLabel>
            <OutlinedInput
              // ref={refDisabledWheel2}
              value={formManager.values.totalLoanAmount}
              onChange={(event) => onChangeNumber(event.target.value, 'totalLoanAmount')}
              onBlur={(event) => onBlurNumber(event.target.value, 'totalLoanAmount')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              label="Total Loan Amount"
              placeholder="Total Loan Amount"
            />
            <FormHelperText>
              {formManager.touched.totalLoanAmount ? formManager.errors.totalLoanAmount : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            error={!!(formManager.errors.creditScoreRangeId && formManager.touched.creditScoreRangeId)}
          >
            <InputLabel>Credit Score Range</InputLabel>
            <Select
              label="Credit Score Range"
              value={formManager.values.creditScoreRangeId}
              onChange={(event) => formManager.setFieldValue('creditScoreRangeId', event.target.value)}
              renderValue={(value) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: 2,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 36,
                    }}
                  >
                    <Circle
                      fontSize="small"
                      sx={{
                        fill: getCreditScoreRangeColor(creditScoreRanges.find((item) => item.id === value)),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      margin: 0,
                    }}
                  >
                    {creditScoreRanges.find((item) => item.id === value)?.start} -{' '}
                    {creditScoreRanges.find((item) => item.id === value)?.end}
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    {creditScoreRanges.find((item) => item.id === value)?.status}
                  </Typography>
                </Box>
              )}
            >
              {creditScoreRanges.map((item, index) => (
                <MenuItem key={`credit-score-range-${index}`} value={item.id}>
                  <ListItemIcon>
                    <Circle
                      fontSize="small"
                      sx={{
                        fill: getCreditScoreRangeColor(item),
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {item.start} - {item.end}
                  </ListItemText>
                  <Typography variant="body2" color="text.secondary">
                    {item.status}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formManager.touched.creditScoreRangeId ? formManager.errors.creditScoreRangeId : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!(formManager.errors.loanTypeId && formManager.touched.loanTypeId)}>
            <InputLabel>Loan Type</InputLabel>
            <Select
              label="Loan Type"
              value={formManager.values.loanTypeId}
              onChange={(event) => formManager.setFieldValue('loanTypeId', event.target.value)}
            >
              {loanTypes.map((item, index) => (
                <MenuItem key={`loan-type-${index}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formManager.touched.loanTypeId ? formManager.errors.loanTypeId : null}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
});
