import { ELoanOffersActionTypes } from '../../enums';
import { ILoanOffer } from '../../models';

const getLoanOffers = () => {
  return {
    type: ELoanOffersActionTypes.GET_LOAN_OFFERS,
  };
};

const setLoanOffers = (payload: Array<ILoanOffer>) => {
  return {
    type: ELoanOffersActionTypes.SET_LOAN_OFFERS,
    payload,
  };
};

const createLoanOffer = (payload: { data: Partial<ILoanOffer>; next: Function }) => {
  return {
    type: ELoanOffersActionTypes.CREATE_LOAN_OFFER,
    payload,
  };
};

const updateLoanOffer = (payload: { data: Partial<ILoanOffer>; next: Function }) => {
  return {
    type: ELoanOffersActionTypes.UPDATE_LOAN_OFFER,
    payload,
  };
};

const deleteLoanOffer = (payload: { next: Function }) => {
  return {
    type: ELoanOffersActionTypes.DELETE_LOAN_OFFER,
    payload,
  };
};

const setMyLoanOffersSelectedCollegeId = (payload: number) => {
  return {
    type: ELoanOffersActionTypes.SET_MY_LOAN_OFFERS_SELECTED_COLLEGE_ID,
    payload,
  };
};

const setMyLoanOffersSelectedYear = (payload: number) => {
  return {
    type: ELoanOffersActionTypes.SET_MY_LOAN_OFFERS_SELECTED_YEAR,
    payload,
  };
};

const setMyLoanOffersSelectedOfferId = (payload: number) => {
  return {
    type: ELoanOffersActionTypes.SET_MY_LOAN_OFFERS_SELECTED_OFFER_ID,
    payload,
  };
};

const setOtherLoanOffersSelectedLenderId = (payload: number) => {
  return {
    type: ELoanOffersActionTypes.SET_OTHER_LOAN_OFFERS_SELECTED_LENDER_ID,
    payload,
  };
};

const setOtherLoanOffersSelectedYear = (payload: number) => {
  return {
    type: ELoanOffersActionTypes.SET_OTHER_LOAN_OFFERS_SELECTED_YEAR,
    payload,
  };
};

export const loanOffersAction = {
  getLoanOffers,
  setLoanOffers,
  createLoanOffer,
  updateLoanOffer,
  deleteLoanOffer,
  setMyLoanOffersSelectedCollegeId,
  setMyLoanOffersSelectedYear,
  setMyLoanOffersSelectedOfferId,
  setOtherLoanOffersSelectedLenderId,
  setOtherLoanOffersSelectedYear,
};
