import { Reducer, combineReducers } from 'redux';

import { IAction, IRootState } from '../../models';
import { admissionTypesReducer } from './admission-types.reducer';
import { collegesReducer } from './colleges.reducer';
import { creditScoreRangesReducer } from './credit-score-ranges.reducer';
import { dialogsReducer } from './dialogs.reducer';
import { efcsReducer } from './efcs.reducer';
import { highschoolsReducer } from './highschools.reducer';
import { lendersReducer } from './lenders.reducer';
import { loadingReducer } from './loading.reducer';
import { loanOffersReducer } from './loan-offers.reducer';
import { loanTermsReducer } from './loan-terms.reducer';
import { loanTypesReducer } from './loan-types.reducer';
import { notificationReducer } from './notification.reducer';
import { offersReducer } from './offers.reducer';
import { repaymentTypesReducer } from './repayment-types.reducer';
import { statesReducer } from './states.reducer';
import { userReducer } from './user.reducer';
import { usersReducer } from './users.reducer';

export function createReducer<S>(initialState: S, handlers: any): Reducer<S> {
  const r = (state: S = initialState, action: IAction<S>): S => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };

  return r as Reducer<S>;
}

const rootReducer: Reducer<IRootState> = combineReducers({
  admissionTypesState: admissionTypesReducer,
  collegesState: collegesReducer,
  creditScoreRangesState: creditScoreRangesReducer,
  dialogsState: dialogsReducer,
  efcsState: efcsReducer,
  highschoolsState: highschoolsReducer,
  lendersState: lendersReducer,
  loadingState: loadingReducer,
  loanOffersState: loanOffersReducer,
  loanTermsState: loanTermsReducer,
  loanTypesState: loanTypesReducer,
  notificationState: notificationReducer,
  offersState: offersReducer,
  repaymentTypesState: repaymentTypesReducer,
  statesState: statesReducer,
  userState: userReducer,
  usersState: usersReducer,
});

export default rootReducer;
