import { all, call, put, takeLatest } from 'redux-saga/effects';

import { EAdmissionTypesActionTypes } from '../../enums';
import { IIdAndName } from '../../models';
import { admissionTypesService } from '../../services';
import { loadingAction, admissionTypesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EAdmissionTypesActionTypes.GET_ADMISSION_TYPES as any, watchGetAdmissionTypes)]);
}

function* watchGetAdmissionTypes() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getAdmissionTypesLoading: true,
      }),
    );

    const { data } = yield call(admissionTypesService.endpoint_get_admission_types);

    yield put(admissionTypesAction.setAdmissionTypes(data as Array<IIdAndName>));
  } catch (error: any) {
    console.error('watchGetAdmissionTypes: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        getAdmissionTypesLoading: false,
      }),
    );
  }
}
