import * as React from 'react';
import { Box, IconButton, Popover, TextField, useTheme } from '@mui/material';
import { Clear } from '@mui/icons-material';

import { IEnhancedTableTextFilter } from '../models';

export interface EnhancedTableTextFilterProps {
  anchorEl: HTMLButtonElement | null;
  anchorId: string;
  anchorLabel: string | React.ReactNode;
  textFilters: IEnhancedTableTextFilter;
  handleClose: () => void;
  updateText: (filterName: string, value: string) => void;
}

export const EnhancedTableTextFilter: React.FC<EnhancedTableTextFilterProps> = (props) => {
  const { anchorEl, anchorLabel, anchorId, textFilters, handleClose, updateText } = props;
  const theme = useTheme();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingY: 1.5,
          paddingX: 2,
          borderBottomWidth: 1,
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: 'solid',
          position: 'sticky',
          top: 0,
          bgcolor: 'white',
          zIndex: 10,
        }}
      >
        <TextField
          label={anchorLabel}
          variant="outlined"
          fullWidth
          size="small"
          value={textFilters[anchorId]}
          onChange={(e) => {
            updateText(anchorId, e.target.value);
          }}
        />
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            if (textFilters[anchorId]) {
              updateText(anchorId, '');
            } else {
              handleClose();
            }
          }}
          sx={{
            marginLeft: 1,
            marginRight: -1,
          }}
        >
          <Clear fontSize="small" />
        </IconButton>
      </Box>
    </Popover>
  );
};
