import { all, call, put, takeLatest } from 'redux-saga/effects';

import { EHighschoolsActionTypes } from '../../enums';
import { IHighschool } from '../../models';
import { highschoolsService } from '../../services';
import { makeHighshcoolNotListedOptionAsTopOption } from '../../utils';
import { loadingAction, highschoolsAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EHighschoolsActionTypes.GET_HIGHSCHOOLS as any, watchGetHighschools)]);
}

function* watchGetHighschools(action: { type: string; payload: { stateId: number } }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getHighschoolsLoading: true,
      }),
    );

    const { stateId } = action.payload;
    const { data } = yield call(highschoolsService.endpoint_get_highschools_by_state_id, stateId);

    const highschools = makeHighshcoolNotListedOptionAsTopOption(data as Array<IHighschool>);
    yield put(highschoolsAction.setHighschools(highschools));

    yield put(
      loadingAction.updateLoadingStatus({
        getHighschoolsLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetHighschools: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getHighschoolsLoading: false,
      }),
    );
  }
}
