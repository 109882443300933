import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const repaymentTypesService = (function () {
  const endpoint_get_repayment_types = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_repayment_types);
  };

  return {
    endpoint_get_repayment_types,
  };
})();
