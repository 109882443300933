import { sortStringArray } from '.';
import { ETableOrder, IEnhancedTableCheckboxFilter, IEnhancedTableHeadCell, IEnhancedTableTextFilter } from '../models';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let aVal: any = a[orderBy];
  let bVal: any = b[orderBy];

  if (typeof aVal === 'string') {
    aVal = aVal.toLocaleLowerCase();
  } else if (typeof aVal === 'object') {
    aVal = aVal?.name?.trim().toLowerCase();
  }

  if (typeof bVal === 'string') {
    bVal = bVal.toLocaleLowerCase();
  } else if (typeof bVal === 'object') {
    bVal = bVal?.name?.trim().toLowerCase();
  }

  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export const getCheckboxFilteredData = (data: Array<any>, checkboxFilters: IEnhancedTableCheckboxFilter) => {
  const filterNames = Object.keys(checkboxFilters);

  let filteredData = [...data];

  for (let i = 0; i < filterNames.length; i++) {
    const filterName = filterNames[i];
    const filter = checkboxFilters[filterName];

    filteredData = [
      ...filteredData.filter((record) => {
        const cell = record[filterName];

        if (Array.isArray(cell)) {
          const names = cell.map((cellValue) => cellValue?.name);

          for (let i = 0; i < names.length; i++) {
            const name = names[i];

            if (filter[name]) {
              return true;
            }
          }

          return false;
        }

        if (cell === null && filter['N/A']) return true;
        return typeof cell === 'string' || typeof cell === 'number' ? filter[cell] : filter[cell?.name];
      }),
    ];
  }

  return filteredData;
};

export function getComparator<Key extends keyof any>(
  order: ETableOrder,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const getTextFilteredData = (data: Array<any>, textFilters: IEnhancedTableTextFilter) => {
  const filterNames = Object.keys(textFilters);

  let filteredData = [...data];

  for (let i = 0; i < filterNames.length; i++) {
    const filterName = filterNames[i];
    const filter = textFilters[filterName];

    filteredData = [
      ...filteredData.filter(
        (record) =>
          (!record[filterName] && !filter.toLowerCase().trim()) ||
          record[filterName]?.toLowerCase().includes(filter.toLowerCase().trim()),
      ),
    ];
  }

  return filteredData;
};

export const initCheckboxFilters = (data: Array<any>, columns: Array<IEnhancedTableHeadCell>) => {
  const checkboxFilterColumns = columns.filter((column) => column.checkboxFilter);

  const checkboxFilters = Object.fromEntries(
    checkboxFilterColumns.map((column) => [
      column.id,
      Object.fromEntries(
        data
          .map((record) => {
            const cell = record[column.id];

            const result = !Array.isArray(cell)
              ? typeof cell === 'string' || typeof cell === 'number'
                ? [cell]
                : cell === null
                ? ['N/A']
                : [cell?.name]
              : cell.map((cellData) => cellData?.name);

            return result;
          })
          .flat()
          .filter((val, index, self) => self.indexOf(val) === index)
          .sort(sortStringArray)
          .map((key) => {
            return [key, true];
          }),
      ),
    ]),
  ) as IEnhancedTableCheckboxFilter;

  return checkboxFilters;
};

export const initTextFilters = (columns: Array<IEnhancedTableHeadCell>) => {
  const textFilterColumns = columns.filter((column) => column.textFilter);

  const textFilters = Object.fromEntries(
    textFilterColumns.map((column) => [column.id, '']),
  ) as IEnhancedTableTextFilter;

  return textFilters;
};

export const isAppliedCheckboxFilters = (
  defaultCheckboxFilters: IEnhancedTableCheckboxFilter,
  checkboxFilters: IEnhancedTableCheckboxFilter,
) => {
  if (defaultCheckboxFilters && checkboxFilters) {
    const filterNames = Object.keys(defaultCheckboxFilters);

    for (let i = 0; i < filterNames.length; i++) {
      const filterName = filterNames[i];

      const isApplied = isAppliedCheckboxFilter(defaultCheckboxFilters, checkboxFilters, filterName);

      if (isApplied) {
        return true;
      }
    }
  }

  return false;
};

export const isAppliedFilters = (
  defaultCheckboxFilters: IEnhancedTableCheckboxFilter,
  checkboxFilters: IEnhancedTableCheckboxFilter,
  textFilters: IEnhancedTableTextFilter,
) => {
  return isAppliedCheckboxFilters(defaultCheckboxFilters, checkboxFilters) || isAppliedTextFilters(textFilters);
};

export const isAppliedCheckboxFilter = (
  defaultCheckboxFilters: IEnhancedTableCheckboxFilter,
  checkboxFilters: IEnhancedTableCheckboxFilter,
  filterName: string,
) => {
  if (defaultCheckboxFilters && checkboxFilters && filterName) {
    const defaultFilters = defaultCheckboxFilters[filterName];
    const currentFilters = checkboxFilters[filterName];

    const keys = Object.keys(defaultFilters || {});

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (defaultFilters[key] !== currentFilters[key]) {
        return true;
      }
    }

    return false;
  }

  return false;
};

export const isAppliedTextFilter = (textFilters: IEnhancedTableTextFilter, filterName: string) => {
  if (textFilters && filterName) {
    if (textFilters[filterName]?.trim()) {
      return true;
    }

    return false;
  }

  return false;
};

export const isAppliedTextFilters = (textFilters: IEnhancedTableTextFilter) => {
  if (textFilters) {
    const filterNames = Object.keys(textFilters);

    for (let i = 0; i < filterNames.length; i++) {
      const filterName = filterNames[i];

      const isApplied = isAppliedTextFilter(textFilters, filterName);

      if (isApplied) {
        return true;
      }
    }

    return false;
  }

  return false;
};

export function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}
