import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ChevronLeft, ChevronRight, Dashboard, People, School, ViewList } from '@mui/icons-material';

import { AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH } from '../configs';
import { ROUTES } from '../consts';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

interface AuthAdminSidebarLayoutProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const AuthAdminSidebarLayout: React.FC<AuthAdminSidebarLayoutProps> = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { open, setOpen } = props;

  const menus = [
    {
      id: 'dashboard',
      icon: <Dashboard />,
      text: 'Dashboard',
      onClick: () => navigate(ROUTES.DASHBOARD),
      path: ROUTES.DASHBOARD,
    },
    { id: 'users', icon: <People />, text: 'Users', onClick: () => navigate(ROUTES.USERS), path: ROUTES.USERS },
    {
      id: 'colleges',
      icon: <School />,
      text: 'Colleges',
      onClick: () => navigate(ROUTES.COLLEGES),
      path: ROUTES.COLLEGES,
    },
    { id: 'offers', icon: <ViewList />, text: 'Offers', onClick: () => navigate(ROUTES.OFFERS), path: ROUTES.OFFERS },
    {
      id: 'loan-offers',
      icon: <ViewList />,
      text: 'Loan Offers',
      onClick: () => navigate(ROUTES.LOAN_OFFERS),
      path: ROUTES.LOAN_OFFERS,
    },
  ];

  return (
    <Drawer
      sx={{
        width: AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Typography
          variant="h6"
          color="primary"
          sx={{
            marginLeft: 2,
          }}
        >
          Road2College
        </Typography>

        <IconButton onClick={() => setOpen(false)}>
          {theme.direction === 'ltr' ? <ChevronLeft color="primary" /> : <ChevronRight color="primary" />}
        </IconButton>
      </DrawerHeader>

      <Divider />

      <List>
        {menus.map((item) => (
          <ListItemButton key={item.id} onClick={item.onClick} selected={location.pathname === item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};
