import { HIGHSCHOOL_NOT_LISTED } from '../consts';
import { IHighschool } from '../models';

export const makeHighshcoolNotListedOptionAsTopOption = (highschools: Array<IHighschool>) => {
  const highschoolNotListed = highschools.find((item) => item.name === HIGHSCHOOL_NOT_LISTED);

  if (!highschoolNotListed) {
    return highschools;
  }

  return [highschoolNotListed, ...highschools.filter((item) => item.name !== HIGHSCHOOL_NOT_LISTED)];
};
