/* eslint-disable */
import * as React from 'react';
import './general-header.css';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { ROUTES } from '../consts';
import road2college from '../assets/images/icons/r2c-logo.svg';
import { palette } from '../configs';
import { selectUser } from '../store/selectors';
import { Nav } from './Nav';
import { NavLink } from 'react-router-dom';
import { HeaderMobile } from './Header/HeaderMobile';
/* import { HeaderMobile } from './Header/HeaderMobile'; */

interface GeneralHeaderLayoutProps {}

export const GeneralHeaderLayout: React.FC<GeneralHeaderLayoutProps> = () => {
  const user = useSelector(selectUser);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav>
      <Nav />
      <header className="header-container">
        <div className="header">
          <div className="first-block-header">
            <img className="road2college" src={road2college} alt={'title'} />
            <NavLink to={'/'} className="college-insights">
              Compare Offers
            </NavLink>
          </div>

           <HeaderMobile />
        </div>
      </header>
    </nav>
  );
};
