import { localStorageKey } from '../consts';

export const getApiAccessToken = () => {
  const authStatus = getAuthStatus();

  return authStatus?.token || '';
};

export const getAuthStatus = () => {
  return JSON.parse(localStorage.getItem(localStorageKey.auth_status) || 'null');
};
