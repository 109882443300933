import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ICollege, IState } from '../models';
import { selectStates } from '../store/selectors';
import { collegesAction } from '../store/actions';

interface AdminEditCollegeFormProps {
  college?: ICollege;
  next?: Function;
}

export const AdminEditCollegeForm = React.forwardRef((props: AdminEditCollegeFormProps, ref) => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const { college, next = () => {} } = props;

  const formManager = useFormik({
    initialValues: {
      id: college?.id || '',
      name: college?.name || '',
      stateId: college?.stateId || '',
      ipeds: college?.ipeds || '',
      isPrivate: college?.isPrivate || false,
      coaInState: college?.coaInState || '',
      coaOutState: college?.coaOutState || '',
      coaPrivate: college?.coaPrivate || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      ipeds: Yup.string().required('IPEDS is required'),
    }),
    onSubmit: (values) => {
      const { id, name, stateId, ipeds, isPrivate, coaInState, coaOutState, coaPrivate } = values;

      const newCollege = {
        id,
        name,
        stateId,
        ipeds,
        isPrivate,
        coaInState: coaInState ? Number(coaInState) : null,
        coaOutState: coaOutState ? Number(coaOutState) : null,
        coaPrivate: coaPrivate ? Number(coaPrivate) : null,
      };

      dispatch(collegesAction.updateCollege({ college: newCollege as ICollege, next }));
    },
  });

  React.useImperativeHandle(
    ref,
    () => ({
      onSubmit() {
        formManager.handleSubmit();
      },
    }),
    [], // eslint-disable-line
  );

  const onChangeNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else if (val.includes('.')) {
      if (Number(val) > 0) {
        formManager.setFieldValue(fieldName, Number(val));
      } else {
        formManager.setFieldValue(fieldName, val);
      }
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  const onBlurNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  return (
    <Box paddingY={2}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField label="College ID" variant="outlined" fullWidth value={formManager.values.id} disabled />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={states.find((state) => state.id === formManager.values.stateId)}
            disablePortal
            options={states}
            getOptionLabel={(option: IState) => `${option.name} - ${option.code}`}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('stateId', newValue?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Student’s State of Residence"
                error={!!(formManager.errors.stateId && formManager.touched.stateId)}
                helperText={formManager.touched.stateId ? formManager.errors.stateId : null}
              />
            )}
            autoHighlight
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="College Name"
            variant="outlined"
            fullWidth
            value={formManager.values.name}
            onChange={formManager.handleChange('name')}
            error={!!(formManager.errors.name && formManager.touched.name)}
            helperText={formManager.touched.name ? formManager.errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="IPEDS"
            variant="outlined"
            fullWidth
            value={formManager.values.ipeds}
            onChange={formManager.handleChange('ipeds')}
            error={!!(formManager.errors.ipeds && formManager.touched.ipeds)}
            helperText={formManager.touched.ipeds ? formManager.errors.ipeds : null}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormControl fullWidth error={!!(formManager.errors.coaInState && formManager.touched.coaInState)}>
            <InputLabel>COA in State</InputLabel>
            <OutlinedInput
              value={formManager.values.coaInState}
              onChange={(event) => onChangeNumber(event.target.value, 'coaInState')}
              onBlur={(event) => onBlurNumber(event.target.value, 'coaInState')}
              type="number"
              inputProps={{ step: 0.1 }}
              label="Unweighted GPA"
            />
            <FormHelperText error>
              {formManager.touched.coaInState ? formManager.errors.coaInState : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormControl fullWidth error={!!(formManager.errors.coaOutState && formManager.touched.coaOutState)}>
            <InputLabel>COA out State</InputLabel>
            <OutlinedInput
              value={formManager.values.coaOutState}
              onChange={(event) => onChangeNumber(event.target.value, 'coaOutState')}
              onBlur={(event) => onBlurNumber(event.target.value, 'coaOutState')}
              type="number"
              inputProps={{ step: 0.1 }}
              label="Unweighted GPA"
            />
            <FormHelperText error>
              {formManager.touched.coaOutState ? formManager.errors.coaOutState : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormControl fullWidth error={!!(formManager.errors.coaPrivate && formManager.touched.coaPrivate)}>
            <InputLabel>COA Private</InputLabel>
            <OutlinedInput
              value={formManager.values.coaPrivate}
              onChange={(event) => onChangeNumber(event.target.value, 'coaPrivate')}
              onBlur={(event) => onBlurNumber(event.target.value, 'coaPrivate')}
              type="number"
              inputProps={{ step: 0.1 }}
              label="Unweighted GPA"
            />
            <FormHelperText error>
              {formManager.touched.coaPrivate ? formManager.errors.coaPrivate : null}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formManager.values.isPrivate}
                onChange={(event, checked) => {
                  formManager.setFieldValue('isPrivate', checked);
                }}
                sx={{
                  paddingY: 0.5,
                  paddingX: 1.2,
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  lineHeight: 1.3,
                }}
              >
                Private College
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
});
