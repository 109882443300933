import { IOffersState } from '../../models';

export const offersState: IOffersState = {
  offers: [],
  loanOffers: [],
  offersAccepted: [],
  offersPublished: [],
  otherOffersSelectedCollegeId: -1,
  otherOffersSelectedYear: -1,
  publicOffers: [],
};
