import { createSelector } from 'reselect';
import dayjs from 'dayjs';

import { selectOffersDetailed } from '.';
import { IUsersState, IRootState } from '../../models';
import { getEfcName, isTodayUser } from '../../utils';

export const selectUsersState = (rootState: IRootState): IUsersState => rootState.usersState;

export const selectBasicUsers = createSelector([selectUsersState], (usersState) => usersState.users);

export const selectUsers = createSelector([selectBasicUsers, selectOffersDetailed], (users, offers) =>
  users.map((item) => ({
    ...item,
    createdAtLabel: item.createdAt ? dayjs(item.createdAt).format('YYYY/MM/DD') : '',
    userEfcName: getEfcName(item?.efc?.min || null, item?.efc?.max || null),
    userStateCode: item?.state?.code || '',
    offers: offers.filter((offer) => offer.userId === item.id).length || 0,
  })),
);

export const selectUsersTotalCount = createSelector([selectUsers], (users) => users.length);

export const selectUsersTodayCount = createSelector(
  [selectUsers],
  (users) => users.filter((user) => isTodayUser(user)).length,
);

export const selectSelectedUserId = createSelector([selectUsersState], (usersState) => usersState.selectedUserId);

export const selectSelectedUser = createSelector([selectUsers, selectSelectedUserId], (users, userId) =>
  users.find((item) => item.id === userId),
);
