export const getYearsOfGraduationList = (): { id: number; name: string; value: number }[] => {
  const yearsOfGraduation: { id: number; name: string; value: number }[] = [];
  for (let i = 0; i < 11; i++) {
    yearsOfGraduation.push({
      id: i,
      name: (new Date().getFullYear() - 2 + i).toString(),
      value: new Date().getFullYear() - 2 + i,
    });
  }
  return yearsOfGraduation;
};
