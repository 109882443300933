import { createTheme } from '@mui/material';

export const palette = {
  primary: {
    main: '#7E106E',
    contrastText: '#464646',
    dark: '#030054',
  },
  secondary: {
    main: '#7E106E',
  },
  default: {
    main: '#030054',
  },
};

export const theme = createTheme({
  palette,
  typography: { fontFamily: 'Inter' },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: 0,
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          height: 'calc(100% - 100px)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:hover': {
            backgroundColor: `${palette.primary.main}20`,
            color: palette.primary.main,
          },
          '&.Mui-selected': {
            backgroundColor: `${palette.primary.main}10`,
            color: palette.primary.main,
          },
        },
      },
    },
  },
});
