import { produce } from 'immer';

import { createReducer } from '.';
import { repaymentTypesActionTypes } from '../../consts';
import { IAction, IRepaymentTypesState } from '../../models';
import { repaymentTypesState } from '../states/repayment-types.state';

export const repaymentTypesReducer = createReducer<IRepaymentTypesState>(repaymentTypesState, {
  [repaymentTypesActionTypes.SET_REPAYMENT_TYPES]: setRepaymentTypes,
});

function setRepaymentTypes(repaymentType: IRepaymentTypesState, { payload }: IAction<Array<any>>) {
  return produce(repaymentType, (draft) => {
    draft.repaymentTypes = payload;
  });
}
