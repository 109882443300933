import * as React from 'react';

import { AcceptedOfferChangeDialog } from './accepted-offer-change.dialog';
import { AdminEditCollegeDialog } from './admin-edit-college.dialog';
import { AdminEditOfferDialog } from './admin-edit-offer.dialog';
import { AdminEditUserDialog } from './admin-edit-user.dialog';
import { LoanOfferCreateDialog } from './loan-offer-create.dialog';
import { LoanOfferDeleteDialog } from './loan-offer-delete.dialog';
import { LoanOfferUpdateDialog } from './loan-offer-update.dialog';
import { MyOfferCreateDialog } from './my-offer-create.dialog';
import { MyOfferDeleteDialog } from './my-offer-delete.dialog';
import { MyOfferUpdateDialog } from './my-offer-update.dialog';
import { PublicOfferChangeDialog } from './public-offer-change.dialog';

export interface DialogsProps {}

export const Dialogs: React.FC<DialogsProps> = () => {
  return (
    <>
      <AcceptedOfferChangeDialog />
      <AdminEditCollegeDialog />
      <AdminEditOfferDialog />
      <AdminEditUserDialog />
      <LoanOfferCreateDialog />
      <LoanOfferUpdateDialog />
      <LoanOfferDeleteDialog />
      <MyOfferCreateDialog />
      <MyOfferDeleteDialog />
      <MyOfferUpdateDialog />
      <PublicOfferChangeDialog />
    </>
  );
};
