const _compareToSort = (fa: any, fb: any) => {
  if (fa < fb) {
    return -1;
  }

  if (fa > fb) {
    return 1;
  }

  return 0;
};

export const sortStringArray = (a: string | number, b: string | number) => {
  const fa = a.toString().toLowerCase();
  const fb = b.toString().toLowerCase();

  return _compareToSort(fa, fb);
};
