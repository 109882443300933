import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { MyOfferForm } from '../forms';
import { dialogsAction } from '../store/actions';
import { selectLoadingState, selectDialogsState } from '../store/selectors';

interface MyOfferCreateDialogProps {}

export const MyOfferCreateDialog: React.FC<MyOfferCreateDialogProps> = () => {
  const dispatch = useDispatch();
  const { myOfferCreateDialog = false } = useSelector(selectDialogsState);
  const { myOfferCreateLoading = false } = useSelector(selectLoadingState);
  const formRef = React.useRef<any>();
  const theme = useTheme();
  const windowSize = useWindowSize();

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      dispatch(
        dialogsAction.updateDialogsStatus({
          myOfferCreateDialog: false,
        }),
      );
    }
  };

  const onSubmit = () => {
    formRef.current.onSubmit();
  };

  return (
    <Dialog fullWidth maxWidth={'md'} scroll={'paper'} onClose={onClose} open={myOfferCreateDialog}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          paddingY: 1.5,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Create New Offer</Typography>

          <IconButton
            sx={{
              color: 'white',
            }}
            onClick={() => onClose(null, 'anyway')}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          overflowX: 'hidden',
          paddingX: windowSize.width < theme.breakpoints.values.sm ? 2 : 3,
        }}
      >
        <Box paddingTop={2}>
          <MyOfferForm ref={formRef} />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          bgcolor: 'grey.A200',
          paddingY: 1.5,
          paddingX: 3,
          justifyContent: 'flex-start',
        }}
      >
        <Button
          onClick={onSubmit}
          color="secondary"
          variant="contained"
          style={{
            color: 'white',
            borderRadius: '20px',
            fontWeight: 600,
          }}
        >
          Create
          {myOfferCreateLoading && (
            <Box marginLeft={2} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" size={16} />
            </Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
