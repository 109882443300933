import { axiosService } from '.';
import { apiUrls } from '../consts';
import { ILoanOffer } from '../models';
import { getApiAccessToken } from '../utils';

export const loanOfferService = (function () {
  const endpoint_create_loan_offer = async (payload: Partial<ILoanOffer>) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.post_loan_offer, payload);
  };

  const endpoint_delete_loan_offer = async (payload: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.delete_loan_offer(payload));
  };

  const endpoint_get_all_loan_offers = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_all_loan_offers);
  };

  const endpoint_get_user_loan_offers = async (payload: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_user_loan_offers(payload));
  };

  const endpoint_update_loan_offer = async (payload: { loanOfferId: number; data: Partial<ILoanOffer> }) => {
    const { loanOfferId, data } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.put_loan_offer(loanOfferId), data);
  };

  return {
    endpoint_create_loan_offer,
    endpoint_delete_loan_offer,
    endpoint_get_all_loan_offers,
    endpoint_get_user_loan_offers,
    endpoint_update_loan_offer,
  };
})();
