import * as React from 'react';
import { Box } from '@mui/material';

import { AuthAdminHeaderLayout, AuthAdminMainLayout, AuthAdminSidebarLayout, DrawerHeader } from '.';

interface AuthAdminLayoutProps {
  children?: React.ReactNode;
}

export const AuthAdminLayout: React.FC<AuthAdminLayoutProps> = (props) => {
  const [open, setOpen] = React.useState(true);
  const { children } = props;

  return (
    <Box sx={{ display: 'flex' }}>
      <AuthAdminHeaderLayout open={open} setOpen={setOpen} />

      <AuthAdminSidebarLayout open={open} setOpen={setOpen} />

      <AuthAdminMainLayout open={open}>
        <DrawerHeader />

        <Box paddingY={4} paddingX={{ xs: 2, sm: 4 }}>
          {children}
        </Box>
      </AuthAdminMainLayout>
    </Box>
  );
};
