import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { EOffersActionTypes } from '../../enums';
import { IOffer, IOfferAccepted, IOfferPublished } from '../../models';
import { offerService } from '../../services';
import { dialogsAction, loadingAction, offersAction } from '../actions';
import {
  selectMyOffersSelectedOffer,
  selectMyOffersSelectedOfferId,
  selectOffersDetailed,
  selectOffersSelectedOffer,
  selectUserId,
} from '../selectors';

export default function* root() {
  yield all([takeLatest(EOffersActionTypes.CREATE_OFFER as any, watchCreateOffer)]);
  yield all([takeLatest(EOffersActionTypes.DELETE_OFFER as any, watchDeleteOffer)]);
  yield all([takeLatest(EOffersActionTypes.GET_ALL_OFFERS as any, watchGetAllOffers)]);
  yield all([takeLatest(EOffersActionTypes.GET_OFFERS as any, watchGetOffers)]);
  yield all([takeLatest(EOffersActionTypes.UPDATE_OFFER as any, watchUpdateOffer)]);
  yield all([takeLatest(EOffersActionTypes.GET_OFFERS_ACCEPTED as any, watchGetOffersAccepted)]);
  yield all([takeLatest(EOffersActionTypes.UPDATE_OFFER_ACCEPTED as any, watchUpdateOfferAccepted)]);
  yield all([takeLatest(EOffersActionTypes.GET_PUBLIC_OFFERS as any, watchGetPublicOffers)]);
  yield all([takeLatest(EOffersActionTypes.GET_OFFERS_PUBLISHED as any, watchGetOffersPublished)]);
  yield all([takeLatest(EOffersActionTypes.UPDATE_OFFER_PUBLISHED as any, watchUpdateOfferPublished)]);
}

function* watchCreateOffer(action: { type: string; payload: IOffer }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myOfferCreateLoading: true,
      }),
    );

    const userId: number = yield select(selectUserId);

    yield call(offerService.endpoint_create_offer, {
      ...action.payload,
      userId,
    });

    const { data } = yield call(offerService.endpoint_get_offers, { userId });

    yield put(offersAction.setOffers(data as Array<IOffer>));
  } catch (error: any) {
    console.error('watchCreateOffer: ', error.response);
  } finally {
    yield put(
      dialogsAction.updateDialogsStatus({
        myOfferCreateDialog: false,
      }),
    );

    yield put(
      loadingAction.updateLoadingStatus({
        myOfferCreateLoading: false,
      }),
    );
  }
}

function* watchDeleteOffer() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myOfferDeleteLoading: true,
      }),
    );

    const offerId: number = yield select(selectMyOffersSelectedOfferId);
    const offers: Array<any> = yield select(selectOffersDetailed);
    const { data } = yield call(offerService.endpoint_delete_offer, offerId);

    yield put(offersAction.setOffers(offers.filter((offer) => offer.id !== data.id)));

    yield put(offersAction.setSelectedOfferId());
  } catch (error: any) {
    console.error('watchDeleteOffer: ', error.response);
  } finally {
    yield put(
      dialogsAction.updateDialogsStatus({
        myOfferDeleteDialog: false,
      }),
    );

    yield put(
      loadingAction.updateLoadingStatus({
        myOfferDeleteLoading: false,
      }),
    );
  }
}

function* watchGetAllOffers() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: true,
      }),
    );

    const { data } = yield call(offerService.endpoint_get_all_offers);

    yield put(offersAction.setOffers(data as Array<IOffer>));

    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetAllOffers: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: false,
      }),
    );
  }
}

function* watchGetOffers() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: true,
      }),
    );

    const userId: number = yield select(selectUserId);
    const { data } = yield call(offerService.endpoint_get_offers, { userId });

    yield put(offersAction.setOffers(data as Array<IOffer>));

    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetOffers: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: false,
      }),
    );
  }
}

function* watchUpdateOffer(action: {
  type: string;
  payload: {
    offer: IOffer;
    next: Function;
  };
}) {
  try {
    const { offer, next } = action.payload;

    yield put(
      loadingAction.updateLoadingStatus({
        myOfferUpdateLoading: true,
      }),
    );

    const { data } = yield call(offerService.endpoint_update_offer, {
      offerId: offer.id,
      data: offer,
    });

    yield put(offersAction.setOffer(data as IOffer));

    yield put(offersAction.setSelectedOfferId());

    next();
  } catch (error: any) {
    console.error('watchUpdateOffer: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myOfferUpdateLoading: false,
      }),
    );
  }
}

function* watchGetOffersAccepted() {
  let offersAccepted: Array<IOfferAccepted> = [];

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        acceptedOffersLoading: true,
      }),
    );

    const { data } = yield call(offerService.endpoint_get_offers_accepted);

    offersAccepted = data as Array<IOfferAccepted>;
  } catch (error: any) {
    console.error('watchGetOffersAccepted: ', error.response);
  } finally {
    yield put(offersAction.setOffersAccepted(offersAccepted));

    yield put(
      loadingAction.updateLoadingStatus({
        acceptedOffersLoading: false,
      }),
    );
  }
}

function* watchUpdateOfferAccepted() {
  let offersAccepted: Array<IOfferAccepted> = [];

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        acceptedOfferUpdating: true,
      }),
    );

    const offer: IOffer = yield select(selectMyOffersSelectedOffer);
    const userId = offer.userId;
    const offerId = offer.id;
    const accepted = !!offer.accepted;

    yield call(offerService.endpoint_update_offer_accepted, {
      userId,
      offerId,
      accepted: !accepted,
    });

    const { data } = yield call(offerService.endpoint_get_offers_accepted);

    offersAccepted = data as Array<IOfferAccepted>;
  } catch (error: any) {
    console.error('watchUpdateOfferAccepted: ', error.response);
  } finally {
    yield put(offersAction.setOffersAccepted(offersAccepted));

    yield put(
      loadingAction.updateLoadingStatus({
        acceptedOfferUpdating: false,
      }),
    );

    yield put(
      dialogsAction.updateDialogsStatus({
        acceptedOfferChangeDialog: false,
      }),
    );
  }
}

function* watchGetPublicOffers() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        publicOffersGettingFlag: true,
      }),
    );

    const { data } = yield call(offerService.endpoint_get_public_offers);

    yield put(offersAction.setPublicOffers(data as Array<IOffer>));
  } catch (error: any) {
    console.error('watchGetPublicOffers: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        publicOffersGettingFlag: false,
      }),
    );
  }
}

function* watchGetOffersPublished() {
  let offersPublished: Array<IOfferPublished> = [];

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        publicOffersGettingFlag: true,
      }),
    );

    const { data } = yield call(offerService.endpoint_get_offers_published);

    offersPublished = data as Array<IOfferPublished>;
  } catch (error: any) {
    console.error('watchGetOffersPublished: ', error.response);
  } finally {
    yield put(offersAction.setOffersPublished(offersPublished));

    yield put(
      loadingAction.updateLoadingStatus({
        publicOffersGettingFlag: false,
      }),
    );
  }
}

function* watchUpdateOfferPublished() {
  let offersPublished: Array<IOfferPublished> = [];

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        publicOfferUpdatingFlag: true,
      }),
    );

    const offer: IOffer = yield select(selectOffersSelectedOffer);
    const offerId = offer.id;
    const published = !!offer.published;

    yield call(offerService.endpoint_update_offer_published, {
      offerId,
      published: !published,
    });

    const { data } = yield call(offerService.endpoint_get_offers_published);

    offersPublished = data as Array<IOfferPublished>;
  } catch (error: any) {
    console.error('watchUpdateOfferPublished: ', error.response);
  } finally {
    yield put(offersAction.setOffersPublished(offersPublished));

    yield put(
      loadingAction.updateLoadingStatus({
        publicOfferUpdatingFlag: false,
      }),
    );

    yield put(
      dialogsAction.updateDialogsStatus({
        publicOfferChangeDialog: false,
      }),
    );
  }
}
