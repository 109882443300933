import * as React from 'react';
import { useSelector } from 'react-redux';

import { ProfileCompletedNavigator, ProfileUncompletedNavigator } from '.';
import { selectUserProfileCompleted } from '../store/selectors';

interface UserNavigatorProps {}

export const UserNavigator: React.FC<UserNavigatorProps> = () => {
  const profileCompleted = useSelector(selectUserProfileCompleted);

  return profileCompleted ? <ProfileCompletedNavigator /> : <ProfileUncompletedNavigator />;
};
