import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';

import { ICollege } from '../models';
import {
  selectMyOffersColleges,
  selectOtherOffersSelectedCollegeId,
  selectOtherOffersSelectedYear,
} from '../store/selectors';
import { offersAction } from '../store/actions';
import { getYearsList } from '../utils';

interface OtherOffersFilterProps {}

export const OtherOffersFilter: React.FC<OtherOffersFilterProps> = () => {
  const dispatch = useDispatch();
  const colleges = useSelector(selectMyOffersColleges);
  const otherOffersSelectedCollegeId = useSelector(selectOtherOffersSelectedCollegeId);
  const selectedYear = useSelector(selectOtherOffersSelectedYear);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const definedYears = getYearsList();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Autocomplete
          value={[
            {
              id: -1,
              name: 'All',
            } as ICollege,
            ...colleges,
          ].find((item) => item.id === otherOffersSelectedCollegeId)}
          options={[
            {
              id: -1,
              name: 'All',
            } as ICollege,
            ...colleges,
          ]}
          getOptionLabel={(item) => (item.id === -1 ? 'All' : `${item.name} - ${item.stateCode}`)}
          onChange={(event: any, newValue: any) => {
            const collegeId = newValue?.id;
            dispatch(offersAction.setOtherOffersSelectedCollegeId(collegeId));
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                style={
                  option.id === -1
                    ? {
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: theme.palette.divider,
                      }
                    : {}
                }
              >
                {option.id === -1 ? option.name : `${option.name} - ${option.stateCode}`}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="College"
              placeholder="College"
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            />
          )}
          disableClearable
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Autocomplete
          value={[
            {
              id: -1,
              value: -1,
              label: 'All',
            },
            ...definedYears,
          ].find((year) => year.id === selectedYear)}
          options={[
            {
              id: -1,
              value: -1,
              label: 'All',
            },
            ...definedYears,
          ]}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                style={
                  option.id === -1
                    ? {
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: theme.palette.divider,
                      }
                    : {}
                }
              >
                {option.label}
              </li>
            );
          }}
          onChange={(event: any, newValue: any) => {
            const year = newValue?.id;
            dispatch(offersAction.setOtherOffersSelectedYear(year));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Year"
              placeholder="Year"
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            />
          )}
          disableClearable
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
