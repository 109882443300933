import { createSelector } from 'reselect';
import dayjs from 'dayjs';

import { IOffersState, IRootState, ICollege } from '../../models';
import { getCollegeNameWithState, getEfcName, getOfferDetails, isTodayOffer } from '../../utils';
import { selectColleges } from './colleges.selector';
import { selectUserId } from './user.selector';

export const selectOffersState = (rootState: IRootState): IOffersState => rootState.offersState;

export const selectOffers = createSelector([selectOffersState], (offersState) => offersState.offers);

export const selectOffersAccepted = createSelector([selectOffersState], (offersState) => offersState.offersAccepted);

export const selectOffersPublished = createSelector([selectOffersState], (offersState) => offersState.offersPublished);

export const selectOffersDetailed = createSelector(
  [selectOffers, selectOffersAccepted, selectOffersPublished],
  (offers, offersAccepted, offersPublished) =>
    offers.map((item) => {
      const {
        grantFinancialNeed,
        grantNonFinancialNeed,
        totalPellAndStateGrants,
        totalUnsubsidiziedFederalLoans,
        totalSubsidizedFederalLoans,
        offerCoa,
        netCost,
      } = getOfferDetails(item);

      const accepted = !!offersAccepted.find((acceptedOfferInfo) => acceptedOfferInfo.offerId === item.id);
      const acceptedLabel = accepted ? 'Verified' : 'Unverified';

      const published = !!offersPublished.find((publishedOfferInfo) => publishedOfferInfo.offerId === item.id);
      const publishedLabel = published ? 'Yes' : 'No';

      return {
        ...item,
        grantFinancialNeed,
        grantNonFinancialNeed,
        totalPellAndStateGrants,
        totalUnsubsidiziedFederalLoans,
        totalSubsidizedFederalLoans,
        admissionTypeName: item.admissionType?.name || '',
        collegeCoaInState: item.college?.coaInState || 0,
        collegeCoaOutState: item.college?.coaOutState || 0,
        collegeCoaPrivate: item.college?.coaPrivate || 0,
        collegeName: item.college?.name || '',
        collegeStateId: item?.college?.stateId || null,
        collegeStateCode: item?.college?.state?.code || null,
        collegeNameWithState: getCollegeNameWithState(item.college?.name || '', item.college?.state?.code || ''),
        userAct: item.user?.act || 0,
        userEfcId: item.user?.efcId || null,
        userGpa: item.user?.gpa || 0,
        userSat: item.user?.sat || 0,
        userStateId: item.user?.stateId || null,
        userEmail: item.user?.email || '',
        userEfcName: getEfcName(item.user?.efc?.min || null, item?.user?.efc?.max || null),
        userEfcMin: item?.user?.efc?.min || 0,
        userEfcMax: item?.user?.efc?.max || 0,
        userStateCode: item?.user?.state?.code || '',
        offerCoa,
        netCost,
        grantFinancialNeedRenewableLabel: item?.grantFinancialNeedRenewable ? 'Yes' : 'No',
        grantNonFinancialNeedRenewableLabel: item?.grantNonFinancialNeedRenewable ? 'Yes' : 'No',
        stackableLabel: item?.stackable ? 'Yes' : 'No',
        acceptMoneyLabel: item?.acceptMoney ? 'Yes' : 'No',
        honorsProgramLabel: item?.honorsProgram ? 'Yes' : 'No',
        yearLabel: `${item.year} - ${item.year + 1}`,
        updatedAtLabel:
          item.updatedAt || item.createdAt ? dayjs(item.updatedAt || item.createdAt).format('YYYY/MM/DD') : '',
        accepted,
        acceptedLabel,
        published,
        publishedLabel,
      };
    }),
);

export const selectOffersTotalCount = createSelector([selectOffersDetailed], (offers) => offers.length);

export const selectOffersTodayCount = createSelector(
  [selectOffersDetailed],
  (offers) => offers.filter((offer) => isTodayOffer(offer)).length,
);

export const selectOtherOffersSelectedCollegeId = createSelector(
  [selectOffersState],
  (offersState) => offersState.otherOffersSelectedCollegeId,
);

export const selectOtherOffersSelectedCollege = createSelector(
  [selectColleges, selectOtherOffersSelectedCollegeId],
  (colleges, collegeId) => colleges.find((college) => college.id === collegeId),
);

export const selectOtherOffersSelectedYear = createSelector(
  [selectOffersState],
  (offersState) => offersState.otherOffersSelectedYear,
);

export const selectOtherOffers = createSelector(
  [selectOffersDetailed, selectOtherOffersSelectedCollegeId, selectOtherOffersSelectedYear],
  (offers, collegeId, year) => {
    if (collegeId === -1 && year === -1) {
      return offers;
    }

    if (collegeId === -1) {
      return offers.filter((item) => item.year === year);
    }

    if (year === -1) {
      return offers.filter((item) => item.collegeId === collegeId);
    }

    return offers.filter((item) => item.collegeId === collegeId && item.year === year);
  },
);

export const selectMyOffers = createSelector([selectOffersDetailed, selectUserId], (offers, userId) =>
  offers.filter((item) => item.userId === userId),
);

export const selectMyOffersCount = createSelector([selectMyOffers], (offers) => (offers || []).length);

export const selectMyOffersSelectedOfferId = createSelector(
  [selectOffersState],
  (offersState) => offersState.myOffersSelectedOfferId,
);

export const selectMyOffersSelectedOffer = createSelector(
  [selectMyOffers, selectMyOffersSelectedOfferId],
  (offers, offerId) => offers.find((item) => item.id === offerId),
);

export const selectMyOffersColleges = createSelector([selectColleges, selectMyOffers], (colleges, offers) => {
  const offersCollegeIds = offers.map((offer) => offer.collegeId);

  return colleges.filter((college) => offersCollegeIds.includes(college.id));
});

export const selectNonMyOffersColleges = createSelector([selectColleges, selectMyOffers], (colleges, offers) => {
  const offersCollegeIds = offers.map((offer) => offer.collegeId);

  return colleges.filter((college) => !offersCollegeIds.includes(college.id));
});

export const selectCollegesWithOffersCount = createSelector(
  [selectColleges, selectOffersDetailed],
  (colleges, offers) => {
    return colleges.map((college) => {
      const offersForCollege = offers.filter((item) => item.collegeId === college.id);
      const offersCount = offersForCollege.length || 0;
      const offerYears = offersForCollege.map((item) => ({
        year: item.year,
        yearLabel: item.yearLabel,
      }));

      return {
        ...college,
        offers: offersCount,
        offerYears,
      } as ICollege;
    });
  },
);

export const selectCollegesCountHaveOffers = createSelector(
  [selectCollegesWithOffersCount],
  (colleges) => colleges.filter((college) => college.offers > 0).length,
);

export const selectPublicOffers = createSelector([selectOffersState], (offersState) =>
  offersState.publicOffers.map((item) => {
    const { grantFinancialNeed, grantNonFinancialNeed } = getOfferDetails(item);

    return {
      collegeNameWithState: getCollegeNameWithState(item.college?.name || '', item.college?.state?.code || ''),
      userEfcName: getEfcName(item.user?.efc?.min || null, item?.user?.efc?.max || null),
      grantFinancialNeed,
      grantNonFinancialNeed,
      yearLabel: `${item.year} - ${item.year + 1}`,
    };
  }),
);

export const selectOffersSelectedId = createSelector(
  [selectOffersState],
  (offersState) => offersState.offersSelectedOfferId,
);

export const selectOffersSelectedOffer = createSelector(
  [selectOffersDetailed, selectOffersSelectedId],
  (offers, offerId) => offers.find((item) => item.id === offerId),
);
