import { all, fork, put } from 'redux-saga/effects';

import { EBasicActionTypes } from '../../enums';

import admissionTypesSaga from './admission-types.saga';
import collegesSaga from './colleges.saga';
import creditScoreRangesSaga from './credit-score-ranges.saga';
import efcsSaga from './efcs.saga';
import highschoolsSaga from './highschools.saga';
import lendersSaga from './lenders.saga';
import loanOffersSaga from './loan-offers.saga';
import loanTermsSaga from './loan-terms.saga';
import loanTypesSaga from './loan-types.saga';
import offersSaga from './offers.saga';
import repaymentTypesSaga from './repayment-types.saga';
import statesSaga from './states.saga';
import userSaga from './user.saga';
import usersSaga from './users.saga';

export default function* root() {
  yield all([
    fork(admissionTypesSaga),
    fork(collegesSaga),
    fork(creditScoreRangesSaga),
    fork(efcsSaga),
    fork(highschoolsSaga),
    fork(lendersSaga),
    fork(loanOffersSaga),
    fork(loanTermsSaga),
    fork(loanTypesSaga),
    fork(offersSaga),
    fork(repaymentTypesSaga),
    fork(statesSaga),
    fork(userSaga),
    fork(usersSaga),
  ]);

  yield put({
    type: EBasicActionTypes.INIT_STORE,
  });
}
