import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from '../consts';

import { AuthUserLayout } from '../layouts';
import {
  CompareMyOffersPage,
  ProfilePage,
  CompareOtherOffersPage,
  CompareMyLoansPage,
  CompareOtherLoansPage,
} from '../pages';
import { loanOffersAction, offersAction } from '../store/actions';

interface ProfileCompletedNavigatorProps {}

export const ProfileCompletedNavigator: React.FC<ProfileCompletedNavigatorProps> = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(offersAction.getOffers());
    dispatch(offersAction.getOffersAccepted());
    dispatch(loanOffersAction.getLoanOffers());
  }, []);

  return (
    <AuthUserLayout>
      <Routes>
        <Route path={ROUTES.COMPARE_MY_OFFERS} element={<CompareMyOffersPage />} />
        <Route path={ROUTES.COMPARE_OTHER_OFFERS} element={<CompareOtherOffersPage />} />
        <Route path={ROUTES.COMPARE_MY_LOANS} element={<CompareMyLoansPage />} />
        <Route path={ROUTES.COMPARE_OTHER_LOANS} element={<CompareOtherLoansPage />} />
        <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
        <Route path="/*" element={<Navigate replace to={ROUTES.COMPARE_MY_OFFERS} />} />
      </Routes>
    </AuthUserLayout>
  );
};
