import { numberWithCommas } from '.';

export const getEfcName = (min: number | null, max: number | null) => {
  if (!min && !max) {
    return '';
  }

  if (!min) {
    return `Under $${numberWithCommas(max as number)}`;
  }

  if (!max) {
    return `Above $${numberWithCommas(min as number)}`;
  }

  return `$${numberWithCommas(min as number)} - $${numberWithCommas(max as number)}`;
};
