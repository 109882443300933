import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ELoanTypesActionTypes } from '../../enums';
import { IIdAndName } from '../../models';
import { loanTypesService } from '../../services';
import { loadingAction, loanTypesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ELoanTypesActionTypes.GET_LOAN_TYPES as any, watchGetLoanTypes)]);
}

function* watchGetLoanTypes() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        loanTypesGettingFlag: true,
      }),
    );

    const { data } = yield call(loanTypesService.endpoint_get_loan_types);

    yield put(loanTypesAction.setLoanTypes(data as Array<IIdAndName>));
  } catch (error: any) {
    console.error('watchGetLoanTypes: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        loanTypesGettingFlag: false,
      }),
    );
  }
}
