export const DEFAULT_ROWS_OPTIONS_PER_PAGE = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
];
