import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const lendersService = (function () {
  const endpoint_get_lenders = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_lenders);
  };

  return {
    endpoint_get_lenders,
  };
})();
