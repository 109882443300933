import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Loading } from '../components';
import { ROUTES } from '../consts';
import { UnauthLayout } from '../layouts';
import { HomePage, TermsOfServicePage } from '../pages';
import { offersAction } from '../store/actions';
import { selectLoadingState } from '../store/selectors';

interface UnauthNavigatorProps {}

export const UnauthNavigator: React.FC<UnauthNavigatorProps> = () => {
  const dispatch = useDispatch();
  const { publicOffersGettingFlag = false } = useSelector(selectLoadingState);

  React.useEffect(() => {
    dispatch(offersAction.getPublicOffers());
  }, []);

  if (publicOffersGettingFlag) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading text="Loading top offers..." />
      </Box>
    );
  }

  return (
    <UnauthLayout>
      <Routes>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsOfServicePage />} />
        <Route path="/*" element={<Navigate replace to={ROUTES.HOME} />} />
      </Routes>
    </UnauthLayout>
  );
};
