import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { Box, Tab, Tabs, useTheme } from '@mui/material';

import { ROUTES } from '../consts';
import { palette } from '../configs';

interface PageMainTabsProps {}

export const PageMainTabs: React.FC<PageMainTabsProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = React.useState<string>(location.pathname || ROUTES.COMPARE_MY_OFFERS);
  const theme = useTheme();
  const windowSize = useWindowSize();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  React.useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location.pathname]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        orientation={windowSize.width < theme.breakpoints.values.md ? 'vertical' : 'horizontal'}
      >
        <Tab
          label="Compare My Own Offers"
          value={ROUTES.COMPARE_MY_OFFERS}
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: selectedTab === ROUTES.COMPARE_MY_OFFERS ? palette.primary.main : palette.primary.dark,
          }}
          sx={
            windowSize.width < theme.breakpoints.values.md
              ? {
                  maxWidth: '100%',
                  minHeight: 36,
                  alignItems: 'flex-start',
                  paddingY: 0,
                  paddingX: 0,
                }
              : {}
          }
        />

        <Tab
          label="Compare Other Offers"
          value={ROUTES.COMPARE_OTHER_OFFERS}
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: selectedTab === ROUTES.COMPARE_OTHER_OFFERS ? palette.primary.main : palette.primary.dark,
          }}
          sx={
            windowSize.width < theme.breakpoints.values.md
              ? {
                  maxWidth: '100%',
                  minHeight: 36,
                  alignItems: 'flex-start',
                  paddingY: 0,
                  paddingX: 0,
                }
              : {}
          }
        />

        <Tab
          label="Compare My Loans"
          value={ROUTES.COMPARE_MY_LOANS}
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: selectedTab === ROUTES.COMPARE_MY_LOANS ? palette.primary.main : palette.primary.dark,
          }}
          sx={
            windowSize.width < theme.breakpoints.values.md
              ? {
                  maxWidth: '100%',
                  minHeight: 36,
                  alignItems: 'flex-start',
                  paddingY: 0,
                  paddingX: 0,
                }
              : {}
          }
        />

        <Tab
          label="Compare Other Loans"
          value={ROUTES.COMPARE_OTHER_LOANS}
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: selectedTab === ROUTES.COMPARE_OTHER_LOANS ? palette.primary.main : palette.primary.dark,
          }}
          sx={
            windowSize.width < theme.breakpoints.values.md
              ? {
                  maxWidth: '100%',
                  minHeight: 36,
                  alignItems: 'flex-start',
                  paddingY: 0,
                  paddingX: 0,
                }
              : {}
          }
        />
      </Tabs>
    </Box>
  );
};
