import { ILoanOffer } from '../models';

export const convertLoanOffersToTableData = (loanOffers: Array<ILoanOffer>) => {
  return loanOffers.map((loanOffer) => convertLoanOfferToTableData(loanOffer));
};

export const convertLoanOfferToTableData = (loanOffer: ILoanOffer) => {
  return {
    id: loanOffer.id,
    lender: loanOffer.lenderName,
    interestRate: loanOffer.interestRateLabel,
    termOfLoan: loanOffer.loanTermLabel,
    repaymentTypeLabel: loanOffer.repaymentTypeLabel,
    coSignorLabel: loanOffer.coSignorLabel,
    creditScoreRangeText: loanOffer.creditScoreRangeText,
    monthlyPaymentAmount: loanOffer.monthlyPaymentAmountLabel,
    totalLoanAmount: loanOffer.totalLoanAmountLabel,
    action: ['edit'],
  };
};

export const convertMyLoanOffersToTableData = (loanOffers: Array<ILoanOffer>) => {
  return loanOffers.map((loanOffer) => convertMyLoanOfferToTableData(loanOffer));
};

export const convertMyLoanOfferToTableData = (loanOffer: ILoanOffer) => {
  return {
    id: loanOffer.id,
    lender: loanOffer.lenderName,
    interestRate: loanOffer.interestRateLabel,
    termOfLoan: loanOffer.loanTermLabel,
    repaymentTypeLabel: loanOffer.repaymentTypeLabel,
    loanLife: loanOffer.loanLife,
    coSignorLabel: loanOffer.coSignorLabel,
    isPublic: loanOffer.govOrPrivateText,
    monthlyPaymentAmount: loanOffer.monthlyPaymentAmountLabel,
    totalLoanAmount: loanOffer.totalLoanAmountLabel,
    action: ['edit', 'delete'],
  };
};

export const convertOtherLoanOffersToTableData = (loanOffers: Array<ILoanOffer>) => {
  return loanOffers.map((loanOffer) => convertOtherLoanOfferToTableData(loanOffer));
};

export const convertOtherLoanOfferToTableData = (loanOffer: ILoanOffer) => {
  return {
    lender: loanOffer.lenderName,
    creditScoreRangeText: loanOffer.creditScoreRangeText,
    interestRate: loanOffer.interestRateLabel,
    termOfLoan: loanOffer.loanTermLabel,
    repaymentTypeLabel: loanOffer.repaymentTypeLabel,
    loanLife: loanOffer.loanLife,
    coSignorLabel: loanOffer.coSignorLabel,
  };
};
