import { produce } from 'immer';

import { createReducer } from '.';
import { loanOffersActionTypes } from '../../consts';
import { IAction, ILoanOffer, ILoanOffersState } from '../../models';
import { loanOffersState } from '../states/loan-offers.state';

export const loanOffersReducer = createReducer<ILoanOffersState>(loanOffersState, {
  [loanOffersActionTypes.SET_LOAN_OFFERS]: setLoanOffers,
  [loanOffersActionTypes.SET_MY_LOAN_OFFERS_SELECTED_COLLEGE_ID]: setMyLoanOffersSelectedCollegeId,
  [loanOffersActionTypes.SET_MY_LOAN_OFFERS_SELECTED_YEAR]: setMyLoanOffersSelectedYear,
  [loanOffersActionTypes.SET_MY_LOAN_OFFERS_SELECTED_OFFER_ID]: setMyLoanOffersSelectedOfferId,
  [loanOffersActionTypes.SET_OTHER_LOAN_OFFERS_SELECTED_LENDER_ID]: setOtherLoanOffersSelectedLenderId,
  [loanOffersActionTypes.SET_OTHER_LOAN_OFFERS_SELECTED_YEAR]: setOtherLoanOffersSelectedYear,
});

function setLoanOffers(state: ILoanOffersState, { payload }: IAction<Array<ILoanOffer>>) {
  return produce(state, (draft) => {
    draft.loanOffers = payload;
  });
}

function setMyLoanOffersSelectedCollegeId(state: ILoanOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.myLoanOffersSelectedCollegeId = payload;
  });
}

function setMyLoanOffersSelectedYear(state: ILoanOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.myLoanOffersSelectedYear = payload;
  });
}

function setMyLoanOffersSelectedOfferId(state: ILoanOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.myLoanOffersSelectedOfferId = payload;
  });
}

function setOtherLoanOffersSelectedLenderId(state: ILoanOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.otherLoanOffersSelectedLenderId = payload;
  });
}

function setOtherLoanOffersSelectedYear(state: ILoanOffersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.otherLoanOffersSelectedYear = payload;
  });
}
