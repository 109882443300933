import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const highschoolsService = (function () {
  const endpoint_get_highschools_by_state_id = async (stateId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_highschools_by_state_id(stateId));
  };

  return {
    endpoint_get_highschools_by_state_id,
  };
})();
