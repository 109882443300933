import * as React from 'react';
import { useWindowSize } from 'react-use';
import { Box, Toolbar, useTheme } from '@mui/material';

import { GeneralHeaderLayout } from './general-header.layout';
import { FooterLayout } from './footer.layout';

interface UnauthLayoutProps {
  children?: React.ReactNode;
}

export const UnauthLayout: React.FC<UnauthLayoutProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const windowSize = useWindowSize();

  return (
    <>
      <GeneralHeaderLayout />

      <Box display="flex" flexDirection="column" justifyContent="center">
        <Toolbar>
          <Box width="100%" minHeight={windowSize.width < theme.breakpoints.values.sm ? 64 : 120} />
        </Toolbar>

        <Box paddingTop={4} paddingBottom={{ xs: 0, md: 4 }} paddingX={{ xs: 0, md: 4 }}>
          {children}
        </Box>
      </Box>
      <FooterLayout />
    </>
  );
};
