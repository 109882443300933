import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { ELoanOffersActionTypes } from '../../enums';
import { ILoanOffer } from '../../models';
import { loanOfferService } from '../../services';
import { loadingAction, loanOffersAction } from '../actions';
import { selectMyLoanOffersSelectedOfferId, selectUserId } from '../selectors';

export default function* root() {
  yield all([takeLatest(ELoanOffersActionTypes.GET_LOAN_OFFERS as any, watchGetLoanOffers)]);
  yield all([takeLatest(ELoanOffersActionTypes.CREATE_LOAN_OFFER as any, watchCreateLoanOffer)]);
  yield all([takeLatest(ELoanOffersActionTypes.UPDATE_LOAN_OFFER as any, watchUpdateLoanOffer)]);
  yield all([takeLatest(ELoanOffersActionTypes.DELETE_LOAN_OFFER as any, watchDeleteLoanOffer)]);
}

function* watchGetLoanOffers() {
  let loanOffers: Array<ILoanOffer> = [];

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        loanOffersGettingFlag: true,
      }),
    );

    const { data } = yield call(loanOfferService.endpoint_get_all_loan_offers);

    loanOffers = data as Array<ILoanOffer>;
  } catch (error: any) {
    console.error('watchGetLoanOffers: ', error.response);
  } finally {
    yield put(loanOffersAction.setLoanOffers(loanOffers));

    yield put(
      loadingAction.updateLoadingStatus({
        loanOffersGettingFlag: false,
      }),
    );
  }
}

function* watchCreateLoanOffer(action: {
  type: string;
  payload: {
    data: Partial<ILoanOffer>;
    next: Function;
  };
}) {
  const { data, next } = action.payload;

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        loanOfferCreatingFlag: true,
      }),
    );

    const userId: number = yield select(selectUserId);

    yield call(loanOfferService.endpoint_create_loan_offer, {
      ...data,
      userId,
    });

    const res: { data: any } = yield call(loanOfferService.endpoint_get_all_loan_offers);

    yield put(loanOffersAction.setLoanOffers(res.data as Array<ILoanOffer>));

    next();
  } catch (error: any) {
    console.error('watchCreateLoanOffer: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        loanOfferCreatingFlag: false,
      }),
    );
  }
}

function* watchUpdateLoanOffer(action: {
  type: string;
  payload: {
    data: Partial<ILoanOffer>;
    next: Function;
  };
}) {
  const { data, next } = action.payload;

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        loanOfferUpdatingFlag: true,
      }),
    );

    const loanOfferId: number = yield select(selectMyLoanOffersSelectedOfferId);

    yield call(loanOfferService.endpoint_update_loan_offer, { loanOfferId, data });

    const res: { data: any } = yield call(loanOfferService.endpoint_get_all_loan_offers);

    yield put(loanOffersAction.setLoanOffers(res.data as Array<ILoanOffer>));

    next();
  } catch (error: any) {
    console.error('watchUpdateLoanOffer: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        loanOfferUpdatingFlag: false,
      }),
    );
  }
}

function* watchDeleteLoanOffer(action: {
  type: string;
  payload: {
    next: Function;
  };
}) {
  const { next } = action.payload;

  try {
    yield put(
      loadingAction.updateLoadingStatus({
        loanOfferDeletingFlag: true,
      }),
    );

    const loanOfferId: number = yield select(selectMyLoanOffersSelectedOfferId);

    yield call(loanOfferService.endpoint_delete_loan_offer, loanOfferId);

    const { data } = yield call(loanOfferService.endpoint_get_all_loan_offers);

    yield put(loanOffersAction.setLoanOffers(data as Array<ILoanOffer>));

    next();
  } catch (error: any) {
    console.error('watchDeleteLoanOffer: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        loanOfferDeletingFlag: false,
      }),
    );
  }
}
