/* eslint-disable */
import * as React from 'react';
import { useWindowSize } from 'react-use';
import { Box, useTheme } from '@mui/material';

import { GeneralHeaderLayout } from './general-header.layout';
import { FooterLayout } from './footer.layout';
import { useSelector } from 'react-redux';
import { selectUserAvaliableYearGraduation, selectUserYearGraduation } from '../store/selectors';
import compareOffersExcitedFamily from '../assets/images/CompareOffersExcitedFamily.jpg';
import { useLocation } from 'react-router-dom';

interface AuthUserLayoutProps {
  children?: React.ReactNode;
}

export const AuthUserLayout: React.FC<AuthUserLayoutProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const windowSize = useWindowSize();
  const userAvaliableYearGraduation = useSelector(selectUserAvaliableYearGraduation);
  const userYearG = useSelector(selectUserYearGraduation);
  const { pathname } = useLocation();
  return (
    <>
      <GeneralHeaderLayout />

      {userAvaliableYearGraduation ? (
        <Box>
          <Box paddingY={4} paddingX={{ xs: 2, sm: 4 }}>
            {children}
          </Box>
        </Box>
      ) : (
        <>
          {pathname === '/profile' || pathname === '/onboarding' || (pathname === '/' && userYearG === undefined) ? (
            <Box>
              <Box paddingY={4} paddingX={{ xs: 2, sm: 4 }}>
                {children}
              </Box>
            </Box>
          ) : (
            <Box paddingY={4} paddingX={{ xs: 2, sm: 4 }}>
              <div className="noAvaliableCompareContainer">
                <div className="image_container">
                  <img className="compareOffersExcitedFamily_image" src={compareOffersExcitedFamily} alt={'title'} />
                </div>
                <div className="text_container">
                  <h1>Welcome to Compare Offers!</h1>
                  <p>
                    This unique site reveals actual financial aid offers received by our users.{' '}
                    <strong>Compare Offers</strong> revealing data shows how you stack up with other students and their
                    offers.
                  </p>

                  <p className="text-blue">
                    *Take note that Compare Offers is exclusively available to families with high school seniors who
                    have college acceptances to share and compare.*
                  </p>

                  <p>
                    If you haven’t reached this stage yet, you can still browse all the offers if you purchase a
                    <a href="https://www.road2college.com/updated-membership-plans/">
                      {' '}
                      Road2College Essential membership
                    </a>{' '}
                    or higher tier. You’ll also have access to R2C Insights, our comparison tool that helps you explore
                    colleges.
                  </p>

                  <p>
                    Start building your college lists and exploring merit scholarships – and then come back when you’re
                    a high school senior to enter your college offer information.
                  </p>
                </div>
              </div>
            </Box>
          )}
        </>
      )}

      <FooterLayout />
    </>
  );
};
