import * as React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { DashboardCard, DashboardChart } from '../../components';
import {
  selectCollegesCountHaveOffers,
  selectOffersTodayCount,
  selectOffersTotalCount,
  selectUsersTodayCount,
  selectUsersTotalCount,
} from '../../store/selectors';

interface DashboardPageProps {}

export const DashboardPage: React.FC<DashboardPageProps> = () => {
  const usersTotalCount = useSelector(selectUsersTotalCount);
  const usersTodayCount = useSelector(selectUsersTodayCount);
  const offersTotalCount = useSelector(selectOffersTotalCount);
  const offersTodayCount = useSelector(selectOffersTodayCount);

  const collegesCountHaveOffers = useSelector(selectCollegesCountHaveOffers);

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <DashboardCard title="User Registrations" total={usersTotalCount} today={usersTodayCount} />
      </Grid>

      <Grid item xs={3}>
        <DashboardCard title="Offers" total={offersTotalCount} today={offersTodayCount} />
      </Grid>

      <Grid item xs={3}>
        <DashboardCard title="Colleges with Offers" total={collegesCountHaveOffers} />
      </Grid>

      <Grid item xs={12}>
        <DashboardChart />
      </Grid>
    </Grid>
  );
};
