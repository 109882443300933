import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

import { PageMainTabs, EnhancedTable, TitleSection } from '../../components';
import { dialogsAction, offersAction } from '../../store/actions';
import { selectLoadingState, selectMyOffers, selectUserEfcName } from '../../store/selectors';
import { convertMyOffersToTableData } from '../../utils';
import { myOffersTableColumns, palette } from '../../configs';

interface CompareMyOffersPageProps {}

export const CompareMyOffersPage: React.FC<CompareMyOffersPageProps> = () => {
  const dispatch = useDispatch();
  let offers = useSelector(selectMyOffers);
  const { getOffersLoading = false } = useSelector(selectLoadingState);
  const userEfcName = useSelector(selectUserEfcName);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const columns = myOffersTableColumns;
  const columnKeys = myOffersTableColumns.map((item) => item.id);

  const onEdit = (id: number) => {
    dispatch(offersAction.setMyOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        myOfferUpdateDialog: true,
      }),
    );
  };

  const onDelete = (id: number) => {
    dispatch(offersAction.setMyOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        myOfferDeleteDialog: true,
      }),
    );
  };

  const onUpdateAccpeted = (id: number) => {
    dispatch(offersAction.setMyOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        acceptedOfferChangeDialog: true,
      }),
    );
  };

  offers = offers.map((item) => ({
    ...item,
    onUpdateAccpeted,
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <PageMainTabs />

      <Box sx={{ py: 5 }}>
        <TitleSection
          title="My College Offers"
          color={palette.primary.dark}
          subtitle={
            <Typography
              variant={windowSize.width < theme.breakpoints.values.md ? 'subtitle2' : 'h6'}
              color="primary"
              id="tableTitle"
              component="div"
            >
              EFC: {userEfcName}
            </Typography>
          }
          createButtonLabel="Add Offer"
          startIcon={<Add />}
          onCreateButton={() => {
            dispatch(
              dialogsAction.updateDialogsStatus({
                myOfferCreateDialog: true,
              }),
            );
          }}
        />

        <Box paddingY={4}>
          <EnhancedTable
            config={{
              toolbar: false,
              rowCheckable: false,
              rowActionVisible: true,
            }}
            loading={getOffersLoading}
            loadingLabel={'Loading My Offers'}
            noDataComponent={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 300,
                }}
              >
                <Typography
                  sx={{
                    marginRight: 0.5,
                  }}
                >
                  Create your offer
                </Typography>
                <Link
                  onClick={(e) => {
                    e.preventDefault();

                    dispatch(
                      dialogsAction.updateDialogsStatus({
                        myOfferCreateDialog: true,
                      }),
                    );
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  here
                </Link>
              </Box>
            }
            toolbarTitle={'Title'}
            data={offers}
            convertFunctionForDataToTableData={convertMyOffersToTableData}
            headColumns={columns}
            defaultSortColumnName="collegeName"
            columnKeys={columnKeys}
            styles={{
              tableStyle: {
                minWidth: 0,
              },
            }}
            initialRowsPerPage={10}
            visiblePagination={true}
            rowActions={{
              onEdit,
              onDelete,
            }}
          />
        </Box>

        <Typography style={{ color: palette.primary.dark }} variant="body2" color="secondary">
          * Net Cost to You; Calculation does not include Loans or Private Scholarships.
        </Typography>
      </Box>
    </Box>
  );
};
