import { EHighschoolsActionTypes } from '../../enums';
import { IHighschool } from '../../models';

const getHighschools = (payload: { stateId: number }) => {
  return {
    type: EHighschoolsActionTypes.GET_HIGHSCHOOLS,
    payload,
  };
};

const setHighschools = (payload: Array<IHighschool>) => {
  return {
    type: EHighschoolsActionTypes.SET_HIGHSCHOOLS,
    payload,
  };
};

export const highschoolsAction = {
  getHighschools,
  setHighschools,
};
