import * as React from 'react';
import { Box, Paper, Typography } from '@mui/material';

import { numberWithCommas } from '../utils';

interface DashboardCardProps {
  title?: string;
  total?: number;
  today?: number;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
  const { title = '', total = 0, today } = props;

  return (
    <Paper
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle2">{title}</Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 1,
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Total
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            sx={{
              fontWeight: 900,
              marginLeft: 'auto',
            }}
          >
            {numberWithCommas(total)}
          </Typography>
        </Box>

        {today !== undefined && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Today
            </Typography>
            <Typography
              variant="h4"
              color="secondary"
              sx={{
                fontWeight: 900,
                marginLeft: 'auto',
              }}
            >
              {numberWithCommas(today)}
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
