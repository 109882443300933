import { ELoanTypesActionTypes } from '../../enums';

const getLoanTypes = () => {
  return {
    type: ELoanTypesActionTypes.GET_LOAN_TYPES,
  };
};

const setLoanTypes = (payload: Array<any>) => {
  return {
    type: ELoanTypesActionTypes.SET_LOAN_TYPES,
    payload,
  };
};

export const loanTypesAction = {
  getLoanTypes,
  setLoanTypes,
};
