import { axiosService } from '.';
import { apiUrls } from '../consts';
import { IOffer } from '../models';
import { getApiAccessToken } from '../utils';

export const offerService = (function () {
  const endpoint_create_offer = async (payload: IOffer) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.post_offer, payload);
  };

  const endpoint_delete_offer = async (payload: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.delete_offer(payload));
  };

  const endpoint_get_all_offers = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_all_offers);
  };

  const endpoint_get_offers = async (payload: { userId: number }) => {
    const { userId } = payload;

    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_offers(userId));
  };

  const endpoint_get_public_offers = async () => {
    return axiosService.get(apiUrls.get_public_offers);
  };

  const endpoint_update_offer = async (payload: { offerId: number; data: IOffer }) => {
    const { offerId, data } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.put_offer(offerId), data);
  };

  const endpoint_get_offers_accepted = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_offers_accepted);
  };

  const endpoint_update_offer_accepted = async (payload: { userId: number; offerId: number; accepted: boolean }) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.post_offers_accepted, payload);
  };

  const endpoint_get_offers_published = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_offers_published);
  };

  const endpoint_update_offer_published = async (payload: { offerId: number; published: boolean }) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.post_offers_published, payload);
  };

  return {
    endpoint_create_offer,
    endpoint_delete_offer,
    endpoint_get_all_offers,
    endpoint_get_offers,
    endpoint_get_public_offers,
    endpoint_update_offer,
    endpoint_get_offers_accepted,
    endpoint_update_offer_accepted,
    endpoint_get_offers_published,
    endpoint_update_offer_published,
  };
})();
