import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ELoanTermsActionTypes } from '../../enums';
import { IIdAndName } from '../../models';
import { loanTermsService } from '../../services';
import { loadingAction, loanTermsAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ELoanTermsActionTypes.GET_LOAN_TERMS as any, watchGetLoanTerms)]);
}

function* watchGetLoanTerms() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        loanTermsGettingFlag: true,
      }),
    );

    const { data } = yield call(loanTermsService.endpoint_get_loan_terms);

    yield put(loanTermsAction.setLoanTerms(data as Array<IIdAndName>));
  } catch (error: any) {
    console.error('watchGetLoanTerms: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        loanTermsGettingFlag: false,
      }),
    );
  }
}
