import { produce } from 'immer';

import { createReducer } from '.';
import { dialogsActionTypes } from '../../consts';
import { IAction, IDialogsState } from '../../models';
import { dialogsState } from '../states/dialogs.state';

export const dialogsReducer = createReducer<IDialogsState>(dialogsState, {
  [dialogsActionTypes.UPDATE_DIALOGS_STATUS]: updateDialogsStatus,
});

function updateDialogsStatus(
  state: IDialogsState,
  {
    payload,
  }: IAction<{
    [key: string]: boolean;
  }>,
) {
  return produce(state, (draft) => {
    Object.keys(payload).forEach((key) => {
      draft[key as keyof IDialogsState] = payload[key] as never;
    });
  });
}
