import { produce } from 'immer';

import { createReducer } from '.';
import { collegesActionTypes } from '../../consts';
import { IAction, ICollegesState } from '../../models';
import { collegesState } from '../states/colleges.state';

export const collegesReducer = createReducer<ICollegesState>(collegesState, {
  [collegesActionTypes.SET_COLLEGES]: setColleges,
  [collegesActionTypes.SET_COLLEGE]: setCollege,
  [collegesActionTypes.SET_SELECTED_COLLEGE_ID]: setSelectedCollegeId,
});

function setColleges(state: ICollegesState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.colleges = payload;
  });
}

function setCollege(state: ICollegesState, { payload }: IAction<any>) {
  return produce(state, (draft) => {
    if (draft.colleges && draft.colleges.length > 0) {
      const index = draft.colleges?.findIndex((val) => val.id === payload.id);

      draft.colleges[index] = { ...payload };
    }
  });
}

function setSelectedCollegeId(state: ICollegesState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.selectedCollegeId = payload;
  });
}
