import { axiosService } from '.';
import { apiUrls } from '../consts';
import { IUser } from '../models';
import { getApiAccessToken } from '../utils';

export const userService = (function () {
  const endpoint_post_get_user = async (payload: { email: string }) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.post_get_user, payload);
  };

  const endpoint_put_user = async (payload: IUser) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.put_user, payload);
  };

  return {
    endpoint_post_get_user,
    endpoint_put_user,
  };
})();
