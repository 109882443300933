import { produce } from 'immer';

import { createReducer } from '.';
import { loanTermsActionTypes } from '../../consts';
import { IAction, ILoanTermsState } from '../../models';
import { loanTermsState } from '../states/loan-terms.state';

export const loanTermsReducer = createReducer<ILoanTermsState>(loanTermsState, {
  [loanTermsActionTypes.SET_LOAN_TERMS]: setLoanTerms,
});

function setLoanTerms(loanTerm: ILoanTermsState, { payload }: IAction<Array<any>>) {
  return produce(loanTerm, (draft) => {
    draft.loanTerms = payload;
  });
}
