import { Box, Paper, Table, TableContainer, TablePagination, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { EnhancedTableBody, EnhancedTableHead, EnhancedTableToolbar, Loading } from '.';
import { DEFAULT_ROWS_OPTIONS_PER_PAGE } from '../consts';

import {
  ETableOrder,
  IEnhancedTableCheckboxFilter,
  IEnhancedTableConfig,
  IEnhancedTableHeadCell,
  IEnhancedTableRowsOptionPerPage,
  IEnhancedTableTextFilter,
} from '../models';
import {
  getCheckboxFilteredData,
  getComparator,
  getTextFilteredData,
  initCheckboxFilters,
  initTextFilters,
  stableSort,
} from '../utils';

export interface EnhancedTableProps {
  config?: IEnhancedTableConfig;
  loading: boolean;
  loadingLabel: string;
  noDataLabel?: string;
  noDataComponent?: React.ReactNode;
  noDataSectionClassnames?: string;
  toolbarTitle: string;
  data: Array<any>;
  convertFunctionForDataToTableData?: Function;
  headColumns: Array<IEnhancedTableHeadCell>;
  columnKeys: Array<string>;
  rowActions: {
    onRow?: Function;
    onEdit?: Function;
    onDelete?: Function;
    onReview?: Function;
  };
  initialRowsPerPage?: number;
  deleteFunction?: (payload: any) => void;
  defaultSortColumnName?: string;
  defaultSortDirection?: ETableOrder;
  styles?: any;
  enhancedTableHeadClassNames?: any;
  visiblePagination?: boolean;
  exportable?: boolean;
  exporting?: boolean;
  filename?: string;
  getCsvHeaders?: Function;
  getCsvData?: Function;
  csvDataOtherProps?: any;
  definedRowsPerPageOptions?: Array<IEnhancedTableRowsOptionPerPage>;
}

export const EnhancedTable: React.FC<EnhancedTableProps> = (props) => {
  const {
    config,
    loading,
    loadingLabel,
    noDataLabel = '',
    noDataComponent = null,
    noDataSectionClassnames,
    toolbarTitle,
    data,
    convertFunctionForDataToTableData,
    headColumns,
    columnKeys,
    rowActions,
    initialRowsPerPage,
    deleteFunction,
    defaultSortColumnName,
    defaultSortDirection,
    styles,
    enhancedTableHeadClassNames,
    visiblePagination = true,
    exportable,
    exporting,
    filename,
    getCsvHeaders,
    getCsvData,
    csvDataOtherProps,
    definedRowsPerPageOptions = DEFAULT_ROWS_OPTIONS_PER_PAGE,
  } = props;
  const [order, setOrder] = React.useState<ETableOrder>(defaultSortDirection || 'asc');
  const [orderBy, setOrderBy] = React.useState<string>(defaultSortColumnName || 'name');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(initialRowsPerPage || 10);
  const [defaultCheckboxFilters, setDefaultCheckboxFilters] = React.useState<IEnhancedTableCheckboxFilter>({});
  const [checkboxFilters, setCheckboxFilters] = React.useState<IEnhancedTableCheckboxFilter>({});
  const [defaultTextFilters, setDefaultTextFilters] = React.useState<IEnhancedTableTextFilter>({});
  const [textFilters, setTextFilters] = React.useState<IEnhancedTableTextFilter>({});
  const [filteredData, setFilteredData] = React.useState<Array<any>>([]);

  const classes = useStyles();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = filteredData.map((n) => `${n.id}`);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearFilters = () => {
    setCheckboxFilters(JSON.parse(JSON.stringify(defaultCheckboxFilters)));
    setTextFilters(JSON.parse(JSON.stringify(defaultTextFilters)));
  };

  const onDelete = () => {
    if (deleteFunction) {
      deleteFunction(selected);
    }
  };

  const updateCheckboxFilters = (filterName: string, key: string, value: boolean) => {
    checkboxFilters[filterName][key] = value;

    setCheckboxFilters(JSON.parse(JSON.stringify(checkboxFilters)));
  };

  const selectAllCheckboxFilters = (filterName: string) => {
    checkboxFilters[filterName] = { ...defaultCheckboxFilters[filterName] };
    setCheckboxFilters(JSON.parse(JSON.stringify(checkboxFilters)));
  };

  const unselectAllCheckboxFilters = (filterName: string) => {
    const keys = Object.keys(checkboxFilters[filterName]);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      checkboxFilters[filterName][key] = false;
    }

    setCheckboxFilters(JSON.parse(JSON.stringify(checkboxFilters)));
  };

  const updateTextFilters = (filterName: string, value: string) => {
    textFilters[filterName] = value;

    setTextFilters(JSON.parse(JSON.stringify(textFilters)));
  };

  React.useEffect(() => {
    setSelected([...selected.filter((id) => data.map((data) => data.id).includes(id))]);
    const defaultCheckboxFilters = initCheckboxFilters(data, headColumns);
    const defaultTextFilters = initTextFilters(headColumns);

    setDefaultCheckboxFilters(JSON.parse(JSON.stringify(defaultCheckboxFilters)));
    setDefaultTextFilters(JSON.parse(JSON.stringify(defaultTextFilters)));

    setCheckboxFilters(JSON.parse(JSON.stringify(defaultCheckboxFilters)));
    setTextFilters(JSON.parse(JSON.stringify(defaultTextFilters)));
  }, [data, headColumns]); // eslint-disable-line

  React.useEffect(() => {
    let filteredData = [...getTextFilteredData(data, textFilters)];
    filteredData = [...getCheckboxFilteredData(filteredData, checkboxFilters)];
    setFilteredData(filteredData);
  }, [checkboxFilters, textFilters]); // eslint-disable-line

  React.useEffect(() => {
    if (initialRowsPerPage) {
      setRowsPerPage(initialRowsPerPage);
    }
  }, [initialRowsPerPage]); // eslint-disable-line

  return (
    <div className={classes.root}>
      {loading ? (
        <Box height={300} display="flex" justifyContent="center" alignItems="center">
          <Loading text={loadingLabel} />
        </Box>
      ) : (
        <Paper>
          {(!config || config?.toolbar) && (
            <EnhancedTableToolbar
              title={toolbarTitle}
              numSelected={selected.length}
              defaultCheckboxFilters={defaultCheckboxFilters}
              checkboxFilters={checkboxFilters}
              textFilters={textFilters}
              hideclearFilters={config?.hideclearFilters}
              clearFilters={clearFilters}
              onDelete={onDelete}
              exportable={exportable}
              exporting={exporting}
              filename={filename}
              getCsvHeaders={getCsvHeaders}
              getCsvData={getCsvData}
              csvDataOtherProps={csvDataOtherProps}
              filteredData={stableSort(filteredData, getComparator(order, orderBy))}
            />
          )}

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
              style={{
                minWidth: 750,
                ...styles?.tableStyle,
              }}
            >
              <EnhancedTableHead
                checkable={!config || config?.rowCheckable}
                actionVisible={!config || config?.rowActionVisible}
                columns={headColumns}
                defaultCheckboxFilters={defaultCheckboxFilters}
                checkboxFilters={checkboxFilters}
                textFilters={textFilters}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowCount={filteredData.length}
                onCheckboxFilterItem={updateCheckboxFilters}
                onCheckboxFilterSelectAll={selectAllCheckboxFilters}
                onCheckboxFilterUnselectAll={unselectAllCheckboxFilters}
                onChangeTextFilter={updateTextFilters}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                classNames={enhancedTableHeadClassNames}
              />

              {filteredData.length > 0 && (
                <EnhancedTableBody
                  checkable={!config || config?.rowCheckable}
                  actionVisible={!config || config?.rowActionVisible}
                  data={filteredData}
                  convertFunctionForDataToTableData={convertFunctionForDataToTableData}
                  columns={headColumns}
                  columnKeys={columnKeys}
                  order={order}
                  orderBy={orderBy}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  selected={selected}
                  setSelected={setSelected}
                  actions={rowActions}
                />
              )}
            </Table>
          </TableContainer>

          {visiblePagination && filteredData.length > 0 && data.length > (initialRowsPerPage || 10) && (
            <TablePagination
              rowsPerPageOptions={definedRowsPerPageOptions}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

          {filteredData.length === 0 && (
            <>
              {noDataLabel && (
                <Box
                  display="flex"
                  width="100%"
                  height={240}
                  alignItems="center"
                  justifyContent="center"
                  className={`${noDataSectionClassnames || ''}`}
                >
                  <Typography color="textSecondary" noWrap>
                    {noDataLabel}
                  </Typography>
                </Box>
              )}

              {noDataComponent}
            </>
          )}
        </Paper>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    '&&': {
      width: '100%',
    },
  },
});
