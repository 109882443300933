import {
  EAdmissionTypesActionTypes,
  ECollegesActionTypes,
  ECreditScoreRangesActionTypes,
  EDialogsActionTypes,
  EEfcsActionTypes,
  EHighschoolsActionTypes,
  ELendersActionTypes,
  ELoadingActionTypes,
  ELoanOffersActionTypes,
  ELoanTermsActionTypes,
  ELoanTypesActionTypes,
  ENotificationActionTypes,
  EOffersActionTypes,
  ERepaymentTypesActionTypes,
  EStatesActionTypes,
  EUserActionTypes,
  EUsersActionTypes,
} from '../enums';

export const admissionTypesActionTypes = {
  ...EAdmissionTypesActionTypes,
};

export const collegesActionTypes = {
  ...ECollegesActionTypes,
};

export const creditScoreRangesActionTypes = {
  ...ECreditScoreRangesActionTypes,
};

export const dialogsActionTypes = {
  ...EDialogsActionTypes,
};

export const efcsActionTypes = {
  ...EEfcsActionTypes,
};

export const highschoolsActionTypes = {
  ...EHighschoolsActionTypes,
};

export const lendersActionTypes = {
  ...ELendersActionTypes,
};

export const loadingActionTypes = {
  ...ELoadingActionTypes,
};

export const loanOffersActionTypes = {
  ...ELoanOffersActionTypes,
};

export const loanTermsActionTypes = {
  ...ELoanTermsActionTypes,
};

export const loanTypesActionTypes = {
  ...ELoanTypesActionTypes,
};

export const notificationActionTypes = {
  ...ENotificationActionTypes,
};

export const offersActionTypes = {
  ...EOffersActionTypes,
};

export const repaymentTypesActionTypes = {
  ...ERepaymentTypesActionTypes,
};

export const statesActionTypes = {
  ...EStatesActionTypes,
};

export const userActionTypes = {
  ...EUserActionTypes,
};

export const usersActionTypes = {
  ...EUsersActionTypes,
};
