import { produce } from 'immer';

import { createReducer } from '.';
import { admissionTypesActionTypes } from '../../consts';
import { IAction, IAdmissionTypesState } from '../../models';
import { admissionTypesState } from '../states/admission-types.state';

export const admissionTypesReducer = createReducer<IAdmissionTypesState>(admissionTypesState, {
  [admissionTypesActionTypes.SET_ADMISSION_TYPES]: setAdmissionTypes,
});

function setAdmissionTypes(admissionType: IAdmissionTypesState, { payload }: IAction<Array<any>>) {
  return produce(admissionType, (draft) => {
    draft.admissionTypes = payload;
  });
}
