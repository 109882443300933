import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { AdminNavigator, UserNavigator } from '.';
import { Loading } from '../components';
import { IUser } from '../models';
import {
  admissionTypesAction,
  collegesAction,
  creditScoreRangesAction,
  efcsAction,
  lendersAction,
  loanTermsAction,
  loanTypesAction,
  repaymentTypesAction,
  statesAction,
  userAction,
} from '../store/actions';
import { selectLoadingState, selectUserRole } from '../store/selectors';
import { EUserRoles } from '../enums';

interface AuthNavigatorProps {}

export const AuthNavigator: React.FC<AuthNavigatorProps> = () => {
  const dispatch = useDispatch();
  const { getUserLoading = false } = useSelector(selectLoadingState);
  const role = useSelector(selectUserRole);

  const { user } = useAuth0();

  React.useEffect(() => {
    if (user) {
      const { email } = user;

      dispatch(
        userAction.setUser({
          email,
        } as IUser),
      );

      dispatch(
        userAction.getUser({
          email: email as string,
        }),
      );
      dispatch(statesAction.getStates());
      dispatch(efcsAction.getEfcs());
      dispatch(admissionTypesAction.getAdmissionTypes());
      dispatch(lendersAction.getLenders());
      dispatch(loanTermsAction.getLoanTerms());
      dispatch(loanTypesAction.getLoanTypes());
      dispatch(repaymentTypesAction.getRepaymentTypes());
      dispatch(collegesAction.getColleges());
      dispatch(creditScoreRangesAction.getCreditScoreRanges());
    }
  }, [user]);

  if (getUserLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading text="Loading profile information." />
      </Box>
    );
  }

  return role === EUserRoles.ADMIN ? <AdminNavigator /> : <UserNavigator />;
};
