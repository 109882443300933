import { ERepaymentTypesActionTypes } from '../../enums';

const getRepaymentTypes = () => {
  return {
    type: ERepaymentTypesActionTypes.GET_REPAYMENT_TYPES,
  };
};

const setRepaymentTypes = (payload: Array<any>) => {
  return {
    type: ERepaymentTypesActionTypes.SET_REPAYMENT_TYPES,
    payload,
  };
};

export const repaymentTypesAction = {
  getRepaymentTypes,
  setRepaymentTypes,
};
