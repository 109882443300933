/* eslint-disable */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, useTheme } from '@mui/material';

import { ROUTES } from '../../consts';
import { ProfileForm } from '../../forms';
import { selectUser } from '../../store/selectors';
import { palette } from '../../configs';

interface ProfilePageProps {}

export const ProfilePage: React.FC<ProfilePageProps> = () => {
  const user = useSelector(selectUser);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  
  return (
    <Box
      sx={{
        paddingTop: 3,
        paddingBottom: 6,
        width: '100%',
        maxWidth: 900,
        marginX: 'auto',
      }}
    >
      <Typography
        variant={windowSize.width < theme.breakpoints.values.sm ? 'h5' : 'h4'}
        sx={{
          textAlign: 'center',
          marginBottom: 4,
          color: palette.primary.dark,
        }}
      >
        Update your profile.
      </Typography>

      <Paper>
        <Box paddingX={{ xs: 2, sm: 4 }} paddingY={4}>
          <ProfileForm user={user} next={() => navigate(ROUTES.COMPARE_MY_OFFERS)} visibleOffersLink />
        </Box>
      </Paper>
    </Box>
  );
};
