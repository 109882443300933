import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const loanTermsService = (function () {
  const endpoint_get_loan_terms = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_loan_terms);
  };

  return {
    endpoint_get_loan_terms,
  };
})();
