import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';

import { EnhancedTable } from '../../components';
import { collegesTableColumns } from '../../configs';
import { DEFAULT_ROWS_OPTIONS_PER_PAGE } from '../../consts';
import { ICollege } from '../../models';
import { collegesAction, dialogsAction } from '../../store/actions';
import { selectCollegesWithOffersCount } from '../../store/selectors';
import { convertCollegesToTableData, getYearsList } from '../../utils';

interface CollegesPageProps {}

export const CollegesPage: React.FC<CollegesPageProps> = () => {
  const dispatch = useDispatch();
  const basicColleges = useSelector(selectCollegesWithOffersCount);
  const [selectedYear, setSelectedYear] = React.useState<number>(-1);
  const [colleges, setColleges] = React.useState<Array<ICollege>>([]);
  const columns = collegesTableColumns;
  const columnKeys = columns.map((item) => item.id);
  const definedYears = getYearsList();

  React.useEffect(() => {
    if (basicColleges.length > 0 && selectedYear) {
      const newColleges = basicColleges.map((item) => {
        if (selectedYear === -1) {
          return item;
        }

        const offers = item.offerYears.filter((offerYearItem) => offerYearItem.year === selectedYear).length;

        return {
          ...item,
          offers,
        };
      });

      setColleges(newColleges);
    }
  }, [basicColleges, selectedYear]);

  const onEdit = (id: number) => {
    dispatch(collegesAction.setSelectedCollegeId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        adminEditCollegeDialog: true,
      }),
    );
  };

  return (
    <Box>
      <Paper>
        <Box
          sx={{
            paddingTop: 3,
            paddingBottom: 2,
            paddingX: 2,
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Year for Offers</InputLabel>
            <Select
              value={selectedYear}
              label="Select Year for Offers"
              onChange={(event) => {
                const year = event.target.value;
                setSelectedYear(Number(year));
              }}
            >
              <MenuItem key={`year-for-offers-all`} value={-1}>
                All
              </MenuItem>

              {definedYears.map((item, index) => (
                <MenuItem key={`year-for-offers-${index}`} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>

      <Box
        sx={{
          marginTop: 2,
        }}
      >
        <EnhancedTable
          config={{
            toolbar: true,
            rowCheckable: false,
            rowActionVisible: true,
          }}
          loading={false}
          loadingLabel={'Loading Colleges'}
          noDataLabel={'No College'}
          noDataSectionClassnames={'h-24'}
          toolbarTitle={'Colleges'}
          data={colleges}
          convertFunctionForDataToTableData={convertCollegesToTableData}
          headColumns={columns}
          columnKeys={columnKeys}
          styles={{
            tableStyle: {
              minWidth: 0,
            },
          }}
          initialRowsPerPage={10}
          visiblePagination={true}
          exportable={false}
          exporting={false}
          filename={''}
          getCsvHeaders={() => {}}
          getCsvData={() => {}}
          csvDataOtherProps={() => {}}
          rowActions={{
            onEdit,
          }}
          definedRowsPerPageOptions={[
            ...DEFAULT_ROWS_OPTIONS_PER_PAGE,
            {
              value: 50,
              label: '50',
            },
            {
              value: 100,
              label: '100',
            },
          ]}
        />
      </Box>
    </Box>
  );
};
