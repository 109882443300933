import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { dialogsAction, offersAction } from '../store/actions';
import { selectLoadingState, selectDialogsState, selectMyOffersSelectedOffer } from '../store/selectors';
import { palette } from '../configs';

interface AcceptedOfferChangeDialogProps {}

export const AcceptedOfferChangeDialog: React.FC<AcceptedOfferChangeDialogProps> = () => {
  const dispatch = useDispatch();
  const { acceptedOfferChangeDialog = false } = useSelector(selectDialogsState);
  const { acceptedOfferUpdating = false } = useSelector(selectLoadingState);
  const offer = useSelector(selectMyOffersSelectedOffer);

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      dispatch(
        dialogsAction.updateDialogsStatus({
          acceptedOfferChangeDialog: false,
        }),
      );
    }
  };

  const onSubmit = () => {
    dispatch(offersAction.updateOfferAccepted());
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      scroll={'paper'}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
      open={acceptedOfferChangeDialog}
    >
      <DialogTitle
        style={{
          color: palette.primary.dark,
          fontFamily: 'Montserrat',
          fontWeight: 600,
        }}
      >
        Confirm
      </DialogTitle>

      <DialogContent>
        <Box paddingBottom={2}>
          <Typography color="textSecondary" gutterBottom>
            Are you sure you want to change the status of this offer for {offer?.collegeName}?
          </Typography>
          <Typography color="textSecondary">This status will be shown to other users.</Typography>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          borderTop: '1px solid #E4E6ED',
          paddingY: 1.5,
          paddingX: 3,
        }}
      >
        <Button
          onClick={onSubmit}
          color="secondary"
          variant="contained"
          style={{
            color: 'white',
            marginRight: 8,
            borderRadius: 20,
          }}
        >
          Confirm
          {acceptedOfferUpdating && (
            <Box marginLeft={2} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" size={16} />
            </Box>
          )}
        </Button>

        <Button style={{ borderRadius: 20 }} onClick={() => onClose(null, 'anyway')} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
