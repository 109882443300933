import { ELoanTermsActionTypes } from '../../enums';

const getLoanTerms = () => {
  return {
    type: ELoanTermsActionTypes.GET_LOAN_TERMS,
  };
};

const setLoanTerms = (payload: Array<any>) => {
  return {
    type: ELoanTermsActionTypes.SET_LOAN_TERMS,
    payload,
  };
};

export const loanTermsAction = {
  getLoanTerms,
  setLoanTerms,
};
