import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { HIGHSCHOOL_NOT_LISTED } from '../consts';
import { EGender, EUserRoles } from '../enums';
import { IEfc, IHighschool, IState, IUser } from '../models';
import { highschoolsAction, usersAction } from '../store/actions';
import { selectEfcs, selectHighschools, selectStates } from '../store/selectors';
import { getYearsOfGraduationList } from '../utils';

interface AdminEditUserFormProps {
  user?: IUser;
  next?: Function;
}

export const AdminEditUserForm = React.forwardRef((props: AdminEditUserFormProps, ref) => {
  const dispatch = useDispatch();
  const states = useSelector(selectStates);
  const efcs = useSelector(selectEfcs);
  const highschools = useSelector(selectHighschools);
  const { user, next = () => {} } = props;

  const formManager = useFormik({
    initialValues: {
      id: user?.id || '',
      email: user?.email || '',
      role: user?.role || EUserRoles.STUDENT,
      termsOfService: user?.termsOfService || false,
      newsletters: user?.newsletters || false,
      stateId: user?.stateId || '',
      highschoolId: user?.highschoolId || '',
      efcId: user?.efcId || '',
      gpa: user?.gpa || '',
      name: user?.name || '',
      yearOfHighSchoolGraduation: user?.yearOfHighSchoolGraduation || '',
      entranceExam: user?.entranceExam || false,
      sat: user?.sat || '',
      act: user?.act || '',
      gender: user?.gender || EGender.MALE,
      athlete: user?.athlete || false,
    },
    validationSchema: Yup.object().shape(
      {
        role: Yup.string().nullable().required('You must select a user type.'),
        stateId: Yup.number().nullable().required('State of residence must be selected.'),
        highschoolId: Yup.number().nullable().required('Highschool must be selected.'),
        efcId: Yup.number().nullable().required('EFC option must be selected.'),
        gpa: Yup.number()
          .nullable()
          .required('GPA is required.')
          .min(0, 'GPA must be greater than 0.')
          .max(4.0, 'GPA must be less than 4.0.'),
        name: Yup.string().trim().nullable().required('Full Name must be entered'),
        yearOfHighSchoolGraduation: Yup.number().nullable().required('Year of Graduation must be selected'),
        sat: Yup.number().when(['entranceExam', 'act'], {
          is: (entranceExam: boolean, act: number) => entranceExam && !act,
          then: Yup.number()
            .nullable()
            .required('At least one of Sat and Act must be entered.')
            .min(0, 'Sat must be greater than 0.')
            .max(1600, 'Sat must be less than 1600.'),
          otherwise: Yup.number()
            .nullable()
            .min(0, 'Sat must be greater than 0.')
            .max(1600, 'Sat must be less than 1600.'),
        }),
        act: Yup.number().when(['entranceExam', 'sat'], {
          is: (entranceExam: boolean, sat: number) => entranceExam && !sat,
          then: Yup.number()
            .nullable()
            .required('At least one of Sat and Act must be entered.')
            .min(0, 'Act must be greater than 0.')
            .max(36, 'Act must be less than 36.'),
          otherwise: Yup.number().nullable().min(0, 'Act must be greater than 0.').max(36, 'Act must be less than 36.'),
        }),
        gender: Yup.string().nullable().required('Gender must be selected'),
        athlete: Yup.boolean().nullable().required('Athlete must be selected'),
      },
      [['sat', 'act']],
    ),
    onSubmit: (values) => {
      const {
        id,
        email,
        role,
        stateId,
        highschoolId,
        efcId,
        gpa,
        name,
        yearOfHighSchoolGraduation,
        entranceExam,
        sat,
        act,
        gender,
        athlete,
        termsOfService,
        newsletters,
      } = values;

      if (!entranceExam) {
        formManager.setFieldValue('act', null);
        formManager.setFieldValue('sat', null);
      }

      const newUser = {
        id,
        email,
        role,
        stateId,
        highschoolId,
        efcId,
        gpa: Number(gpa),
        name,
        yearOfHighSchoolGraduation,
        entranceExam,
        sat: entranceExam ? sat || null : null,
        act: entranceExam ? act || null : null,
        gender,
        athlete,
        termsOfService,
        newsletters,
      };

      dispatch(usersAction.updateUser({ user: newUser as IUser, next }));
    },
  });

  React.useEffect(() => {
    const stateId = formManager.values.stateId as number;

    if (stateId) {
      dispatch(highschoolsAction.getHighschools({ stateId }));
    }
  }, [formManager.values.stateId]);

  React.useEffect(() => {
    const newHighschool = highschools.find((highschool) => highschool.id === user?.highschoolId) || null;
    const newHighschoolId = newHighschool ? newHighschool.id : null;

    formManager.setFieldValue('highschoolId', newHighschoolId);
  }, [highschools]);

  React.useImperativeHandle(
    ref,
    () => ({
      onSubmit() {
        formManager.handleSubmit();
      },
    }),
    [], // eslint-disable-line
  );

  const onChangeNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else if (val.includes('.')) {
      if (Number(val) > 0) {
        formManager.setFieldValue(fieldName, Number(val));
      } else {
        formManager.setFieldValue(fieldName, val);
      }
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  const onBlurNumber = (val: string, fieldName: string) => {
    if (val.length === 0) {
      formManager.setFieldValue(fieldName, '');
    } else {
      formManager.setFieldValue(fieldName, `${Number(val)}`);
    }
  };

  return (
    <Box paddingY={2}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            value={formManager.values.role}
            onChange={(event, value) => {
              formManager.setFieldValue('role', value);
            }}
          >
            <Grid container spacing={{ xs: 1, md: 4 }}>
              <Grid item xs={12} md={3}>
                <FormControlLabel value={EUserRoles.STUDENT} control={<Radio />} label="Student" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel value={EUserRoles.PARENT} control={<Radio />} label="Parent" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel value={EUserRoles.PROFESSIONAL} control={<Radio />} label="Professional" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel value={EUserRoles.ADMIN} control={<Radio />} label="Admin" />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField label="User ID" variant="outlined" fullWidth value={formManager.values.id} disabled />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField label="Email" variant="outlined" fullWidth value={formManager.values.email} disabled />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!(formManager.errors.name && formManager.touched.name)}>
            <InputLabel>Full Name</InputLabel>
            <OutlinedInput
              value={formManager.values.name}
              onChange={(event) => formManager.setFieldValue('name', event.target.value)}
              onBlur={(event) => formManager.setFieldValue('name', event.target.value)}
              type="string"
              label="Full Name"
            />
            <FormHelperText error>{formManager.touched.name ? formManager.errors.name : null}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={states.find((state) => state.id === formManager.values.stateId)}
            disablePortal
            options={states}
            getOptionLabel={(option: IState) => `${option.name} - ${option.code}`}
            onChange={(event: any, newValue: any) => {
              formManager.setFieldValue('stateId', newValue?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Student’s State of Residence"
                error={!!(formManager.errors.stateId && formManager.touched.stateId)}
                helperText={formManager.touched.stateId ? formManager.errors.stateId : null}
              />
            )}
            autoHighlight
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={highschools.find((highschool) => highschool.id === formManager.values.highschoolId) || null}
            disablePortal
            options={highschools}
            getOptionLabel={(option: IHighschool) => option.name}
            onChange={(event: any, newValue: IHighschool | null) => {
              formManager.setFieldValue('highschoolId', newValue?.id || null);
            }}
            renderOption={(props, option) => {
              return (
                <div key={option.id}>
                  <li {...props}>{option.name}</li>

                  {option.name === HIGHSCHOOL_NOT_LISTED && <Divider />}
                </div>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="High school"
                error={!!(formManager.errors.highschoolId && formManager.touched.highschoolId)}
                helperText={formManager.touched.highschoolId ? formManager.errors.highschoolId : null}
              />
            )}
            autoHighlight
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={efcs.find((efc) => efc.id === formManager.values.efcId) || null}
            disablePortal
            options={efcs}
            getOptionLabel={(option: IEfc) => option.name}
            onChange={(event: any, newValue: IEfc | null) => {
              formManager.setFieldValue('efcId', newValue?.id || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="EFC"
                error={!!(formManager.errors.efcId && formManager.touched.efcId)}
                helperText={formManager.touched.efcId ? formManager.errors.efcId : null}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!(formManager.errors.gpa && formManager.touched.gpa)}>
            <InputLabel>Unweighted GPA</InputLabel>
            <OutlinedInput
              value={formManager.values.gpa}
              onChange={(event) => onChangeNumber(event.target.value, 'gpa')}
              onBlur={(event) => onBlurNumber(event.target.value, 'gpa')}
              type="number"
              inputProps={{ step: 0.1 }}
              label="Unweighted GPA"
            />
            <FormHelperText error>{formManager.touched.gpa ? formManager.errors.gpa : null}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={
              getYearsOfGraduationList().find((year) => year.value === formManager.values.yearOfHighSchoolGraduation) ||
              null
            }
            disablePortal
            options={getYearsOfGraduationList()}
            getOptionLabel={(option: { id: number; name: string; value: number }) => option.name}
            onChange={(event: any, newValue: { id: number; name: string; value: number } | null) => {
              formManager.setFieldValue('yearOfHighSchoolGraduation', newValue?.value || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Year of Graduation"
                error={
                  !!(formManager.errors.yearOfHighSchoolGraduation && formManager.touched.yearOfHighSchoolGraduation)
                }
                helperText={
                  formManager.touched.yearOfHighSchoolGraduation ? formManager.errors.yearOfHighSchoolGraduation : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <RadioGroup
            value={formManager.values.entranceExam}
            onChange={(event, value) => {
              formManager.setFieldValue('entranceExam', value === 'true');
            }}
          >
            <Grid container spacing={{ xs: 1, md: 4 }}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={
                    <Typography
                      sx={{
                        lineHeight: 1.3,
                      }}
                    >
                      Student took the ACT and/or SAT
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={
                    <Typography
                      sx={{
                        lineHeight: 1.3,
                      }}
                    >
                      Student did not take the ACT and/or SAT
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        {formManager.values.entranceExam && (
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!(formManager.errors.sat && formManager.touched.sat)}>
                  <InputLabel>SAT</InputLabel>
                  <OutlinedInput
                    value={formManager.values.sat}
                    onChange={(event) => onChangeNumber(event.target.value, 'sat')}
                    onBlur={(event) => onBlurNumber(event.target.value, 'sat')}
                    type="number"
                    inputProps={{ step: 0.1 }}
                    label="SAT"
                  />
                  <FormHelperText>{formManager.touched.sat ? formManager.errors.sat : null}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!(formManager.errors.act && formManager.touched.act)}>
                  <InputLabel>ACT</InputLabel>
                  <OutlinedInput
                    value={formManager.values.act}
                    onChange={(event) => onChangeNumber(event.target.value, 'act')}
                    onBlur={(event) => onBlurNumber(event.target.value, 'act')}
                    type="number"
                    inputProps={{ step: 0.1 }}
                    label="ACT"
                  />
                  <FormHelperText>{formManager.touched.act ? formManager.errors.act : null}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Typography
            color="textSecondary"
            sx={{
              fontSize: 12,
              marginRight: 4,
            }}
          >
            Gender
          </Typography>

          <RadioGroup
            value={formManager.values.gender}
            onChange={(event, value) => {
              formManager.setFieldValue('gender', value);
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormControlLabel value={EGender.MALE} control={<Radio />} label={<Typography>Male</Typography>} />
              <FormControlLabel
                value={EGender.FEMALE}
                control={<Radio />}
                label={<Typography>Female</Typography>}
                sx={{
                  marginLeft: 4,
                }}
              />
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            color="textSecondary"
            sx={{
              fontSize: 12,
              marginRight: 4,
            }}
          >
            Athlete
          </Typography>

          <RadioGroup
            value={formManager.values.athlete}
            onChange={(event, value) => {
              formManager.setFieldValue('athlete', value === 'true');
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label={<Typography>Yes</Typography>} />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={<Typography>No</Typography>}
                sx={{
                  marginLeft: 4,
                }}
              />
            </Box>
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formManager.values.termsOfService}
                onChange={(event, checked) => {
                  formManager.setFieldValue('termsOfService', checked);
                }}
                sx={{
                  paddingY: 0.5,
                  paddingX: 1.2,
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  lineHeight: 1.3,
                }}
              >
                Accepted the terms of service
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formManager.values.newsletters}
                onChange={(event, checked) => {
                  formManager.setFieldValue('newsletters', checked);
                }}
                sx={{
                  paddingY: 0.5,
                  paddingX: 1.2,
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  lineHeight: 1.3,
                }}
              >
                Newsletters
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
});
