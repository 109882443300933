import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography } from '@mui/material';

import { ROUTES } from '../../consts';
import { ProfileForm } from '../../forms';
import { selectUser } from '../../store/selectors';

interface OnboardingPageProps {}

export const OnboardingPage: React.FC<OnboardingPageProps> = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        paddingTop: 3,
        paddingBottom: 6,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          marginBottom: 4,
          color: '#464646',
          fontFamily: 'Montserrat',
        }}
      >
        Please complete your profile.
      </Typography>

      <Paper
        sx={{
          padding: 4,
        }}
      >
        <ProfileForm user={user} next={() => navigate(ROUTES.COMPARE_MY_OFFERS)} />
      </Paper>
    </Container>
  );
};
