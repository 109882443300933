import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const usersService = (function () {
  const endpoint_get_users = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_users);
  };

  return {
    endpoint_get_users,
  };
})();
