import { ELendersActionTypes } from '../../enums';

const getLenders = () => {
  return {
    type: ELendersActionTypes.GET_LENDERS,
  };
};

const setLenders = (payload: Array<any>) => {
  return {
    type: ELendersActionTypes.SET_LENDERS,
    payload,
  };
};

export const lendersAction = {
  getLenders,
  setLenders,
};
