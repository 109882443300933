import { produce } from 'immer';

import { createReducer } from '.';
import { creditScoreRangesActionTypes } from '../../consts';
import { IAction, ICreditScoreRangesState } from '../../models';
import { creditScoreRangesState } from '../states/credit-score-ranges.state';

export const creditScoreRangesReducer = createReducer<ICreditScoreRangesState>(creditScoreRangesState, {
  [creditScoreRangesActionTypes.SET_CREDIT_SCORE_RANGES]: setCreditScoreRanges,
});

function setCreditScoreRanges(creditScoreRange: ICreditScoreRangesState, { payload }: IAction<Array<any>>) {
  return produce(creditScoreRange, (draft) => {
    draft.creditScoreRanges = payload;
  });
}
