import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const creditScoreRangesService = (function () {
  const endpoint_get_credit_score_ranges = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_credit_score_ranges);
  };

  return {
    endpoint_get_credit_score_ranges,
  };
})();
