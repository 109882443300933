import { produce } from 'immer';

import { createReducer } from '.';
import { lendersActionTypes } from '../../consts';
import { IAction, ILendersState } from '../../models';
import { lendersState } from '../states/lenders.state';

export const lendersReducer = createReducer<ILendersState>(lendersState, {
  [lendersActionTypes.SET_LENDERS]: setLenders,
});

function setLenders(lender: ILendersState, { payload }: IAction<Array<any>>) {
  const sortedLenders = (payload || []).sort((a, b) => (a?.name === b?.name ? 0 : a?.name > b?.name ? 1 : -1));

  return produce(lender, (draft) => {
    draft.lenders = sortedLenders;
  });
}
