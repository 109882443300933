import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { EnhancedTable } from '../../components';
import { loanOffersTableColumns } from '../../configs';
import { convertLoanOffersToTableData } from '../../utils';
import { selectLoadingState, selectLoanOffers } from '../../store/selectors';
import { dialogsAction, loanOffersAction } from '../../store/actions';

interface LoanOffersPageProps {}

export const LoanOffersPage: React.FC<LoanOffersPageProps> = () => {
  const dispatch = useDispatch();
  const offers = useSelector(selectLoanOffers);
  const { loanOffersGettingFlag = false } = useSelector(selectLoadingState);
  const columns = loanOffersTableColumns;
  const columnKeys = columns.map((item) => item.id);

  const onEdit = (id: number) => {
    dispatch(loanOffersAction.setMyLoanOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        loanOfferUpdateDialog: true,
      }),
    );
  };

  return (
    <Box>
      <EnhancedTable
        config={{
          toolbar: true,
          rowCheckable: false,
          rowActionVisible: true,
        }}
        loading={loanOffersGettingFlag}
        loadingLabel={'Loading Loan Offers'}
        noDataLabel={'No Loan Offer'}
        noDataSectionClassnames={'h-24'}
        toolbarTitle="Loan Offers"
        data={offers}
        convertFunctionForDataToTableData={convertLoanOffersToTableData}
        headColumns={columns}
        columnKeys={columnKeys}
        styles={{
          tableStyle: {
            minWidth: 0,
          },
          color: '#464646',
        }}
        initialRowsPerPage={10}
        visiblePagination={true}
        rowActions={{
          onEdit,
        }}
      />
    </Box>
  );
};
