import { all, call, put, takeLatest } from 'redux-saga/effects';

import { EEfcsActionTypes } from '../../enums';
import { IEfc } from '../../models';
import { efcsService } from '../../services';
import { loadingAction, efcsAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EEfcsActionTypes.GET_EFCS as any, watchGetEfcs)]);
}

function* watchGetEfcs() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getEfcsLoading: true,
      }),
    );

    const { data } = yield call(efcsService.endpoint_get_efcs);

    yield put(efcsAction.setEfcs(data as Array<IEfc>));
  } catch (error: any) {
    console.error('watchGetEfcs: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        getEfcsLoading: false,
      }),
    );
  }
}
