import { produce } from 'immer';

import { createReducer } from '.';
import { notificationActionTypes } from '../../consts';
import { IAction, INotificationState } from '../../models';
import { notificationState } from '../states/notification.state';

export const notificationReducer = createReducer<INotificationState>(notificationState, {
  [notificationActionTypes.UPDATE_NOTIFICATION_STATUS]: updateNotificationStatus,
});

function updateNotificationStatus(state: INotificationState, { payload }: IAction<INotificationState>) {
  return produce(state, (draft) => {
    draft.variant = payload.variant;
    draft.message = payload.message;
  });
}
