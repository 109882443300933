import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ELendersActionTypes } from '../../enums';
import { IIdAndName } from '../../models';
import { lendersService } from '../../services';
import { loadingAction, lendersAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ELendersActionTypes.GET_LENDERS as any, watchGetLenders)]);
}

function* watchGetLenders() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        lendersGettingFlag: true,
      }),
    );

    const { data } = yield call(lendersService.endpoint_get_lenders);

    yield put(lendersAction.setLenders(data as Array<IIdAndName>));
  } catch (error: any) {
    console.error('watchGetLenders: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        lendersGettingFlag: false,
      }),
    );
  }
}
