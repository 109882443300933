import { createSelector } from 'reselect';

import { INotificationState, IRootState } from '../../models';

export const selectNotificationState = (rootState: IRootState): INotificationState => rootState.notificationState;

export const selectVariant = createSelector(
  [selectNotificationState],
  (notificationState) => notificationState.variant,
);

export const selectMessage = createSelector(
  [selectNotificationState],
  (notificationState) => notificationState.message,
);
