import { createSelector } from 'reselect';

import { IRepaymentTypesState, IRootState } from '../../models';

export const selectRepaymentTypesState = (rootState: IRootState): IRepaymentTypesState => rootState.repaymentTypesState;

export const selectRepaymentTypes = createSelector(
  [selectRepaymentTypesState],
  (repaymentTypesState) => repaymentTypesState.repaymentTypes,
);
