import { ILoanOffersState } from '../../models';

export const loanOffersState: ILoanOffersState = {
  loanOffers: [],
  myLoanOffersSelectedCollegeId: -1,
  myLoanOffersSelectedYear: -1,
  myLoanOffersSelectedOfferId: -1,
  otherLoanOffersSelectedLenderId: -1,
  otherLoanOffersSelectedYear: -1,
};
