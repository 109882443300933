import { Box } from '@mui/material';

import { IEnhancedTableHeadCell } from '../models';

export const myOffersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'collegeName',
    label: 'College',
    align: 'left',
    disablePadding: false,
    sortable: true,
    width: 140,
    classes: 'table-header',
  },
  {
    id: 'admissionTypeName',
    label: 'Admission Type',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'grantFinancialNeed',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 84,
        }}
      >
        Need Based Aid
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'grantFinancialNeedRenewableLabel',
    label: 'Renewable?',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'grantNonFinancialNeed',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 84,
        }}
      >
        Merit Based Scholarship
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'grantNonFinancialNeedRenewableLabel',
    label: 'Renewable?',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'totalPellAndStateGrants',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 84,
        }}
      >
        Pell & State Grants
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'totalUnsubsidiziedFederalLoans',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 80,
        }}
      >
        Unsubsidized Loans
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'totalSubsidizedFederalLoans',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 80,
        }}
      >
        Subsidized Loans
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'offerCoa',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 70,
        }}
      >
        Cost of Attendance
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'netCost',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 70,
        }}
      >
        Net Cost to You *
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'accepted',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 70,
        }}
      >
        Accepted Offer?
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: false,
    classes: 'table-header',
  },
];

export const otherOffersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'collegeNameWithState',
    label: 'College',
    align: 'left',
    disablePadding: false,
    sortable: true,
    width: 140,
    classes: 'table-header',
  },
  {
    id: 'year',
    label: 'Year',
    align: 'left',
    disablePadding: false,
    sortable: true,
    width: 100,
    classes: 'table-header',
  },
  {
    id: 'acceptedLabel',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 70,
        }}
      >
        Accepted Offer?
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'userEfcMin',
    label: 'SAI/EFC',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'userStateCode',
    label: 'User State',
    align: 'right',
    cellAlign: 'center',
    disablePadding: false,
    sortable: true,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'userGpa',
    label: 'GPA',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'userSat',
    label: 'SAT',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'userAct',
    label: 'ACT',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'admissionTypeName',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 80,
        }}
      >
        Admission Type
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'grantFinancialNeed',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 70,
        }}
      >
        Need-Based Aid
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'grantNonFinancialNeed',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 90,
        }}
      >
        Merit-Based Scholarships
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'totalPellAndStateGrants',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 80,
        }}
      >
        Pell & State Grants
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'totalPrivateScholarship',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 80,
        }}
      >
        Private Scholarships
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'acceptMoneyLabel',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 110,
        }}
      >
        Did not receive any money
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'honorsProgramLabel',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 110,
        }}
      >
        Got into an honors program
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'updatedAt',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 110,
        }}
      >
        Last Updated At
      </Box>
    ),
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
];

export const loanOffersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'lender',
    label: 'Lender',
    align: 'left',
    disablePadding: false,
    sortable: false,
    classes: 'table-header',
  },
  {
    id: 'interestRate',
    label: 'APR',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'termOfLoan',
    label: 'Rate Type',
    align: 'left',
    disablePadding: false,
    sortable: false,
    classes: 'table-header',
  },
  {
    id: 'repaymentTypeLabel',
    label: 'Repayment Type',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'coSignorLabel',
    label: 'Cosigner',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'creditScoreRangeText',
    label: 'Credit Score',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'monthlyPaymentAmount',
    label: 'Monthly Payment',
    align: 'left',
    disablePadding: false,
    sortable: false,
    classes: 'table-header',
  },
  {
    id: 'totalLoanAmount',
    label: 'Total Loan Amount',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
];

export const myLoanOffersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'lender',
    label: 'Lender',
    align: 'left',
    disablePadding: false,
    sortable: false,
    classes: 'table-header',
  },
  {
    id: 'interestRate',
    label: 'APR',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'termOfLoan',
    label: 'Rate Type',
    align: 'left',
    disablePadding: false,
    sortable: false,
    classes: 'table-header',
  },
  {
    id: 'repaymentTypeLabel',
    label: 'Repayment Type',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'loanLife',
    label: 'Repayment Length(years)',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'coSignorLabel',
    label: 'Cosigner',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'monthlyPaymentAmount',
    label: 'Monthly Payment',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'totalLoanAmount',
    label: 'Total Loan Amount',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
];

export const otherLoanOffersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'lender',
    label: 'Lender',
    align: 'left',
    disablePadding: false,
    sortable: false,
    classes: 'table-header',
  },
  {
    id: 'creditScoreRangeText',
    label: 'Credit Score',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'interestRate',
    label: 'APR',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'termOfLoan',
    label: 'Rate Type',
    align: 'left',
    disablePadding: false,
    sortable: false,
    classes: 'table-header',
  },
  {
    id: 'repaymentTypeLabel',
    label: 'Repayment Type',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'loanLife',
    label: 'Repayment Length(years)',
    align: 'left',
    disablePadding: false,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'coSignorLabel',
    label: 'Cosigner',
    align: 'left',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
];

export const usersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'id',
    label: 'User Id',
    align: 'left',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'email',
    label: 'Email',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    textFilter: true,
    classes: 'table-header',
  },
  {
    id: 'name',
    label: 'Full Name',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    textFilter: true,
    classes: 'table-header',
  },
  {
    id: 'userStateCode',
    label: 'State',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'role',
    label: 'Role',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'userEfcName',
    label: 'Efc',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'yearOfHighSchoolGraduation',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 180,
        }}
      >
        Year of High School Graduation
      </Box>
    ),
    align: 'center',
    cellAlign: 'center',
    disablePadding: false,
    width: 140,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'createdAtLabel',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
        }}
      >
        Created At
      </Box>
    ),
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    textFilter: true,
    classes: 'table-header',
  },
  {
    id: 'offers',
    label: '# Of Offers',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
];

export const collegesTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'id',
    label: 'College Id',
    align: 'left',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'collegeNameWithState',
    label: 'College Name',
    disablePadding: false,
    align: 'right',
    cellAlign: 'right',
    width: 280,
    sortable: true,
    textFilter: true,
    classes: 'table-header',
  },
  {
    id: 'offers',
    label: '# Of Offers',
    disablePadding: false,
    align: 'right',
    cellAlign: 'right',
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'coaPrivate',
    label: 'COA (Private)',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'coaInState',
    label: 'COA (In State)',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'coaOutState',
    label: 'COA (Out State)',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
];

export const offersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'id',
    label: 'Offer Id',
    align: 'left',
    disablePadding: false,
    width: 80,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'collegeNameWithState',
    label: 'College',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    sortable: true,
    width: 180,
    textFilter: true,
    classes: 'table-header',
  },
  {
    id: 'userEmail',
    label: 'User',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    sortable: true,
    width: 140,
    textFilter: true,
    classes: 'table-header',
  },
  {
    id: 'acceptedLabel',
    label: 'Accepted Offer?',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    sortable: false,
    width: 140,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'userEfcName',
    label: 'Efc',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    sortable: false,
    width: 140,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'grantFinancialNeed',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 84,
        }}
      >
        Need Based Aid
      </Box>
    ),
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'grantNonFinancialNeed',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 84,
        }}
      >
        Merit Based Scholarship
      </Box>
    ),
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'yearLabel',
    label: 'Year',
    disablePadding: false,
    align: 'right',
    cellAlign: 'right',
    width: 140,
    sortable: true,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'publishedLabel',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 70,
        }}
      >
        Top Offer?
      </Box>
    ),
    align: 'right',
    disablePadding: false,
    sortable: false,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'published',
    label: (
      <Box
        sx={{
          display: 'inline-flex',
          whiteSpace: 'break-spaces',
          width: 120,
        }}
      >
        Set as Top Offer?
      </Box>
    ),
    align: 'right',
    disablePadding: false,
    sortable: false,
    checkboxFilter: false,
    classes: 'table-header',
  },
];

export const topOffersTableColumns: IEnhancedTableHeadCell[] = [
  {
    id: 'collegeNameWithState',
    label: 'College',
    align: 'left',
    cellAlign: 'left',
    disablePadding: false,
    sortable: true,
    width: 280,
    textFilter: true,
    classes: 'table-header',
  },
  {
    id: 'userEfcName',
    label: 'Efc',
    align: 'left',
    cellAlign: 'left',
    disablePadding: false,
    sortable: false,
    width: 140,
    checkboxFilter: true,
    classes: 'table-header',
  },
  {
    id: 'grantFinancialNeed',
    label: 'Need Based Aid',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'grantNonFinancialNeed',
    label: 'Merit Based Scholarship',
    align: 'right',
    cellAlign: 'right',
    disablePadding: false,
    width: 140,
    sortable: true,
    classes: 'table-header',
  },
  {
    id: 'yearLabel',
    label: 'Year',
    disablePadding: false,
    align: 'right',
    cellAlign: 'right',
    width: 140,
    sortable: true,
    checkboxFilter: true,
    classes: 'table-header',
  },
];
