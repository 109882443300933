import React from 'react';
import { Box, Typography } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { selectMessage, selectVariant } from '../store/selectors';
import { notificationAction } from '../store/actions';

interface NotificationsLayoutProps {}

export const NotificationsLayout: React.FC<NotificationsLayoutProps> = () => {
  return (
    <SnackbarProvider maxSnack={5}>
      <NotificationListener />
    </SnackbarProvider>
  );
};

const NotificationListener = () => {
  const dispatch = useDispatch();
  const variant = useSelector(selectVariant);
  const message = useSelector(selectMessage);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (message) {
      enqueueSnackbar(message, { variant });

      setTimeout(() => {
        dispatch(
          notificationAction.updateNotificationStatus({
            variant: 'error',
            message: '',
          }),
        );
      }, 500);
    }
  }, [message]);

  return (
    <Box display="none">
      <Typography>Show Notification</Typography>
    </Box>
  );
};
