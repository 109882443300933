import * as React from 'react';
import { CSVLink } from 'react-csv';
import { Badge, Box, CircularProgress, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { ClearAll, CloudDownload, Delete } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { IEnhancedTableCheckboxFilter, IEnhancedTableTextFilter } from '../models';
import { isAppliedFilters } from '../utils';
import { palette } from '../configs';

export interface EnhancedTableToolbarProps {
  title: string;
  numSelected: number;
  defaultCheckboxFilters: IEnhancedTableCheckboxFilter;
  checkboxFilters: IEnhancedTableCheckboxFilter;
  textFilters: IEnhancedTableTextFilter;
  hideclearFilters?: boolean;
  clearFilters: () => void;
  onDelete: (payload: any) => void;
  exportable?: boolean;
  exporting?: boolean;
  filename?: string;
  getCsvHeaders?: Function;
  getCsvData?: Function;
  csvDataOtherProps?: any;
  filteredData?: Array<any>;
}

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = (props) => {
  const {
    title,
    numSelected,
    defaultCheckboxFilters,
    checkboxFilters,
    textFilters,
    hideclearFilters,
    clearFilters,
    onDelete,
    exportable,
    exporting,
    filename = 'data.csv',
    getCsvHeaders = () => [],
    getCsvData = (payload?: { data: any }) => [], // eslint-disable-line
    csvDataOtherProps = {},
    filteredData = [],
  } = props;
  const classes = useStyles();

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography
          style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, color: palette.primary.dark }}
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          style={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, color: palette.primary.dark }}
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {exportable &&
        (exporting ? (
          <Box
            width="43.19px"
            height="43.19px"
            padding="12px"
            marginLeft="6.4px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={16} />
          </Box>
        ) : (
          <CSVLink
            filename={filename}
            style={{
              color: '#76B041',
              fontWeight: 700,
              fontSize: 12,
              textTransform: 'uppercase',
              textDecoration: 'underline',
            }}
            headers={getCsvHeaders()}
            data={getCsvData({
              data: filteredData,
              ...csvDataOtherProps,
            })}
          >
            <Tooltip title="Export offers to CSV" arrow placement="top">
              <IconButton
                sx={{
                  marginX: 1,
                }}
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          </CSVLink>
        ))}

      {numSelected > 0 ? (
        <Tooltip title="Delete" arrow placement="top">
          <IconButton aria-label="delete" onClick={onDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
      ) : hideclearFilters ? null : (
        <Tooltip title="Clear all filters" arrow placement="top">
          <IconButton aria-label="filter list" onClick={() => clearFilters()}>
            <Badge
              color="secondary"
              variant="dot"
              invisible={!isAppliedFilters(defaultCheckboxFilters, checkboxFilters, textFilters)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <ClearAll />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles({
  root: {
    '&&': {
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(1),
    },
  },
  highlight: {
    color: 'red',
  },
  // theme.palette.type === 'light'
  //   ? {
  //       '&&': {
  //         color: theme.palette.primary.main,
  //         backgroundColor: lighten(theme.palette.primary.light, 0.85),
  //       },
  //     }
  //   : {
  //       '&&': {
  //         color: theme.palette.text.primary,
  //         backgroundColor: theme.palette.primary.dark,
  //       },
  //     },
  title: {
    '&&': {
      flex: '1 1 100%',
    },
  },
});
