import { ECreditScoreStatus, ELoanType, ERepaymentType, ETermOfLoan } from '../enums';
import { IIdAndName } from '../models';

export const definedLenders = [
  { id: 1, name: 'Ascent' },
  { id: 2, name: 'Brazos' },
  { id: 3, name: 'Citizens Bank' },
  { id: 4, name: 'Cognition Financial' },
  { id: 5, name: 'CollegeAve' },
  { id: 6, name: 'CommonBond' },
  { id: 7, name: 'Discover' },
  { id: 8, name: 'Earnest' },
  { id: 9, name: 'Elfi' },
  { id: 10, name: 'Funding University' },
  { id: 11, name: 'Laurel Road' },
  { id: 12, name: 'LendKey' },
  { id: 13, name: 'Nelnet' },
  { id: 14, name: 'Purefy' },
  { id: 15, name: 'Sallie Mae' },
  { id: 16, name: 'SoFi' },
  { id: 17, name: 'Splash Financial' },
  { id: 18, name: 'Stride Funding' },
  { id: 19, name: 'Parent Plus' },
  { id: 20, name: 'Federal Direct' },
] as Array<IIdAndName>;

export const definedCreditScoreRanges = [
  {
    status: ECreditScoreStatus.EXCELLENT,
    color: '#238236',
    start: 760,
    end: 850,
  },
  {
    status: ECreditScoreStatus.VERY_GOOD,
    color: '#6CB134',
    start: 700,
    end: 759,
  },
  {
    status: ECreditScoreStatus.GOOD,
    color: '#F8C30E',
    start: 660,
    end: 669,
  },
  {
    status: ECreditScoreStatus.FAIR,
    color: '#F27A19',
    start: 620,
    end: 659,
  },
  {
    status: ECreditScoreStatus.POOR,
    color: '#E82F1C',
    start: 580,
    end: 619,
  },
  {
    status: ECreditScoreStatus.VERY_POOR,
    color: '#AB0D1D',
    start: 500,
    end: 579,
  },
];

export const definedTermOfLoans = [ETermOfLoan.FIXED, ETermOfLoan.VARIABLE];

export const definedRepaymentTypes = [
  ERepaymentType.IMMEDIATE,
  ERepaymentType.INTEREST_ONLY,
  ERepaymentType.PARTIAL_PAYMENT,
  ERepaymentType.FULLY_DEFERRED,
];

export const definedLoanTypes = [ELoanType.IN_SCHOOL, ELoanType.REFINANCE];

export const definedLifeOfLoans = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
