import { EEfcsActionTypes } from '../../enums';

const getEfcs = () => {
  return {
    type: EEfcsActionTypes.GET_EFCS,
  };
};

const setEfcs = (payload: Array<any>) => {
  return {
    type: EEfcsActionTypes.SET_EFCS,
    payload,
  };
};

export const efcsAction = {
  getEfcs,
  setEfcs,
};
