import * as React from 'react';
import { useWindowSize } from 'react-use';
import { Button, Divider, IconButton, Stack, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Logout, Menu } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';

import { AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH } from '../configs';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH}px)`,
    marginLeft: `${AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AuthAdminHeaderLayoutProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const AuthAdminHeaderLayout: React.FC<AuthAdminHeaderLayoutProps> = (props) => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const { logout } = useAuth0();
  const { open, setOpen } = props;

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        {!open && (
          <IconButton
            color="inherit"
            style={{ color: 'white' }}
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2 }}
          >
            <Menu />
          </IconButton>
        )}

        {!open && (
          <Typography style={{ color: 'white' }} variant="h6" noWrap component="div">
            Road2College
          </Typography>
        )}

        <Stack
          direction="row"
          spacing={windowSize.width < theme.breakpoints.values.sm ? 1 : 2}
          divider={<Divider orientation="vertical" flexItem light />}
          sx={{
            marginLeft: 'auto',
          }}
        >
          {windowSize.width < theme.breakpoints.values.md ? (
            <Tooltip title="Log out" arrow>
              <IconButton
                color="inherit"
                size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
                onClick={() => {
                  logout({
                    returnTo: window.location.origin,
                  });
                }}
              >
                <Logout fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              color="inherit"
              startIcon={<Logout />}
              style={{ color: 'white' }}
              onClick={() => {
                logout({
                  returnTo: window.location.origin,
                });
              }}
            >
              Log out
            </Button>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
