/* eslint-disable */
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import mobilenavigate from '../../assets/images/icons/mobile-menu.svg';
import cross from '../../assets/images/icons/times-mobile.png';
import mobilemenuactive from '../../assets/images/icons/mobile-menu-active-icon.svg';
import { ROUTES } from '../../consts';

export const HeaderMobile = () => {
  const mobileBtn = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);

  //Dropwdowns
  const [menuDropdown, setMenuDropdownOpen] = useState(false);
  const toggleMenu = () => setMenuDropdownOpen((prevState) => !prevState);

  //Log User
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <>
          <div className="menu-mobile">
            <Dropdown
              isOpen={menuDropdown}
              toggle={toggleMenu}
              direction="down"
              className="dropdown-custom-header dropdown-bg-none text-black p-0"
            >
              <DropdownToggle caret>
                <img
                  className="menu"
                  ref={mobileBtn}
                  src={menuDropdown ? cross : mobilenavigate}
                  alt="Navigate menu Road2"
                />
              </DropdownToggle>
              <DropdownMenu style={{ width: width }}>
                <DropdownItem href="https://www.road2college.com/contact-us/">Contact Us</DropdownItem>
                <DropdownItem href="https://www.road2college.com/college-insights-faqs">FAQs</DropdownItem>
                <DropdownItem href={ROUTES.PROFILE}>Your Profile</DropdownItem>
                <DropdownItem onClick={() => logout()}>Log Out</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </>
      ) : (
        <>
          <span>
            <img
              className="menu"
              onClick={() => {
                setIsMenuVisible(!isMenuVisible);
              }}
              onBlur={() => {
                setIsMenuVisible(false);
              }}
              src={isMenuVisible ? mobilemenuactive : mobilenavigate}
              alt={'Navigate menu'}
            />

            {isMenuVisible && (
              <div className="profile-menu-mobile-registered">
                <div className="profile-menu-mobile-registered-container-btn">
                  <button
                    className="profile-menu-mobile-registered-get-started"
                    onClick={() => {
                      loginWithRedirect({ screen_hint: 'signup' });
                    }}
                  >
                    GET STARTED
                  </button>
                  <button className="profile-menu-mobile-registered-login" onClick={loginWithRedirect}>
                    LOGIN
                  </button>
                </div>
              </div>
            )}
          </span>
        </>
      )}
    </>
  );
};
