import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { EnhancedTable } from '../../components';
import { DEFAULT_ROWS_OPTIONS_PER_PAGE } from '../../consts';
import { offersTableColumns } from '../../configs';
import { dialogsAction, offersAction } from '../../store/actions';
import { selectOffersDetailed } from '../../store/selectors';
import { convertOffersToTableData, getOffersCsvData, getOffersCsvHeaders, offerscsvDataOtherProps } from '../../utils';

interface OffersPageProps {}

export const OffersPage: React.FC<OffersPageProps> = () => {
  const dispatch = useDispatch();
  let offers = useSelector(selectOffersDetailed);
  const columns = offersTableColumns;
  const columnKeys = columns.map((item) => item.id);

  const filename = `CCO_Offers_${dayjs(new Date()).format('YYYY_MM_DD')}`;
  const getCsvHeaders = getOffersCsvHeaders;
  const getCsvData = getOffersCsvData;
  const csvDataOtherProps = offerscsvDataOtherProps;

  const onUpdatePublished = (id: number) => {
    dispatch(offersAction.setOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        publicOfferChangeDialog: true,
      }),
    );
  };

  offers = offers.map((item) => ({
    ...item,
    onUpdatePublished,
  }));

  const onEdit = (id: number) => {
    dispatch(offersAction.setOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        adminEditOfferDialog: true,
      }),
    );
  };

  return (
    <Box>
      <EnhancedTable
        config={{
          toolbar: true,
          rowCheckable: false,
          rowActionVisible: true,
        }}
        loading={false}
        loadingLabel={'Loading Offers'}
        noDataLabel={'No Offer'}
        noDataSectionClassnames={'h-24'}
        toolbarTitle={'Offers'}
        data={offers}
        convertFunctionForDataToTableData={convertOffersToTableData}
        headColumns={columns}
        columnKeys={columnKeys}
        styles={{
          tableStyle: {
            minWidth: 0,
          },
        }}
        initialRowsPerPage={10}
        visiblePagination={true}
        exportable={true}
        exporting={false}
        filename={filename}
        getCsvHeaders={getCsvHeaders}
        getCsvData={getCsvData}
        csvDataOtherProps={csvDataOtherProps}
        rowActions={{
          onEdit,
        }}
        definedRowsPerPageOptions={[
          ...DEFAULT_ROWS_OPTIONS_PER_PAGE,
          {
            value: 50,
            label: '50',
          },
          {
            value: 100,
            label: '100',
          },
        ]}
      />
    </Box>
  );
};
