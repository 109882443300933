import { ECollegesActionTypes } from '../../enums';
import { ICollege } from '../../models';

const getColleges = () => {
  return {
    type: ECollegesActionTypes.GET_COLLEGES,
  };
};

const setColleges = (payload: Array<ICollege>) => {
  return {
    type: ECollegesActionTypes.SET_COLLEGES,
    payload,
  };
};

const setCollege = (payload: ICollege) => {
  return {
    type: ECollegesActionTypes.SET_COLLEGE,
    payload,
  };
};

const setSelectedCollegeId = (payload: number) => {
  return {
    type: ECollegesActionTypes.SET_SELECTED_COLLEGE_ID,
    payload,
  };
};

const updateCollege = (payload: { college: ICollege; next: Function }) => {
  return {
    type: ECollegesActionTypes.UPDATE_COLLEGE,
    payload,
  };
};

export const collegesAction = {
  getColleges,
  setColleges,
  setCollege,
  setSelectedCollegeId,
  updateCollege,
};
