import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { EUserActionTypes } from '../../enums';
import { IUser } from '../../models';
import { userService } from '../../services';
import { loadingAction, userAction } from '../actions';
import { selectUser } from '../selectors';

export default function* root() {
  yield all([takeLatest(EUserActionTypes.GET_USER as any, watchGetUser)]);
  yield all([takeLatest(EUserActionTypes.UPDATE_USER as any, watchUpdateUser)]);
}

function* watchGetUser(action: {
  type: string;
  payload: {
    email: string;
  };
}) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getUserLoading: true,
      }),
    );

    const currentUser: IUser = yield select(selectUser);
    const { data } = yield call(userService.endpoint_post_get_user, action.payload);

    yield put(userAction.setUser({ ...currentUser, ...data } as IUser));
  } catch (error: any) {
    console.error('watchGetUser: ', error);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        getUserLoading: false,
      }),
    );
  }
}

function* watchUpdateUser(action: { type: string; payload: { user: IUser; next: Function } }) {
  try {
    const { user, next } = action.payload;

    yield put(
      loadingAction.updateLoadingStatus({
        updateUserLoading: true,
      }),
    );

    const currentUser: IUser = yield select(selectUser);
    const { data } = yield call(userService.endpoint_put_user, user);

    yield put(userAction.setUser({ ...currentUser, ...data } as IUser));

    next();
  } catch (error: any) {
    console.error('watchUpdateUser: ', error);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        updateUserLoading: false,
      }),
    );
  }
}
