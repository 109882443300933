import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import { PageMainTabs, EnhancedTable, OtherOffersFilter, TitleSection } from '../../components';
import { otherOffersTableColumns, palette } from '../../configs';
import { START_YEAR } from '../../consts';
import { EUserRoles } from '../../enums';
import {
  selectOtherOffersSelectedCollege,
  selectOtherOffers,
  selectOtherOffersSelectedYear,
  selectUserRole,
  selectLoadingState,
} from '../../store/selectors';
import {
  convertOtherOffersToTableData,
  getEndYear,
  getOffersCsvData,
  getOffersCsvHeaders,
  offerscsvDataOtherProps,
} from '../../utils';

interface CompareOtherOffersPageProps {}

export const CompareOtherOffersPage: React.FC<CompareOtherOffersPageProps> = () => {
  const offers = useSelector(selectOtherOffers);
  const { getOffersLoading = false } = useSelector(selectLoadingState);
  const otherOffersSelectedCollege = useSelector(selectOtherOffersSelectedCollege);
  const otherOffersSelectedYear = useSelector(selectOtherOffersSelectedYear);
  const role = useSelector(selectUserRole);

  let columns = otherOffersTableColumns;

  if (otherOffersSelectedCollege) {
    columns = columns.filter((item) => item.id !== 'collegeNameWithState');
  }

  if (otherOffersSelectedYear !== -1) {
    columns = columns.filter((item) => item.id !== 'year');
  }

  const columnKeys = columns.map((item) => item.id);

  let filename = `CCO_Offers_${dayjs(new Date()).format('YYYY_MM_DD')}`;
  let collegeNameInfo = '';
  let yearInfo = '';
  let collegeInfo = '';

  if (otherOffersSelectedCollege && otherOffersSelectedCollege.collegeNameWithState) {
    collegeNameInfo = `${otherOffersSelectedCollege.collegeNameWithState || ''}`;
  }

  if (otherOffersSelectedYear !== -1) {
    yearInfo = `${otherOffersSelectedYear}-${otherOffersSelectedYear + 1}`;
  } else {
    yearInfo = `${START_YEAR}-${getEndYear()}`;
  }

  if (collegeNameInfo) {
    collegeInfo = ` (${collegeNameInfo}, ${yearInfo})`;
  } else {
    collegeInfo = ` (${yearInfo})`;
  }

  filename += `${collegeInfo}.csv`;

  const getCsvHeaders = getOffersCsvHeaders;
  const getCsvData = getOffersCsvData;
  const csvDataOtherProps = offerscsvDataOtherProps;

  return (
    <Box sx={{ width: '100%' }}>
      <PageMainTabs />

      <Box sx={{ py: 5 }}>
        <TitleSection color={palette.primary.dark} title={'Compare Other College Offers'} />

        <Box marginTop={4}>
          <OtherOffersFilter />
        </Box>

        <Box paddingY={4}>
          <EnhancedTable
            config={{
              toolbar: true,
              rowCheckable: false,
              rowActionVisible: false,
            }}
            loading={getOffersLoading}
            loadingLabel={'Loading Other Offers'}
            noDataLabel={'No Offer'}
            noDataSectionClassnames={'h-24'}
            toolbarTitle={
              otherOffersSelectedCollege
                ? `Crowdsourced Offers for ${otherOffersSelectedCollege?.name}`
                : 'All Crowdsourced Offers'
            }
            data={offers}
            convertFunctionForDataToTableData={convertOtherOffersToTableData}
            headColumns={columns}
            defaultSortColumnName="userEfcMin"
            defaultSortDirection="desc"
            columnKeys={columnKeys}
            rowActions={{}}
            styles={{
              tableStyle: {
                minWidth: 0,
              },
            }}
            initialRowsPerPage={10}
            visiblePagination={true}
            exportable={role === EUserRoles.ADMIN}
            exporting={false}
            filename={filename}
            getCsvHeaders={getCsvHeaders}
            getCsvData={getCsvData}
            csvDataOtherProps={csvDataOtherProps}
          />
        </Box>
      </Box>
    </Box>
  );
};
