import { produce } from 'immer';

import { createReducer } from '.';
import { loanTypesActionTypes } from '../../consts';
import { IAction, ILoanTypesState } from '../../models';
import { loanTypesState } from '../states/loan-types.state';

export const loanTypesReducer = createReducer<ILoanTypesState>(loanTypesState, {
  [loanTypesActionTypes.SET_LOAN_TYPES]: setLoanTypes,
});

function setLoanTypes(loanType: ILoanTypesState, { payload }: IAction<Array<any>>) {
  return produce(loanType, (draft) => {
    draft.loanTypes = payload;
  });
}
