import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Link, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import { PageMainTabs, EnhancedTable, MyLoanOffersFilter, TitleSection } from '../../components';
import { myLoanOffersTableColumns, palette } from '../../configs';
import { convertMyLoanOffersToTableData } from '../../utils';
import { dialogsAction, loanOffersAction } from '../../store/actions';
import { selectLoadingState, selectMyLoanOffersFiltered, selectMyOffersCount } from '../../store/selectors';

interface CompareMyLoansPageProps {}

export const CompareMyLoansPage: React.FC<CompareMyLoansPageProps> = () => {
  const dispatch = useDispatch();
  const offers = useSelector(selectMyLoanOffersFiltered);
  const myOffersCount = useSelector(selectMyOffersCount);
  const { loanOffersGettingFlag = false } = useSelector(selectLoadingState);

  const columns = myLoanOffersTableColumns;
  const columnKeys = columns.map((item) => item.id);

  const onEdit = (id: number) => {
    dispatch(loanOffersAction.setMyLoanOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        loanOfferUpdateDialog: true,
      }),
    );
  };

  const onDelete = (id: number) => {
    dispatch(loanOffersAction.setMyLoanOffersSelectedOfferId(id));

    dispatch(
      dialogsAction.updateDialogsStatus({
        loanOfferDeleteDialog: true,
      }),
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <PageMainTabs />

      <Box sx={{ py: 5 }}>
        <TitleSection
          color={palette.primary.dark}
          title={'My Loan Offers'}
          createButtonLabel="Add Loan"
          startIcon={<Add />}
          onCreateButton={() => {
            dispatch(
              dialogsAction.updateDialogsStatus({
                loanOfferCreateDialog: true,
              }),
            );
          }}
        />

        {myOffersCount === 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: 300,
            }}
          >
            <Typography>To add a loan, please add the college you are attending.</Typography>
            <Link
              onClick={(e) => {
                e.preventDefault();

                dispatch(
                  dialogsAction.updateDialogsStatus({
                    myOfferCreateDialog: true,
                  }),
                );
              }}
              sx={{
                cursor: 'pointer',
                marginTop: 1,
              }}
            >
              Add College
            </Link>
          </Box>
        )}

        {myOffersCount > 0 && (
          <Box marginTop={4}>
            <MyLoanOffersFilter />
          </Box>
        )}

        {myOffersCount > 0 && (
          <Box paddingY={4}>
            <EnhancedTable
              config={{
                toolbar: true,
                rowCheckable: false,
                rowActionVisible: true,
              }}
              loading={loanOffersGettingFlag}
              loadingLabel={'Loading Loan Offers'}
              noDataComponent={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 300,
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: 0.5,
                    }}
                  >
                    Create your loan
                  </Typography>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();

                      dispatch(
                        dialogsAction.updateDialogsStatus({
                          loanOfferCreateDialog: true,
                        }),
                      );
                    }}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    here
                  </Link>
                </Box>
              }
              toolbarTitle="Loan Offers"
              data={offers}
              convertFunctionForDataToTableData={convertMyLoanOffersToTableData}
              headColumns={columns}
              columnKeys={columnKeys}
              styles={{
                tableStyle: {
                  minWidth: 0,
                },
              }}
              initialRowsPerPage={10}
              visiblePagination={true}
              rowActions={{
                onEdit,
                onDelete,
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
