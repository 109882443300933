export const numberWithCommas = (x: number) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeUndefinedFields = (obj: any) => {
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (obj[key] === undefined) {
      delete obj[key];
    }
  }

  return obj;
};

export const isTodayData = (createdAt: Date) => {
  const createdAtYear = createdAt.getFullYear();
  const createdAtMonth = createdAt.getMonth();
  const createdAtDate = createdAt.getDate();

  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDate = today.getDate();

  return createdAtYear === todayYear && createdAtMonth === todayMonth && createdAtDate === todayDate;
};
