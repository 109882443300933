import { createSelector } from 'reselect';

import { IRootState, ILoanOffersState } from '../../models';
import { numberWithCommas } from '../../utils';
import { selectUserId } from './user.selector';

export const selectLoanOffersState = (rootState: IRootState): ILoanOffersState => rootState.loanOffersState;

export const selectLoanOffers = createSelector([selectLoanOffersState], (loanOffersState) =>
  loanOffersState.loanOffers.map((item) => ({
    ...item,
    collegeNameWithState: `${item.college?.name || ''} - ${item.college?.state?.code || ''}`,
    lenderName: item.lender?.name || '',
    interestRateLabel: `${item.interestRate}%`,
    loanTermLabel: item.loanTerm?.name || '',
    repaymentTypeLabel: item.repaymentType?.name || '',
    coSignorLabel: item.coSignor ? 'Yes' : 'No',
    govOrPrivateText: item.college?.isPrivate ? 'Private' : 'Public',
    creditScoreRangeText: `${item.creditScoreRange?.start || 0} - ${item.creditScoreRange?.end || 0} (${
      item.creditScoreRange?.status || ''
    })`,
    monthlyPaymentAmountLabel: `$${numberWithCommas(item.monthlyPaymentAmount || 0)}`,
    totalLoanAmountLabel: `$${numberWithCommas(item.totalLoanAmount || 0)}`,
    yearLabel: `${item.year} - ${item.year + 1}`,
  })),
);

export const selectMyLoanOffers = createSelector([selectLoanOffers, selectUserId], (loanOffers, userId) =>
  loanOffers.filter((item) => item.userId === userId),
);

export const selectMyLoanOffersCount = createSelector([selectMyLoanOffers], (loanOffers) => loanOffers.length);

export const selectMyLoanOffersSelectedCollegeId = createSelector(
  [selectLoanOffersState],
  (loanOffersState) => loanOffersState.myLoanOffersSelectedCollegeId,
);

export const selectMyLoanOffersSelectedYear = createSelector(
  [selectLoanOffersState],
  (loanOffersState) => loanOffersState.myLoanOffersSelectedYear,
);

export const selectOtherLoanOffersSelectedLenderId = createSelector(
  [selectLoanOffersState],
  (loanOffersState) => loanOffersState.otherLoanOffersSelectedLenderId,
);

export const selectOtherLoanOffersSelectedYear = createSelector(
  [selectLoanOffersState],
  (loanOffersState) => loanOffersState.otherLoanOffersSelectedYear,
);

export const selectMyLoanOffersFiltered = createSelector(
  [selectMyLoanOffers, selectMyLoanOffersSelectedCollegeId, selectMyLoanOffersSelectedYear],
  (loanOffers, collegeId, year) => {
    if (collegeId === -1 && year === -1) {
      return loanOffers;
    }

    if (collegeId === -1) {
      return loanOffers.filter((item) => item.year === year);
    }

    if (year === -1) {
      return loanOffers.filter((item) => item.collegeId === collegeId);
    }

    return loanOffers.filter((item) => item.collegeId === collegeId && item.year === year);
  },
);

export const selectLoanOffersFiltered = createSelector(
  [selectLoanOffers, selectOtherLoanOffersSelectedLenderId, selectOtherLoanOffersSelectedYear],
  (loanOffers, lenderId, year) => {
    if (lenderId === -1 && year === -1) {
      return loanOffers;
    }

    if (lenderId === -1) {
      return loanOffers.filter((item) => item.year === year);
    }

    if (year === -1) {
      return loanOffers.filter((item) => item.lenderId === lenderId);
    }

    return loanOffers.filter((item) => item.lenderId === lenderId && item.year === year);
  },
);

export const selectMyLoanOffersSelectedOfferId = createSelector(
  [selectLoanOffersState],
  (loanOffersState) => loanOffersState.myLoanOffersSelectedOfferId,
);

export const selectLoanOffersSelectedOffer = createSelector(
  [selectLoanOffers, selectMyLoanOffersSelectedOfferId],
  (loanOffers, loanOfferId) => loanOffers.find((loanOffer) => loanOffer.id === loanOfferId),
);
