/* eslint-disable */
import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { AuthNavigator, UnauthNavigator } from '.';
import { Loading } from '../components';
import { localStorageKey } from '../consts';
import { Dialogs } from '../dialogs';
import { NotificationsLayout } from '../layouts';

interface RootNavigatorProps {}

export const RootNavigator: React.FunctionComponent<RootNavigatorProps> = () => {
  const { isLoading, isAuthenticated, error, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [authError, setAuthError] = React.useState<boolean>(false);
  const [isTokenLoading, setIsTokenLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (window.location.hostname === 'compareoffers.road2college.com') {
      const script = document.createElement('script') as any;

      script.type = 'text/javascript';
      script.async = 'async';
      script['data-noptimize'] = '1';
      script['data-cfasync'] = 'false';
      script.src = '//scripts.mediavine.com/tags/road2college.js';

      document.head.appendChild(script);
    }
  }, []);

  React.useEffect(() => {
    setAuthError(!!error);
  }, [error]);

  React.useEffect(() => {
    try {
      if (isAuthenticated) {
        getAccessTokenSilently().then((token) => {
          localStorage.setItem(localStorageKey.auth_status, JSON.stringify({ token }));

          setIsTokenLoading(false);
        });
      } else if (!isLoading) {
        localStorage.removeItem(localStorageKey.auth_status);

        setIsTokenLoading(false);
      }
    } catch (error) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading || isTokenLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading text="Please wait while your credentials are verified." />
      </Box>
    );
  }

  if (authError) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5">Something went wrong</Typography>
        <Typography
          color="textSecondary"
          sx={{
            marginTop: 2,
          }}
        >
          Sorry, something went wrong there. Please try again later.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setAuthError(false);
          }}
          sx={{
            marginTop: 8,
          }}
        >
          Back to home
        </Button>
      </Box>
    );
  }

  return (
    <>
      {isAuthenticated ? <AuthNavigator /> : <UnauthNavigator />}
      <Dialogs />
      <NotificationsLayout />
    </>
  );
};
