export enum ETermOfLoan {
  FIXED = 'Fixed',
  VARIABLE = 'Variable',
}

export enum ERepaymentType {
  IMMEDIATE = 'Immediate',
  INTEREST_ONLY = 'Interest Only',
  PARTIAL_PAYMENT = 'Partial Payment',
  FULLY_DEFERRED = 'Fully Deferred',
}

export enum ELoanType {
  IN_SCHOOL = 'In School',
  REFINANCE = 'Refinance',
}

export enum ECreditScoreStatus {
  EXCELLENT = 'Excellent',
  VERY_GOOD = 'Very Good',
  GOOD = 'Good',
  FAIR = 'Fair',
  POOR = 'Poor',
  VERY_POOR = 'Very Poor',
}
