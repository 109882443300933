import { produce } from 'immer';

import { createReducer } from '.';
import { statesActionTypes } from '../../consts';
import { IAction, IStatesState } from '../../models';
import { statesState } from '../states/states.state';

export const statesReducer = createReducer<IStatesState>(statesState, {
  [statesActionTypes.SET_STATES]: setStates,
});

function setStates(state: IStatesState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.states = payload;
  });
}
