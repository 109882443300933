import { IRootState } from '../../models';
import { admissionTypesState } from './admission-types.state';
import { collegesState } from './colleges.state';
import { creditScoreRangesState } from './credit-score-ranges.state';
import { dialogsState } from './dialogs.state';
import { efcsState } from './efcs.state';
import { highschoolsState } from './highschools.state';
import { lendersState } from './lenders.state';
import { loadingState } from './loading.state';
import { loanOffersState } from './loan-offers.state';
import { loanTermsState } from './loan-terms.state';
import { loanTypesState } from './loan-types.state';
import { notificationState } from './notification.state';
import { offersState } from './offers.state';
import { repaymentTypesState } from './repayment-types.state';
import { statesState } from './states.state';
import { userState } from './user.state';
import { usersState } from './users.state';

const rootState: IRootState = {
  admissionTypesState,
  collegesState,
  creditScoreRangesState,
  dialogsState,
  efcsState,
  highschoolsState,
  lendersState,
  loadingState,
  loanOffersState,
  loanTermsState,
  loanTypesState,
  notificationState,
  offersState,
  repaymentTypesState,
  statesState,
  userState,
  usersState,
};

export default rootState;
