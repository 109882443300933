import { EOffersActionTypes } from '../../enums';
import { IOffer, IOfferAccepted, IOfferPublished } from '../../models';

const createOffer = (payload: IOffer) => {
  return {
    type: EOffersActionTypes.CREATE_OFFER,
    payload,
  };
};

const deleteOffer = () => {
  return {
    type: EOffersActionTypes.DELETE_OFFER,
  };
};

const getAllOffers = () => {
  return {
    type: EOffersActionTypes.GET_ALL_OFFERS,
  };
};

const getOffers = () => {
  return {
    type: EOffersActionTypes.GET_OFFERS,
  };
};

const setMyOffersSelectedOfferId = (payload: number) => {
  return {
    type: EOffersActionTypes.SET_MY_OFFERS_SELECTED_OFFER_ID,
    payload,
  };
};

const setLoanOffersSelectedOfferId = (payload: number) => {
  return {
    type: EOffersActionTypes.SET_LOAN_OFFERS_SELECTED_OFFER_ID,
    payload,
  };
};

const setOffer = (payload: IOffer) => {
  return {
    type: EOffersActionTypes.SET_OFFER,
    payload,
  };
};

const setOffers = (payload: Array<IOffer>) => {
  return {
    type: EOffersActionTypes.SET_OFFERS,
    payload,
  };
};

const setOtherOffersSelectedCollegeId = (payload: number) => {
  return {
    type: EOffersActionTypes.SET_OTHER_OFFERS_SELECTED_COLLEGE_ID,
    payload,
  };
};

const setOtherOffersSelectedYear = (payload: number) => {
  return {
    type: EOffersActionTypes.SET_OTHER_OFFERS_SELECTED_YEAR,
    payload,
  };
};

const setSelectedOfferId = (payload?: number) => {
  return {
    type: EOffersActionTypes.SET_SELECTED_OFFER_ID,
    payload,
  };
};

const updateOffer = (payload: { offer: IOffer; next: Function }) => {
  return {
    type: EOffersActionTypes.UPDATE_OFFER,
    payload,
  };
};

const getOffersAccepted = () => {
  return {
    type: EOffersActionTypes.GET_OFFERS_ACCEPTED,
  };
};

const setOffersAccepted = (payload: Array<IOfferAccepted>) => {
  return {
    type: EOffersActionTypes.SET_OFFERS_ACCEPTED,
    payload,
  };
};

const updateOfferAccepted = () => {
  return {
    type: EOffersActionTypes.UPDATE_OFFER_ACCEPTED,
  };
};

const getPublicOffers = () => {
  return {
    type: EOffersActionTypes.GET_PUBLIC_OFFERS,
  };
};

const setPublicOffers = (payload: Array<IOffer>) => {
  return {
    type: EOffersActionTypes.SET_PUBLIC_OFFERS,
    payload,
  };
};

const setOffersSelectedOfferId = (payload: number) => {
  return {
    type: EOffersActionTypes.SET_OFFERS_SELECTED_OFFER_ID,
    payload,
  };
};

const getOffersPublished = () => {
  return {
    type: EOffersActionTypes.GET_OFFERS_PUBLISHED,
  };
};

const setOffersPublished = (payload: Array<IOfferPublished>) => {
  return {
    type: EOffersActionTypes.SET_OFFERS_PUBLISHED,
    payload,
  };
};

const updateOfferPublished = () => {
  return {
    type: EOffersActionTypes.UPDATE_OFFER_PUBLISHED,
  };
};

export const offersAction = {
  createOffer,
  deleteOffer,
  getAllOffers,
  getOffers,
  setMyOffersSelectedOfferId,
  setLoanOffersSelectedOfferId,
  setOffer,
  setOffers,
  setOtherOffersSelectedCollegeId,
  setOtherOffersSelectedYear,
  setSelectedOfferId,
  updateOffer,
  getOffersAccepted,
  setOffersAccepted,
  updateOfferAccepted,
  getPublicOffers,
  setPublicOffers,
  setOffersSelectedOfferId,
  getOffersPublished,
  setOffersPublished,
  updateOfferPublished,
};
