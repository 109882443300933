import { EUserActionTypes } from '../../enums';
import { IUser } from '../../models';

const getUser = (payload: { email: string }) => {
  return {
    type: EUserActionTypes.GET_USER,
    payload,
  };
};

const setUser = (payload: IUser) => {
  return {
    type: EUserActionTypes.SET_USER,
    payload,
  };
};

const updateUser = (payload: { user: IUser; next: Function }) => {
  return {
    type: EUserActionTypes.UPDATE_USER,
    payload,
  };
};

export const userAction = {
  getUser,
  setUser,
  updateUser,
};
