import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ECreditScoreRangesActionTypes } from '../../enums';
import { IIdAndName } from '../../models';
import { creditScoreRangesService } from '../../services';
import { loadingAction, creditScoreRangesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ECreditScoreRangesActionTypes.GET_CREDIT_SCORE_RANGES as any, watchGetCreditScoreRanges)]);
}

function* watchGetCreditScoreRanges() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        creditScoreRangesGettingFlag: true,
      }),
    );

    const { data } = yield call(creditScoreRangesService.endpoint_get_credit_score_ranges);

    yield put(creditScoreRangesAction.setCreditScoreRanges(data as Array<IIdAndName>));
  } catch (error: any) {
    console.error('watchGetCreditScoreRanges: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        creditScoreRangesGettingFlag: false,
      }),
    );
  }
}
