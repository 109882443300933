import { createSelector } from 'reselect';

import { IHighschoolsState, IRootState } from '../../models';

export const selectHighschoolsState = (rootState: IRootState): IHighschoolsState => rootState.highschoolsState;

export const selectHighschools = createSelector(
  [selectHighschoolsState],
  (highschoolsState) => highschoolsState.highschools,
);
