import { produce } from 'immer';

import { createReducer } from '.';
import { usersActionTypes } from '../../consts';
import { IAction, IUser, IUsersState } from '../../models';
import { usersState } from '../states/users.state';

export const usersReducer = createReducer<IUsersState>(usersState, {
  [usersActionTypes.SET_USERS]: setUsers,
  [usersActionTypes.SET_SELECTED_USER_ID]: setSelectedUserId,
  [usersActionTypes.SET_USER]: setUser,
});

function setUsers(state: IUsersState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.users = payload;
  });
}

function setSelectedUserId(state: IUsersState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.selectedUserId = payload;
  });
}

function setUser(state: IUsersState, { payload }: IAction<IUser>) {
  return produce(state, (draft) => {
    if (draft.users && draft.users.length > 0) {
      const index = draft.users?.findIndex((val) => val.id === payload.id);

      draft.users[index] = { ...payload };
    }
  });
}
