import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { Auth0Provider } from '@auth0/auth0-react';

import './assets/styles/index.scss';
import { theme } from './configs';
import { RootNavigator } from './navigators';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <Provider store={store}>
        <Auth0Provider
          domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
          clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
          audience={`${process.env.REACT_APP_AUTH0_AUDIENCE}`}
          redirectUri={window.location.origin}
        >
          <BrowserRouter>
            <CssBaseline />
            <RootNavigator />
          </BrowserRouter>
        </Auth0Provider>
      </Provider>
    </StylesProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

reportWebVitals();
