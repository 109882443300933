export enum EAdmissionTypesActionTypes {
  GET_ADMISSION_TYPES = 'GET_ADMISSION_TYPES',
  SET_ADMISSION_TYPES = 'SET_ADMISSION_TYPES',
}

export enum EBasicActionTypes {
  INIT_STORE = 'INIT_STORE',
}

export enum ECollegesActionTypes {
  GET_COLLEGES = 'GET_COLLEGES',
  SET_COLLEGES = 'SET_COLLEGES',
  SET_COLLEGE = 'SET_COLLEGE',
  SET_SELECTED_COLLEGE_ID = 'SET_SELECTED_COLLEGE_ID',
  UPDATE_COLLEGE = 'UPDATE_COLLEGE',
}

export enum ECreditScoreRangesActionTypes {
  GET_CREDIT_SCORE_RANGES = 'GET_CREDIT_SCORE_RANGES',
  SET_CREDIT_SCORE_RANGES = 'SET_CREDIT_SCORE_RANGES',
}

export enum EDialogsActionTypes {
  UPDATE_DIALOGS_STATUS = 'UPDATE_DIALOGS_STATUS',
}

export enum EEfcsActionTypes {
  GET_EFCS = 'GET_EFCS',
  SET_EFCS = 'SET_EFCS',
}

export enum EHighschoolsActionTypes {
  GET_HIGHSCHOOLS = 'GET_HIGHSCHOOLS',
  SET_HIGHSCHOOLS = 'SET_HIGHSCHOOLS',
}

export enum ELendersActionTypes {
  GET_LENDERS = 'GET_LENDERS',
  SET_LENDERS = 'SET_LENDERS',
}

export enum ELoadingActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
}

export enum ELoanOffersActionTypes {
  GET_LOAN_OFFERS = 'GET_LOAN_OFFERS',
  SET_LOAN_OFFERS = 'SET_LOAN_OFFERS',
  CREATE_LOAN_OFFER = 'CREATE_LOAN_OFFER',
  UPDATE_LOAN_OFFER = 'UPDATE_LOAN_OFFER',
  DELETE_LOAN_OFFER = 'DELETE_LOAN_OFFER',
  SET_MY_LOAN_OFFERS_SELECTED_COLLEGE_ID = 'SET_MY_LOAN_OFFERS_SELECTED_COLLEGE_ID',
  SET_MY_LOAN_OFFERS_SELECTED_YEAR = 'SET_MY_LOAN_OFFERS_SELECTED_YEAR',
  SET_MY_LOAN_OFFERS_SELECTED_OFFER_ID = 'SET_MY_LOAN_OFFERS_SELECTED_OFFER_ID',
  SET_OTHER_LOAN_OFFERS_SELECTED_LENDER_ID = 'SET_OTHER_LOAN_OFFERS_SELECTED_LENDER_ID',
  SET_OTHER_LOAN_OFFERS_SELECTED_YEAR = 'SET_OTHER_LOAN_OFFERS_SELECTED_YEAR',
}

export enum ELoanTermsActionTypes {
  GET_LOAN_TERMS = 'GET_LOAN_TERMS',
  SET_LOAN_TERMS = 'SET_LOAN_TERMS',
}

export enum ELoanTypesActionTypes {
  GET_LOAN_TYPES = 'GET_LOAN_TYPES',
  SET_LOAN_TYPES = 'SET_LOAN_TYPES',
}

export enum ENotificationActionTypes {
  UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS',
}

export enum EOffersActionTypes {
  GET_ALL_OFFERS = 'GET_ALL_OFFERS',
  GET_OFFERS = 'GET_OFFERS',
  SET_OFFERS = 'SET_OFFERS',
  SET_OFFER = 'SET_OFFER',
  SET_SELECTED_OFFER_ID = 'SET_SELECTED_OFFER_ID',
  CREATE_OFFER = 'CREATE_OFFER',
  UPDATE_OFFER = 'UPDATE_OFFER',
  DELETE_OFFER = 'DELETE_OFFER',
  SET_OTHER_OFFERS_SELECTED_COLLEGE_ID = 'SET_OTHER_OFFERS_SELECTED_COLLEGE_ID',
  SET_OTHER_OFFERS_SELECTED_YEAR = 'SET_OTHER_OFFERS_SELECTED_YEAR',
  SET_MY_OFFERS_SELECTED_OFFER_ID = 'SET_MY_OFFERS_SELECTED_OFFER_ID',
  SET_LOAN_OFFERS_SELECTED_OFFER_ID = 'SET_LOAN_OFFERS_SELECTED_OFFER_ID',
  GET_OFFERS_ACCEPTED = 'GET_OFFERS_ACCEPTED',
  SET_OFFERS_ACCEPTED = 'SET_OFFERS_ACCEPTED',
  UPDATE_OFFER_ACCEPTED = 'UPDATE_OFFER_ACCEPTED',
  GET_PUBLIC_OFFERS = 'GET_PUBLIC_OFFERS',
  SET_PUBLIC_OFFERS = 'SET_PUBLIC_OFFERS',
  SET_OFFERS_SELECTED_OFFER_ID = 'SET_OFFERS_SELECTED_OFFER_ID',
  GET_OFFERS_PUBLISHED = 'GET_OFFERS_PUBLISHED',
  SET_OFFERS_PUBLISHED = 'SET_OFFERS_PUBLISHED',
  UPDATE_OFFER_PUBLISHED = 'UPDATE_OFFER_PUBLISHED',
  GET_LOAN_OFFERS = 'GET_LOAN_OFFERS',
  SET_LOAN_OFFERS = 'SET_LOAN_OFFERS',
  CREATE_LOAN_OFFER = 'CREATE_LOAN_OFFER',
  UPDATE_LOAN_OFFER = 'UPDATE_LOAN_OFFER',
  DELETE_LOAN_OFFER = 'DELETE_LOAN_OFFER',
}

export enum ERepaymentTypesActionTypes {
  GET_REPAYMENT_TYPES = 'GET_REPAYMENT_TYPES',
  SET_REPAYMENT_TYPES = 'SET_REPAYMENT_TYPES',
}

export enum EStatesActionTypes {
  GET_STATES = 'GET_STATES',
  SET_STATES = 'SET_STATES',
}

export enum EUserActionTypes {
  GET_USER = 'GET_USER',
  UPDATE_USER = '[USER] UPDATE_USER',
  SET_USER = '[USER] SET_USER',
}

export enum EUsersActionTypes {
  GET_USERS = 'GET_USERS',
  SET_USERS = 'SET_USERS',
  SET_SELECTED_USER_ID = 'SET_SELECTED_USER_ID',
  UPDATE_USER = '[USERS] UPDATE_USER',
  SET_USER = '[USERS] SET_USER',
}
