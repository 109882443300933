import { EAdmissionTypesActionTypes } from '../../enums';

const getAdmissionTypes = () => {
  return {
    type: EAdmissionTypesActionTypes.GET_ADMISSION_TYPES,
  };
};

const setAdmissionTypes = (payload: Array<any>) => {
  return {
    type: EAdmissionTypesActionTypes.SET_ADMISSION_TYPES,
    payload,
  };
};

export const admissionTypesAction = {
  getAdmissionTypes,
  setAdmissionTypes,
};
