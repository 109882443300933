import { ECreditScoreStatus } from '../enums';
import { ICreditScoreRange } from '../models';

export const getCreditScoreRangeColor = (range?: ICreditScoreRange) => {
  if (!range) {
    return '#ffffff';
  }

  if (range.status === ECreditScoreStatus.EXCELLENT) {
    return '#238236';
  }

  if (range.status === ECreditScoreStatus.VERY_GOOD) {
    return '#6CB134';
  }

  if (range.status === ECreditScoreStatus.GOOD) {
    return '#F8C30E';
  }

  if (range.status === ECreditScoreStatus.FAIR) {
    return '#F27A19';
  }

  if (range.status === ECreditScoreStatus.POOR) {
    return '#E82F1C';
  }

  if (range.status === ECreditScoreStatus.VERY_POOR) {
    return '#AB0D1D';
  }

  return '#ffffff';
};
