import { styled } from '@mui/material/styles';

import { AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH } from '../configs';

interface AuthAdminMainLayoutProps {
  open: boolean;
}

export const AuthAdminMainLayout = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<AuthAdminMainLayoutProps>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${AUTH_LAYOUT_LEFT_DRAWER_OPENED_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  backgroundColor: theme.palette.grey[200],
  minHeight: '100vh',
}));
