import { produce } from 'immer';

import { createReducer } from '.';
import { highschoolsActionTypes } from '../../consts';
import { IAction, IHighschoolsState } from '../../models';
import { highschoolsState } from '../states/highschools.state';

export const highschoolsReducer = createReducer<IHighschoolsState>(highschoolsState, {
  [highschoolsActionTypes.SET_HIGHSCHOOLS]: setHighschools,
});

function setHighschools(state: IHighschoolsState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.highschools = payload;
  });
}
