/* eslint-disable */
import facebook from '../assets/images/facebook-16.png';
import x from '../assets/images/X-Logo.png';
import instagram from '../assets/images/insta.png';
import youtube from '../assets/images/youtube-16.png';
import footerLogo from '../assets/images/road2college_footer_logo.png';
import './footer.css';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const FooterLayout = () => {
  const { pathname } = useLocation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <>
      <footer className="footer">
        <div className="top-footer-block-landing">
          <div className="container-other-block-landing">
            <div className="first-footer-block-landing">
              <div className="footer-block-title ">
                <img
                  onClick={() => window.open('https://www.road2college.com/')}
                  src={footerLogo}
                  alt="road to college icon"
                />
                <div className="footer-information-container">
                  <p className="footer-description-mobile">
                    Make smarter college finance descisions. Become an educated customer of higher ed
                  </p>
                  <p className="footer-rights-mobile">
                    @2021 Road2College. All Rights Reserved. Exclusive Member of Mediavine Financial.
                  </p>
                </div>
                {!isAuthenticated && (
                  <>
                    <button
                      onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                      type="button"
                      className="become-a-member-button"
                    >
                      Become a Member
                    </button>
                    <button onClick={loginWithRedirect} type="button" className="log-in-button-landing">
                      Log in
                    </button>
                  </>
                )}
              </div>
              <div className="second-title-block">
                <div className="soc-net-icons">
                  <a target="_blank" href="https://www.facebook.com/Road2College/" rel="noreferrer">
                    <img className="soc-network" src={facebook} alt="facebook" />
                  </a>
                  <a target="_blank" href="https://twitter.com/rd2college?lang=en" rel="noreferrer">
                    <img className="soc-network" src={x} alt="x" />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/_road2college_/" rel="noreferrer">
                    <img className="soc-network" src={instagram} alt="instagram" />
                  </a>
                  <a target="_blank" href="https://www.youtube.com/channel/UCtooo7EcnUA16Z2pbWkUVXw" rel="noreferrer">
                    <img className="soc-network" src={youtube} alt="youtube" />
                  </a>
                </div>

                <h6 className="destination-date">@{new Date().getFullYear()} DESTINATION U.INC</h6>
              </div>
            </div>

            <div className="second-footer-block-landing">
              <h4 className="uppercase" onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>
                Get Started
              </h4>
              <p className="sub-title disable-hover">By Grade:</p>
              <p>
                <a target="_blank" href="https://www.road2college.com/grades/9th-grade/" rel="noreferrer">
                  <span>9th Grade</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/grades/10th-grade/" rel="noreferrer">
                  <span>10th Grade</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/grades/11th-grade/" rel="noreferrer">
                  <span>11th Grade</span>
                </a>
              </p>
              <p className="end-list">
                <a target="_blank" href="https://www.road2college.com/grades/12th-grade/" rel="noreferrer">
                  <span>12th Grade</span>
                </a>
              </p>

              <p className="sub-title disable-hover">By Stage in the Process:</p>

              <p>
                <a target="_blank" href="https://www.road2college.com/stages/plan/" rel="noreferrer">
                  <strong className="strong-class">Plan </strong>
                  <span>Academic & Financial</span>
                </a>
              </p>

              <p>
                <a target="_blank" href="https://www.road2college.com/stages/research/" rel="noreferrer">
                  <strong className="strong-class">Research </strong>
                  <span>Colleges</span>
                </a>
              </p>

              <p>
                <a target="_blank" href="https://www.road2college.com/stages/apply/" rel="noreferrer">
                  <strong className="strong-class">Apply </strong>
                  <span>to Colleges</span>
                </a>
              </p>

              <p>
                <a target="_blank" href="https://www.road2college.com/stages/compare/" rel="noreferrer">
                  <strong className="strong-class">Compare </strong>
                  <span>College Offers</span>
                </a>
              </p>

              <p>
                <a target="_blank" href="https://www.road2college.com/stages/decide/" rel="noreferrer">
                  <strong className="strong-class">Decide </strong>
                  <span>Where to Attend</span>
                </a>
              </p>

              <p>
                <a target="_blank" href="https://www.road2college.com/stages/pay/" rel="noreferrer">
                  <strong className="strong-class">Pay </strong>
                  <span>for College</span>
                </a>
              </p>

              <p>
                <a target="_blank" href="https://www.road2college.com/stages/go/" rel="noreferrer">
                  <strong className="strong-class">Go </strong>
                  <span>to College!</span>
                </a>
              </p>
            </div>

            <div className="third-footer-block-landing">
              <h4 className="disable-hover">INFORMATION HUB</h4>
              <p className="sub-title disable-hover">By Topic:</p>

              <p>
                <a target="_blank" href="https://www.road2college.com/category/applications/" rel="noreferrer">
                  <span>Applications</span>
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  href="https://www.road2college.com/category/college-financial-planning/"
                  rel="noreferrer"
                >
                  <span>College Financial Planning</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/college-life/" rel="noreferrer">
                  <span>College Life</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/college-list-building/" rel="noreferrer">
                  <span>College List Building</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/essays/" rel="noreferrer">
                  <span>Essays</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/financial-aid/" rel="noreferrer">
                  <span>Financial Aid</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/financial-aid-appeals/" rel="noreferrer">
                  <span>Financial Aid Appeals</span>
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  href="https://www.road2college.com/category/high-school-academics-activities/"
                  rel="noreferrer"
                >
                  <span>High School Academics & Activities</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/merit-scholarships/" rel="noreferrer">
                  <span>Merit Scholarships</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/paying-off-loans/" rel="noreferrer">
                  <span>Paying off Loans</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/standardized-tests/" rel="noreferrer">
                  <span>Standardized Tests</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/category/student-parent-loans/" rel="noreferrer">
                  <span>Student & Parent Loans</span>
                </a>
              </p>
              <p>
                <a target="_blank" href="https://www.road2college.com/topics/" rel="noreferrer">
                  <span>All Topics</span>
                </a>
              </p>
            </div>

            <div className="fourth-footer-block-landing">
              <div>
                <h4>
                  <a target="_blank" href="https://www.road2college.com/events/" rel="noreferrer">
                    EVENTS
                  </a>
                </h4>
                <h4>
                  <a target="_blank" href="https://www.road2college.com/membership-plans/" rel="noreferrer">
                    MEMBERSHIP
                  </a>
                </h4>
                <h4>
                  <a target="_blank" href="https://www.road2college.com/shop/" rel="noreferrer">
                    TOOLS & SERVICES
                  </a>
                </h4>

                <p>
                  <a target="_blank" href="https://collegeinsights.road2college.com/" rel="noreferrer">
                    <span>R2C Insights</span>
                  </a>
                </p>

                <p>
                  <a target="_blank" href="https://compareoffers.road2college.com/" rel="noreferrer">
                    <span>Compare College Offers</span>
                  </a>
                </p>

                <p className="end-list">
                  <a target="_blank" href="https://www.road2college.com/shop" rel="noreferrer">
                    <span>1-1 Services</span>
                  </a>
                </p>
              </div>

              <div>
                <h4>
                  <a target="_blank" href="https://www.road2college.com/about-us/" rel="noreferrer">
                    ABOUT
                  </a>
                </h4>

                <p>
                  <a target="_blank" href="https://www.road2college.com/our-team/" rel="noreferrer">
                    <span>Our Team</span>
                  </a>
                </p>
                <p className="end-list">
                  <a target="_blank" href="https://www.road2college.com/coming-soon/" rel="noreferrer">
                    <span>Partnership Opportunities</span>
                  </a>
                </p>
                <h4>
                  <a target="_blank" href="https://www.road2college.com/contact-us/" rel="noreferrer">
                    CONTACT
                  </a>
                </h4>
                <h4>POLICIES</h4>
                <p>
                  <a target="_blank" href="https://www.road2college.com/privacy-policy/" rel="noreferrer">
                    <span>Privacy Policy</span>
                  </a>
                </p>
                <p>
                  <a target="_blank" href="https://www.road2college.com/terms-and-conditions/" rel="noreferrer">
                    <span>Terms & Conditions</span>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="container-other-blocks-mobile">
            <div className="flex-custom-center">
              <img src={footerLogo} alt="road to college icon" />
            </div>

            {!isAuthenticated && (
              <div>
                <div className="flex-custom-center">
                  <button
                    onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                    type="button"
                    className="become-a-member-button"
                  >
                    Become a Member
                  </button>
                </div>
                <div className="flex-custom-center">
                  <button onClick={loginWithRedirect} type="button" className="log-in-button-landing">
                    Login
                  </button>
                </div>
              </div>
            )}

            <div className="footer-mobile-menu">
              <h4>
                <a target="_blank" href="https://www.road2college.com/topics/" rel="noreferrer">
                  ALL TOPICS
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/events/" rel="noreferrer">
                  EVENTS
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/membership-plans/" rel="noreferrer">
                  MEMBERSHIP
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/membership-plans/" rel="noreferrer">
                  INSIGHTS
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/membership-plans/" rel="noreferrer">
                  COMPARE OFFERS
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/shop/" rel="noreferrer">
                  1-1 SERVICES
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/our-team/" rel="noreferrer">
                  OUR TEAM
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/contact-us/" rel="noreferrer">
                  CONTACT
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/privacy-policy/" rel="noreferrer">
                  PRIVACY POLICY
                </a>
              </h4>

              <h4>
                <a target="_blank" href="https://www.road2college.com/terms-and-conditions/" rel="noreferrer">
                  TERMS & CONDITIONS
                </a>
              </h4>

              <div className="soc-net-icons">
                <a target="_blank" href="https://www.facebook.com/Road2College/" rel="noreferrer">
                  <img className="soc-network" src={facebook} alt="facebook" />
                </a>
                <a target="_blank" href="https://twitter.com/rd2college?lang=en" rel="noreferrer">
                  <img className="soc-network" src={x} alt="twitter" />
                </a>
                <a target="_blank" href="https://www.instagram.com/_road2college_/" rel="noreferrer">
                  <img className="soc-network" src={instagram} alt="instagram" />
                </a>
                <a target="_blank" href="https://www.youtube.com/channel/UCtooo7EcnUA16Z2pbWkUVXw" rel="noreferrer">
                  <img className="soc-network" src={youtube} alt="youtube" />
                </a>
              </div>

              <h6 className="destination-date">@{new Date().getFullYear()} DESTINATION U.INC</h6>
            </div>
          </div>
        </div>

        <div className="policy-disclaimer-container-main">
          <div className="policy-disclaimer-container">
            <p className="policy-disclaimer">
              <strong className="italic">Ad Policy Disclaimer:</strong> Product name, logo, brands, and other trademarks
              featured or referred to within Road2College are the property of their respective trademark holders.
              Information obtained via Road2College™ is for educational purposes only. Please consult a licensed
              financial professional before making any financial decisions. This site may be compensated through third
              party advertisers. This site is not endorsed or affiliated with the U.S. Department of Education. By
              visiting Road2College’s site, you accept and agree to be bound by Road2College’s Terms of Use.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
