import { createSelector } from 'reselect';

import { ICollegesState, IRootState } from '../../models';
import { getCollegeNameWithState, getComparator, stableSort } from '../../utils';

export const selectCollegesState = (rootState: IRootState): ICollegesState => rootState.collegesState;

export const selectBasicColleges = createSelector([selectCollegesState], (collegesState) => collegesState.colleges);

export const selectColleges = createSelector([selectBasicColleges], (colleges) =>
  stableSort(
    colleges.map((item) => ({
      ...item,
      stateCode: item.state?.code || '',
      collegeNameWithState: getCollegeNameWithState(item.name || '', item.state?.code || ''),
      coaPrivate: item.coaPrivate || 0,
      coaInState: item.coaInState || 0,
      coaOutState: item.coaOutState || 0,
    })),
    getComparator('asc', 'collegeNameWithState'),
  ),
);

export const selectSelectedCollegeId = createSelector(
  [selectCollegesState],
  (collegesState) => collegesState.selectedCollegeId,
);

export const selectSelectedCollege = createSelector([selectColleges, selectSelectedCollegeId], (colleges, collegeId) =>
  colleges.find((item) => item.id === collegeId),
);
