import * as React from 'react';
import { useWindowSize } from 'react-use';
import { Box, Button, Typography, useTheme } from '@mui/material';

export interface TitleSectionProps {
  title: string;
  subtitle?: string | React.ReactNode;
  createButtonLabel?: string;
  onCreateButton?: () => void;
  startIcon?: React.ReactNode;
  color?: string;
}

export const TitleSection: React.FC<TitleSectionProps> = (props) => {
  const { title, subtitle, createButtonLabel, onCreateButton, startIcon, color } = props;
  const theme = useTheme();
  const windowSize = useWindowSize();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: { xs: 'center', sm: 'flex-start' },
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box
        style={{
          fontWeight: 600,
        }}
      >
        <Typography
          variant={windowSize.width < theme.breakpoints.values.md ? 'h5' : 'h4'}
          sx={{
            textAlign: { xs: 'center', sm: 'left' },
          }}
          style={{
            color,
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>

        {!!subtitle && subtitle}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: { xs: 0, sm: 'auto' },
          marginTop: { xs: 2, sm: 0 },
        }}
      >
        <Button
          color="secondary"
          size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
          onClick={() => {
            window.open('https://www.road2college.com/best-private-student-loans/');
          }}
          style={{
            fontWeight: 700,
            borderRadius: '20px',
          }}
          sx={{
            paddingX: 2,
          }}
        >
          View Loan Options
        </Button>

        {createButtonLabel && (
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 600,
              borderRadius: '20px',
            }}
            startIcon={startIcon}
            size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            onClick={() => {
              if (onCreateButton) {
                onCreateButton();
              }
            }}
            sx={{
              minWidth: windowSize.width < theme.breakpoints.values.sm ? 120 : 140,
              color: 'white',
              marginLeft: 2,
            }}
          >
            {createButtonLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
