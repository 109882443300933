import { ECreditScoreRangesActionTypes } from '../../enums';

const getCreditScoreRanges = () => {
  return {
    type: ECreditScoreRangesActionTypes.GET_CREDIT_SCORE_RANGES,
  };
};

const setCreditScoreRanges = (payload: Array<any>) => {
  return {
    type: ECreditScoreRangesActionTypes.SET_CREDIT_SCORE_RANGES,
    payload,
  };
};

export const creditScoreRangesAction = {
  getCreditScoreRanges,
  setCreditScoreRanges,
};
