import * as React from 'react';
import { useWindowSize } from 'react-use';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';

interface AuthSignupFormProps {}

export const AuthSignupForm: React.FC<AuthSignupFormProps> = () => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const { loginWithRedirect } = useAuth0();

  const formManager = useFormik({
    initialValues: {
      email: '',
      userRole: 'parent',
      termsOfService: false,
      newsLetters: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required').email('Invalid email address'),
      termsOfService: Yup.boolean().oneOf([true], 'You must accept the Terms of Service'),
    }),
    onSubmit: (values) => {
      loginWithRedirect({
        login_hint: values.email,
        screen_hint: 'signup',
      });
    },
  });

  return (
    <>
      <form onSubmit={formManager.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ fontFamily: 'Montserrat', color: theme.palette.primary.dark, fontWeight: 600 }}
            >
              Email ID
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              InputProps={{
                style: { fontFamily: 'Nunito Sans', borderRadius: '10px', borderWidth: '2px' },
              }}
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
              onChange={formManager.handleChange('email')}
              error={!!(formManager.errors.email && formManager.touched.email)}
              helperText={formManager.touched.email ? formManager.errors.email : null}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formManager.values.termsOfService}
                    onChange={(event, value) => {
                      formManager.setFieldValue('termsOfService', value);
                    }}
                  />
                }
                label={
                  <Typography
                    variant={
                      windowSize.width < theme.breakpoints.values.sm ||
                      (windowSize.width > theme.breakpoints.values.lg && windowSize.width < theme.breakpoints.values.xl)
                        ? 'body2'
                        : 'body1'
                    }
                    sx={{
                      lineHeight: 1.3,
                    }}
                  >
                    I’ve read & understand the Road2College{' '}
                    <Link
                      style={{ color: theme.palette.primary.dark, textDecorationColor: theme.palette.primary.dark }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open('https://www.road2college.com/terms-and-conditions/');
                      }}
                    >
                      Terms of Service
                    </Link>{' '}
                    *
                  </Typography>
                }
              />
              {!!(formManager.errors.termsOfService && formManager.touched.termsOfService) && (
                <FormHelperText
                  error
                  sx={{
                    marginLeft: 4,
                    marginTop: -1,
                  }}
                >
                  {formManager.touched.termsOfService ? formManager.errors.termsOfService : null}
                </FormHelperText>
              )}

              <FormControlLabel
                control={<Checkbox />}
                label={
                  <Typography
                    variant={
                      windowSize.width < theme.breakpoints.values.sm ||
                      (windowSize.width > theme.breakpoints.values.lg && windowSize.width < theme.breakpoints.values.xl)
                        ? 'body2'
                        : 'body1'
                    }
                    sx={{
                      lineHeight: 1.3,
                    }}
                  >
                    Sign me up for Road2College informational newsletters
                  </Typography>
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size={windowSize.width < theme.breakpoints.values.sm ? 'medium' : 'large'}
              fullWidth
              style={{
                color: 'white',
                borderRadius: '40px',
                height: '50px',
                width: '70%',
                alignSelf: 'center',
                fontFamily: 'Montserrat',
                fontWeight: '600',
              }}
            >
              Sign up
            </Button>
          </Grid>
        </Grid>
      </form>

      <Box
        sx={{
          marginTop: 3,
        }}
      >
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          style={{ fontWeight: 600, color: theme.palette.primary.contrastText }}
        >
          Already have an account?{' '}
          <Link
            component="button"
            variant="subtitle1"
            style={{
              fontWeight: 500,
              color: theme.palette.primary.dark,
              textDecorationColor: theme.palette.primary.dark,
            }}
            onClick={() => loginWithRedirect()}
          >
            Log In
          </Link>
        </Typography>
      </Box>
    </>
  );
};
