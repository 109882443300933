import { numberWithCommas } from '.';
import { ICollege } from '../models';

export const convertCollegesToTableData = (colleges: Array<ICollege>) => {
  return colleges.map((college) => convertCollegeToTableData(college));
};

export const convertCollegeToTableData = (college: ICollege) => {
  return {
    id: college.id,
    collegeNameWithState: college.collegeNameWithState,
    offers: college.offers,
    coaPrivate: college.coaPrivate ? `$${numberWithCommas(college.coaPrivate)}` : '',
    coaInState: college.coaInState ? `$${numberWithCommas(college.coaInState)}` : '',
    coaOutState: college.coaOutState ? `$${numberWithCommas(college.coaOutState)}` : '',
    action: ['edit'],
  };
};

export const emptyCollege = () => {
  return {
    id: -1,
    name: '',
    stateId: null,
    ipeds: null,
    isPrivate: false,
    coaInState: null,
    coaOutState: null,
    coaPrivate: null,
    createdAt: null,
    updatedAt: null,
  } as ICollege;
};

export const getCollegeNameWithState = (name: string, code: string) => `${name}${name && code ? ' - ' : ''}${code}`;
