import { EStatesActionTypes } from '../../enums';

const getStates = () => {
  return {
    type: EStatesActionTypes.GET_STATES,
  };
};

const setStates = (payload: Array<any>) => {
  return {
    type: EStatesActionTypes.SET_STATES,
    payload,
  };
};

export const statesAction = {
  getStates,
  setStates,
};
