import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { MyOfferForm } from '../forms';
import { dialogsAction } from '../store/actions';
import { selectDialogsState, selectLoadingState, selectMyOffersSelectedOffer } from '../store/selectors';

interface MyOfferUpdateDialogProps {}

export const MyOfferUpdateDialog: React.FC<MyOfferUpdateDialogProps> = () => {
  const dispatch = useDispatch();
  const offer = useSelector(selectMyOffersSelectedOffer);
  const { myOfferUpdateDialog = false } = useSelector(selectDialogsState);
  const { myOfferUpdateLoading = false } = useSelector(selectLoadingState);
  const formRef = React.useRef<any>();
  const theme = useTheme();
  const windowSize = useWindowSize();
  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      dispatch(
        dialogsAction.updateDialogsStatus({
          myOfferUpdateDialog: false,
        }),
      );
    }
  };
  const onSubmit = () => {
    formRef.current.onSubmit();
  };
  return (
    <Dialog fullWidth maxWidth={'md'} scroll={'paper'} onClose={onClose} open={myOfferUpdateDialog}>
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          paddingY: 1.5,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Edit Offer</Typography>
          <IconButton
            sx={{
              color: 'white',
            }}
            onClick={() => onClose(null, 'anyway')}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          overflowX: 'hidden',
          paddingX: windowSize.width < theme.breakpoints.values.sm ? 2 : 3,
        }}
      >
        <Box paddingTop={2}>
          <MyOfferForm
            ref={formRef}
            offer={offer}
            next={() =>
              dispatch(
                dialogsAction.updateDialogsStatus({
                  myOfferUpdateDialog: false,
                }),
              )
            }
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          bgcolor: 'grey.A200',
          paddingY: 1.5,
          paddingX: 3,
          justifyContent: 'flex-start',
        }}
      >
        <Button
          onClick={onSubmit}
          color="secondary"
          variant="contained"
          style={{
            color: 'white',
            borderRadius: 20,
          }}
        >
          Update
          {myOfferUpdateLoading && (
            <Box marginLeft={2} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" size={16} />
            </Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
