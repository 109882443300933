import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export interface LoadingProps {
  text?: string;
  color?: 'inherit' | 'primary' | 'secondary';
  size?: number;
}

export const Loading: React.FC<LoadingProps> = (props) => {
  const { text = 'Loading', color = 'primary', size = 40 } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginBottom={4}>
      <Typography>{text}</Typography>

      <Box marginTop={2}>
        <CircularProgress color={color} size={size} />
      </Box>
    </Box>
  );
};
