import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const efcsService = (function () {
  const endpoint_get_efcs = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_efcs);
  };

  return {
    endpoint_get_efcs,
  };
})();
