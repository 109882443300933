import { axiosService } from '.';
import { apiUrls } from '../consts';
import { ICollege } from '../models';
import { getApiAccessToken } from '../utils';

export const collegeService = (function () {
  const endpoint_get_colleges = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_colleges);
  };

  const endpoint_update_college = async (payload: { collegeId: number; data: ICollege }) => {
    const { collegeId, data } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.put_college(collegeId), data);
  };

  return {
    endpoint_get_colleges,
    endpoint_update_college,
  };
})();
