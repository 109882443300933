import { createSelector } from 'reselect';

import { ICreditScoreRangesState, IRootState } from '../../models';

export const selectCreditScoreRangesState = (rootState: IRootState): ICreditScoreRangesState =>
  rootState.creditScoreRangesState;

export const selectCreditScoreRanges = createSelector(
  [selectCreditScoreRangesState],
  (creditScoreRangesState) => creditScoreRangesState.creditScoreRanges,
);
