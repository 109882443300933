import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';

import { ICollege } from '../models';
import {
  selectMyLoanOffersSelectedCollegeId,
  selectMyLoanOffersSelectedYear,
  selectMyOffersColleges,
} from '../store/selectors';
import { loanOffersAction } from '../store/actions';
import { getYearsList } from '../utils';

interface MyLoanOffersFilterProps {}

export const MyLoanOffersFilter: React.FC<MyLoanOffersFilterProps> = () => {
  const dispatch = useDispatch();
  const colleges = useSelector(selectMyOffersColleges);
  const myLoanOffersSelectedCollegeId = useSelector(selectMyLoanOffersSelectedCollegeId);
  const myLoanOffersSelectedYear = useSelector(selectMyLoanOffersSelectedYear);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const definedYears = getYearsList();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Autocomplete
          value={[
            {
              id: -1,
              name: 'All',
            } as ICollege,
            ...colleges,
          ].find((item) => item.id === myLoanOffersSelectedCollegeId)}
          options={[
            {
              id: -1,
              name: 'All',
            } as ICollege,
            ...colleges,
          ]}
          getOptionLabel={(item) => (item.id === -1 ? 'All' : `${item.name} - ${item.stateCode}`)}
          onChange={(event: any, newValue: any) => {
            const collegeId = newValue?.id;
            dispatch(loanOffersAction.setMyLoanOffersSelectedCollegeId(collegeId));
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                style={
                  option.id === -1
                    ? {
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: theme.palette.divider,
                      }
                    : {}
                }
              >
                {option.id === -1 ? option.name : `${option.name} - ${option.stateCode}`}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Name of College"
              placeholder="Select a College"
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            />
          )}
          disableClearable
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Autocomplete
          value={[
            {
              id: -1,
              value: -1,
              label: 'All',
            },
            ...definedYears,
          ].find((year) => year.id === myLoanOffersSelectedYear)}
          options={[
            {
              id: -1,
              value: -1,
              label: 'All',
            },
            ...definedYears,
          ]}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                style={
                  option.id === -1
                    ? {
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: theme.palette.divider,
                      }
                    : {}
                }
              >
                {option.label}
              </li>
            );
          }}
          onChange={(event: any, newValue: any) => {
            const year = newValue?.id;
            dispatch(loanOffersAction.setMyLoanOffersSelectedYear(year));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Year"
              placeholder="Year"
              size={windowSize.width < theme.breakpoints.values.sm ? 'small' : 'medium'}
            />
          )}
          disableClearable
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
