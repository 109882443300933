import { produce } from 'immer';

import { createReducer } from '.';
import { efcsActionTypes } from '../../consts';
import { IAction, IEfcsState } from '../../models';
import { efcsState } from '../states/efcs.state';

export const efcsReducer = createReducer<IEfcsState>(efcsState, {
  [efcsActionTypes.SET_EFCS]: setEfcs,
});

function setEfcs(efc: IEfcsState, { payload }: IAction<Array<any>>) {
  return produce(efc, (draft) => {
    draft.efcs = payload;
  });
}
