import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { dialogsAction, offersAction } from '../store/actions';
import { selectLoadingState, selectDialogsState, selectOffersSelectedOffer } from '../store/selectors';
import { palette } from '../configs';

interface PublicOfferChangeDialogProps {}

export const PublicOfferChangeDialog: React.FC<PublicOfferChangeDialogProps> = () => {
  const dispatch = useDispatch();
  const { publicOfferChangeDialog = false } = useSelector(selectDialogsState);
  const { publicOfferUpdatingFlag = false } = useSelector(selectLoadingState);
  const offer = useSelector(selectOffersSelectedOffer);

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      dispatch(
        dialogsAction.updateDialogsStatus({
          publicOfferChangeDialog: false,
        }),
      );
    }
  };

  const onSubmit = () => {
    dispatch(offersAction.updateOfferPublished());
  };

  return (
    <Dialog fullWidth maxWidth={'sm'} scroll={'paper'} onClose={onClose} open={publicOfferChangeDialog}>
      <DialogTitle
        style={{
          color: palette.primary.dark,
          fontFamily: 'Montserrat',
          fontWeight: 600,
        }}
      >
        Confirm
      </DialogTitle>

      <DialogContent>
        {offer?.published ? (
          <Box paddingBottom={2}>
            <Typography color="textSecondary" gutterBottom>
              Are you sure you want to remove this offer for {offer?.collegeName} from top offers list?
            </Typography>
            <Typography color="textSecondary">This offer will be removed in the homepage top offers list.</Typography>
          </Box>
        ) : (
          <Box paddingBottom={2}>
            <Typography color="textSecondary" gutterBottom>
              Are you sure you want to publish this offer for {offer?.collegeName}?
            </Typography>
            <Typography color="textSecondary">This offer will be shown in the homepage top offers list.</Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          borderTop: '1px solid #E4E6ED',
          paddingY: 1.5,
          paddingX: 3,
        }}
      >
        <Button
          onClick={onSubmit}
          color="secondary"
          variant="contained"
          style={{
            color: 'white',
            marginRight: 8,
            borderRadius: 20,
          }}
        >
          Confirm
          {publicOfferUpdatingFlag && (
            <Box marginLeft={2} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" size={16} />
            </Box>
          )}
        </Button>

        <Button style={{ borderRadius: 20 }} onClick={() => onClose(null, 'anyway')} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
