import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Loading } from '../components';
import { ROUTES } from '../consts';
import { AuthAdminLayout } from '../layouts';
import { DashboardPage, UsersPage, CollegesPage, OffersPage, LoanOffersPage } from '../pages';
import { loanOffersAction, offersAction, usersAction } from '../store/actions';
import { selectLoadingState } from '../store/selectors';

interface AdminNavigatorProps {}

export const AdminNavigator: React.FC<AdminNavigatorProps> = () => {
  const dispatch = useDispatch();
  const {
    getUsersLoading = false,
    getCollegesLoading = false,
    getOffersLoading = false,
  } = useSelector(selectLoadingState);

  React.useEffect(() => {
    dispatch(usersAction.getUsers());
    dispatch(offersAction.getAllOffers());
    dispatch(offersAction.getOffersAccepted());
    dispatch(offersAction.getOffersPublished());
    dispatch(loanOffersAction.getLoanOffers());
  }, []);

  if (getUsersLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading text="Loading users..." />
      </Box>
    );
  }

  if (getCollegesLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading text="Loading colleges..." />
      </Box>
    );
  }

  if (getOffersLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading text="Loading offers..." />
      </Box>
    );
  }

  return (
    <AuthAdminLayout>
      <Routes>
        <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
        <Route path={ROUTES.USERS} element={<UsersPage />} />
        <Route path={ROUTES.COLLEGES} element={<CollegesPage />} />
        <Route path={ROUTES.OFFERS} element={<OffersPage />} />
        <Route path={ROUTES.LOAN_OFFERS} element={<LoanOffersPage />} />
        <Route path="/*" element={<Navigate replace to={ROUTES.DASHBOARD} />} />
      </Routes>
    </AuthAdminLayout>
  );
};
