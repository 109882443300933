import { createSelector } from 'reselect';

import { IEfcsState, IRootState } from '../../models';
import { getEfcName } from '../../utils';

export const selectEfcsState = (rootState: IRootState): IEfcsState => rootState.efcsState;

export const selectEfcs = createSelector([selectEfcsState], (efcsState) =>
  efcsState.efcs.map((item) => ({
    ...item,
    name: getEfcName(item.min, item.max),
  })),
);
