import { createSelector } from 'reselect';

import { IAdmissionTypesState, IRootState } from '../../models';

export const selectAdmissionTypesState = (rootState: IRootState): IAdmissionTypesState => rootState.admissionTypesState;

export const selectAdmissionTypes = createSelector(
  [selectAdmissionTypesState],
  (admissionTypesState) => admissionTypesState.admissionTypes,
);
