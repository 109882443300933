import * as React from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Popover, useTheme } from '@mui/material';

import { IEnhancedTableCheckboxFilter } from '../models';

export interface EnhancedTableCheckboxFilterProps {
  anchorEl: HTMLButtonElement | null;
  anchorId: string;
  checkboxFilters: IEnhancedTableCheckboxFilter;
  handleClose: () => void;
  onItem: (filterName: string, key: string, value: boolean) => void;
  onSelectAll: (filterName: string) => void;
  onUnselectAll: (filterName: string) => void;
}

export const EnhancedTableCheckboxFilter: React.FC<EnhancedTableCheckboxFilterProps> = (props) => {
  const { anchorEl, anchorId, checkboxFilters, handleClose, onItem, onSelectAll, onUnselectAll } = props;
  const theme = useTheme();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {checkboxFilters && checkboxFilters[anchorId] && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingY: 1.5,
              paddingX: 2,
              borderBottomWidth: 1,
              borderBottomColor: theme.palette.divider,
              borderBottomStyle: 'solid',
              position: 'sticky',
              top: 0,
              bgcolor: 'white',
              zIndex: 10,
            }}
          >
            <Button style={{ borderRadius: 20 }} color="primary" size="small" onClick={() => onSelectAll(anchorId)}>
              Reset
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className="ml-2"
              onClick={() => onUnselectAll(anchorId)}
              sx={{
                marginLeft: 1,
              }}
              style={{ borderRadius: 20, color: 'white' }}
            >
              Uncheck all
            </Button>
          </Box>

          <Box
            sx={{
              paddingX: 2,
              paddingY: 1,
            }}
          >
            <FormControl component="fieldset">
              <FormGroup>
                {Object.entries(checkboxFilters[anchorId]).map(([key, value], index) => (
                  <FormControlLabel
                    key={`filter-checkbox-${index}`}
                    control={
                      <Checkbox color="primary" checked={value} onChange={() => onItem(anchorId, key, !value)} />
                    }
                    label={key}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </>
      )}
    </Popover>
  );
};
