import * as React from 'react';
import { Box, Button, Checkbox, IconButton, TableBody, TableCell } from '@mui/material';
import { Delete, Edit, Send } from '@mui/icons-material';

import { ETableOrder, IEnhancedTableHeadCell } from '../models';
import { getComparator, stableSort } from '../utils';
import { EnhancedTableRow } from '.';

export interface EnhancedTableBodyProps {
  checkable?: boolean;
  actionVisible?: boolean;
  data: Array<any>;
  convertFunctionForDataToTableData?: Function;
  columns: Array<IEnhancedTableHeadCell>;
  columnKeys: Array<string>;
  order: ETableOrder;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  selected: Array<string>;
  setSelected: Function;
  actions: {
    onRow?: Function;
    onEdit?: Function;
    onDelete?: Function;
    onReview?: Function;
  };
}

export const EnhancedTableBody: React.FC<EnhancedTableBodyProps> = (props) => {
  const {
    checkable,
    actionVisible,
    data,
    convertFunctionForDataToTableData,
    columns,
    columnKeys,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
    setSelected,
    actions,
  } = props;

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  return (
    <TableBody className="cursor-pointer">
      {(
        (convertFunctionForDataToTableData || ((data: any) => data))(stableSort(data, getComparator(order, orderBy))) ||
        []
      )
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: any, index: number) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <EnhancedTableRow
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={`enhanced-table-row-${index}`}
              selected={isItemSelected}
              onClick={() => (actions?.onRow ? actions.onRow(Number(row.id)) : null)}
              className={row.className ? row.className : ''}
            >
              {checkable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleClick(e, row.id);
                    }}
                  />
                </TableCell>
              )}

              {columnKeys.map((key, index) => (
                <TableCell
                  key={`table-cell-${key}`}
                  padding={columns.find((column) => column.id === key)?.disablePadding ? 'none' : 'normal'}
                  align={columns[index].cellAlign || columns[index].align}
                  style={{
                    width: columns.find((column) => column.id === key)?.width
                      ? columns.find((column) => column.id === key)?.width
                      : 'undefined',
                  }}
                >
                  {row[key]}
                </TableCell>
              ))}

              {actionVisible && row.action && row.action.length > 0 && (
                <TableCell align="center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {row.action.map((action: string, index: number) => {
                      switch (action) {
                        case 'edit': {
                          return (
                            <IconButton
                              key={`users-table-row-action-${index}`}
                              color="secondary"
                              onClick={() => (actions?.onEdit ? actions.onEdit(Number(row.id)) : null)}
                            >
                              <Edit fontSize="inherit" />
                            </IconButton>
                          );
                        }
                        case 'delete': {
                          return (
                            <IconButton
                              key={`users-table-row-action-${index}`}
                              color="secondary"
                              onClick={() => (actions?.onDelete ? actions.onDelete(Number(row.id)) : null)}
                            >
                              <Delete fontSize="inherit" />
                            </IconButton>
                          );
                        }
                        case 'send': {
                          return (
                            <IconButton key={`users-table-row-action-${index}`} color="secondary">
                              <Send fontSize="inherit" />
                            </IconButton>
                          );
                        }
                        case 'review': {
                          return (
                            <Button
                              key={`users-table-row-action-${index}`}
                              color="secondary"
                              variant="contained"
                              size="small"
                              onClick={() => (actions?.onReview ? actions.onReview(Number(row.id)) : null)}
                            >
                              Review
                            </Button>
                          );
                        }
                        default: {
                          return null;
                        }
                      }

                      return null;
                    })}
                  </Box>
                </TableCell>
              )}
            </EnhancedTableRow>
          );
        })}
    </TableBody>
  );
};
