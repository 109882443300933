import * as React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { VisibilityOutlined, ArticleOutlined, Search } from '@mui/icons-material';

import HomeBg1 from '../../assets/images/home-bg-1.png';
import { EnhancedTable } from '../../components';
import { palette, topOffersTableColumns } from '../../configs';
import { AuthSignupForm } from '../../forms';
import { convertTopOffersToTableData } from '../../utils';
import { selectPublicOffers } from '../../store/selectors';

interface HomePageProps {}

export const HomePage: React.FC<HomePageProps> = () => {
  const theme = useTheme();
  const windowSize = useWindowSize();

  const topOffers = useSelector(selectPublicOffers);
  const columns = topOffersTableColumns;
  const columnKeys = columns.map((item) => item.id);

  return (
    <Box paddingTop={{ xs: 0, sm: 3 }} paddingBottom={{ xs: 0, md: 3 }} paddingX={{ xs: 0, md: 8 }}>
      <Box paddingX={2}>
        <Typography
          variant={windowSize.width < theme.breakpoints.values.md ? 'h5' : 'h4'}
          color="primary"
          align="center"
          sx={{
            fontWeight: 600,
            maxWidth: windowSize.width < theme.breakpoints.values.md ? 500 : 'unset',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontFamily: 'Montserrat',
            color: theme.palette.primary.contrastText,
          }}
        >
          Compare College and Loan Offers | A Crowdsourced Solution to Getting the Best Deal
        </Typography>

        <Box marginTop={3} maxWidth={768} marginX="auto">
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  style={{
                    width: 80,
                    height: 80,
                    borderStyle: 'solid',
                    borderWidth: 3,
                    borderColor: theme.palette.primary.main,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ArticleOutlined
                    color="primary"
                    style={{
                      fontSize: 48,
                    }}
                  />
                </Box>

                <Box marginTop={2} maxWidth={240}>
                  <Typography
                    color="primary"
                    align="center"
                    style={{ color: theme.palette.primary.dark, fontFamily: 'Montserrat', fontWeight: 600 }}
                  >
                    Enter your own offers. All data is anonymous!
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  style={{
                    width: 80,
                    height: 80,
                    borderStyle: 'solid',
                    borderWidth: 3,
                    borderColor: theme.palette.primary.main,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <VisibilityOutlined
                    color="primary"
                    style={{
                      fontSize: 48,
                    }}
                  />
                </Box>

                <Box marginTop={2} maxWidth={240}>
                  <Typography
                    color="primary"
                    align="center"
                    style={{ color: theme.palette.primary.dark, fontFamily: 'Montserrat', fontWeight: 600 }}
                  >
                    View all your offers & compare “apples to apples”
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  style={{
                    width: 80,
                    height: 80,
                    borderStyle: 'solid',
                    borderWidth: 3,
                    borderColor: theme.palette.primary.main,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Search
                    color="primary"
                    style={{
                      fontSize: 48,
                    }}
                  />
                </Box>

                <Box marginTop={2} maxWidth={240}>
                  <Typography
                    color="primary"
                    align="center"
                    style={{ color: theme.palette.primary.dark, fontFamily: 'Montserrat', fontWeight: 600 }}
                  >
                    Compare your offers to those received by others
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {topOffers.length > 0 && (
        <Box paddingTop={4} paddingX={{ xs: 2, md: 0 }}>
          <EnhancedTable
            config={{
              toolbar: true,
              rowCheckable: false,
              rowActionVisible: false,
            }}
            loading={false}
            loadingLabel={'Loading Top Offers'}
            noDataLabel={'No Offer'}
            noDataSectionClassnames={'h-24'}
            toolbarTitle={'Top Offers'}
            data={topOffers}
            convertFunctionForDataToTableData={convertTopOffersToTableData}
            headColumns={columns}
            columnKeys={columnKeys}
            rowActions={{}}
            styles={{
              tableStyle: {
                minWidth: 0,
              },
            }}
            initialRowsPerPage={5}
            visiblePagination={true}
            exportable={false}
            exporting={false}
            filename={''}
            getCsvHeaders={() => {}}
            getCsvData={() => {}}
            csvDataOtherProps={() => {}}
          />
        </Box>
      )}

      <Box
        marginTop={4}
        paddingY={4}
        paddingX={{ xs: 2, sm: 6, md: 12, lg: 6, xl: 12 }}
        position="relative"
        borderRadius={{ xs: 0, md: 1 }}
        overflow="hidden"
        style={{
          backgroundImage: `url(${HomeBg1})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          style={{
            backgroundColor: 'rgba(0,0,0,.5)',
          }}
        />

        <Grid container spacing={4} style={{ flexDirection: 'column', alignItems: 'center' }} position="relative">
          <Grid item xs={12} lg={6}>
            <Paper>
              <Box paddingY={4} paddingX={{ xs: 3, md: 8, lg: 3, xl: 8 }}>
                <Box>
                  <AuthSignupForm />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box>
              <Paper>
                <Box paddingY={4} paddingX={{ xs: 3, md: 8, lg: 3, xl: 8 }}>
                  <Typography variant="h6">No College Offers Yet?</Typography>
                  <Typography variant="subtitle1">
                    Get started with our college search tool{' '}
                    <a
                      href="https://collegeinsights.road2college.com"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: palette.primary.dark,
                        fontWeight: 600,
                      }}
                    >
                      College Insights
                    </a>{' '}
                    to view & compare data from thousands of US colleges.
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: 2,
                    }}
                  >
                    No Loan Offers Yet?
                  </Typography>
                  <Typography variant="subtitle1">
                    Check out our{' '}
                    <a
                      href="https://www.road2college.com/best-private-student-loans"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: palette.primary.dark,
                        fontWeight: 600,
                      }}
                    >
                      List of Lenders
                    </a>
                    .
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
