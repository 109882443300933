import { ERepaymentType, ETermOfLoan } from '../enums';

export const mockTopOffers = [
  {
    id: 4,
    userId: 5,
    collegeId: 504,
    admissionTypeId: 3,
    grantFinancialNeed: 0,
    grantFinancialNeedRenewable: false,
    grantNonFinancialNeed: 10000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 1,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 10001,
    totalSubsidizedFederalLoans: 0,
    totalUnsubsidiziedFederalLoans: 5500,
    totalCostOfAttendance: 48000,
    totalCostOfYou: 37999,
    workStudyProjectedEarning: null,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T19:18:48.000Z',
    updatedAt: '2020-01-23T00:48:48.000Z',
    admissionType: {
      name: 'Early Action',
    },
    college: {
      coaInState: 24491,
      coaOutState: 43212,
      coaPrivate: null,
      ipeds: 126818,
      name: 'Colorado State University',
      stateId: 6,
      state: {
        code: 'CO',
      },
    },
    user: {
      act: 29,
      athlete: false,
      efcId: 11,
      email: 'lilican2@gmail.com',
      entranceExam: true,
      gender: null,
      gpa: 3.94,
      role: 'parent',
      sat: null,
      stateId: 43,
      efc: {
        name: 'Above $60,000',
        min: 60000,
        max: null,
      },
      state: {
        code: 'TX',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Action',
    collegeCoaInState: 24491,
    collegeCoaOutState: 43212,
    collegeCoaPrivate: 0,
    collegeName: 'Colorado State University',
    collegeStateId: 6,
    collegeStateCode: 'CO',
    collegeNameWithState: 'Colorado State University - CO',
    userAct: 29,
    userEfcId: 11,
    userGpa: 3.94,
    userSat: 0,
    userStateId: 43,
    userEmail: 'lilican2@gmail.com',
    userEfcName: 'Above $60,000',
    userEfcMin: 60000,
    userEfcMax: 0,
    userStateCode: 'TX',
    offerCoa: 48000,
    netCost: 37999,
    grantFinancialNeedRenewableLabel: 'No',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/01/22',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
  {
    id: 5,
    userId: 5,
    collegeId: 3428,
    admissionTypeId: 3,
    grantFinancialNeed: 1000,
    grantFinancialNeedRenewable: true,
    grantNonFinancialNeed: 28000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 29000,
    totalSubsidizedFederalLoans: 0,
    totalUnsubsidiziedFederalLoans: 5500,
    totalCostOfAttendance: 60000,
    totalCostOfYou: 31000,
    workStudyProjectedEarning: null,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T19:21:00.000Z',
    updatedAt: '2020-01-23T00:51:00.000Z',
    admissionType: {
      name: 'Early Action',
    },
    college: {
      coaInState: null,
      coaOutState: null,
      coaPrivate: 61935,
      ipeds: 239017,
      name: 'Lawrence University',
      stateId: 49,
      state: {
        code: 'WI',
      },
    },
    user: {
      act: 29,
      athlete: false,
      efcId: 11,
      email: 'lilican2@gmail.com',
      entranceExam: true,
      gender: null,
      gpa: 3.94,
      role: 'parent',
      sat: null,
      stateId: 43,
      efc: {
        name: 'Above $60,000',
        min: 60000,
        max: null,
      },
      state: {
        code: 'TX',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Action',
    collegeCoaInState: 0,
    collegeCoaOutState: 0,
    collegeCoaPrivate: 61935,
    collegeName: 'Lawrence University',
    collegeStateId: 49,
    collegeStateCode: 'WI',
    collegeNameWithState: 'Lawrence University - WI',
    userAct: 29,
    userEfcId: 11,
    userGpa: 3.94,
    userSat: 0,
    userStateId: 43,
    userEmail: 'lilican2@gmail.com',
    userEfcName: 'Above $60,000',
    userEfcMin: 60000,
    userEfcMax: 0,
    userStateCode: 'TX',
    offerCoa: 60000,
    netCost: 31000,
    grantFinancialNeedRenewableLabel: 'Yes',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/01/22',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
  {
    id: 6,
    userId: 5,
    collegeId: 2598,
    admissionTypeId: 3,
    grantFinancialNeed: 0,
    grantFinancialNeedRenewable: false,
    grantNonFinancialNeed: 17000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 17000,
    totalSubsidizedFederalLoans: 0,
    totalUnsubsidiziedFederalLoans: 5500,
    totalCostOfAttendance: 67000,
    totalCostOfYou: 50000,
    workStudyProjectedEarning: null,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T19:22:20.000Z',
    updatedAt: '2020-02-18T07:07:29.000Z',
    admissionType: {
      name: 'Early Action',
    },
    college: {
      coaInState: null,
      coaOutState: null,
      coaPrivate: 57834,
      ipeds: 210401,
      name: 'Willamette University',
      stateId: 37,
      state: {
        code: 'OR',
      },
    },
    user: {
      act: 29,
      athlete: false,
      efcId: 11,
      email: 'lilican2@gmail.com',
      entranceExam: true,
      gender: null,
      gpa: 3.94,
      role: 'parent',
      sat: null,
      stateId: 43,
      efc: {
        name: 'Above $60,000',
        min: 60000,
        max: null,
      },
      state: {
        code: 'TX',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Action',
    collegeCoaInState: 0,
    collegeCoaOutState: 0,
    collegeCoaPrivate: 57834,
    collegeName: 'Willamette University',
    collegeStateId: 37,
    collegeStateCode: 'OR',
    collegeNameWithState: 'Willamette University - OR',
    userAct: 29,
    userEfcId: 11,
    userGpa: 3.94,
    userSat: 0,
    userStateId: 43,
    userEmail: 'lilican2@gmail.com',
    userEfcName: 'Above $60,000',
    userEfcMin: 60000,
    userEfcMax: 0,
    userStateCode: 'TX',
    offerCoa: 67000,
    netCost: 50000,
    grantFinancialNeedRenewableLabel: 'No',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/02/18',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
  {
    id: 7,
    userId: 5,
    collegeId: 2600,
    admissionTypeId: 3,
    grantFinancialNeed: 0,
    grantFinancialNeedRenewable: false,
    grantNonFinancialNeed: 38000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 38000,
    totalSubsidizedFederalLoans: 0,
    totalUnsubsidiziedFederalLoans: 5500,
    totalCostOfAttendance: 64000,
    totalCostOfYou: 26000,
    workStudyProjectedEarning: null,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T19:24:07.000Z',
    updatedAt: '2020-01-23T00:54:07.000Z',
    admissionType: {
      name: 'Early Action',
    },
    college: {
      coaInState: null,
      coaOutState: null,
      coaPrivate: 65950,
      ipeds: 210669,
      name: 'Allegheny College',
      stateId: 38,
      state: {
        code: 'PA',
      },
    },
    user: {
      act: 29,
      athlete: false,
      efcId: 11,
      email: 'lilican2@gmail.com',
      entranceExam: true,
      gender: null,
      gpa: 3.94,
      role: 'parent',
      sat: null,
      stateId: 43,
      efc: {
        name: 'Above $60,000',
        min: 60000,
        max: null,
      },
      state: {
        code: 'TX',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Action',
    collegeCoaInState: 0,
    collegeCoaOutState: 0,
    collegeCoaPrivate: 65950,
    collegeName: 'Allegheny College',
    collegeStateId: 38,
    collegeStateCode: 'PA',
    collegeNameWithState: 'Allegheny College - PA',
    userAct: 29,
    userEfcId: 11,
    userGpa: 3.94,
    userSat: 0,
    userStateId: 43,
    userEmail: 'lilican2@gmail.com',
    userEfcName: 'Above $60,000',
    userEfcMin: 60000,
    userEfcMax: 0,
    userStateCode: 'TX',
    offerCoa: 64000,
    netCost: 26000,
    grantFinancialNeedRenewableLabel: 'No',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/01/22',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
  {
    id: 8,
    userId: 15,
    collegeId: 1374,
    admissionTypeId: 3,
    grantFinancialNeed: 21400,
    grantFinancialNeedRenewable: true,
    grantNonFinancialNeed: 25000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 46400,
    totalSubsidizedFederalLoans: 3500,
    totalUnsubsidiziedFederalLoans: 2000,
    totalCostOfAttendance: 63010,
    totalCostOfYou: 16610,
    workStudyProjectedEarning: 3500,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T20:42:25.000Z',
    updatedAt: '2020-01-23T02:12:25.000Z',
    admissionType: {
      name: 'Early Action',
    },
    college: {
      coaInState: null,
      coaOutState: null,
      coaPrivate: 58990,
      ipeds: 167783,
      name: 'Simmons College',
      stateId: 21,
      state: {
        code: 'MA',
      },
    },
    user: {
      act: null,
      athlete: false,
      efcId: 1,
      email: 'cgradford@hotmail.com',
      entranceExam: true,
      gender: null,
      gpa: 4,
      role: 'parent',
      sat: 1160,
      stateId: 21,
      efc: {
        name: 'Under $15,000',
        min: null,
        max: 15000,
      },
      state: {
        code: 'MA',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Action',
    collegeCoaInState: 0,
    collegeCoaOutState: 0,
    collegeCoaPrivate: 58990,
    collegeName: 'Simmons College',
    collegeStateId: 21,
    collegeStateCode: 'MA',
    collegeNameWithState: 'Simmons College - MA',
    userAct: 0,
    userEfcId: 1,
    userGpa: 4,
    userSat: 1160,
    userStateId: 21,
    userEmail: 'cgradford@hotmail.com',
    userEfcName: 'Under $15,000',
    userEfcMin: 0,
    userEfcMax: 15000,
    userStateCode: 'MA',
    offerCoa: 63010,
    netCost: 16610,
    grantFinancialNeedRenewableLabel: 'Yes',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/01/22',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
  {
    id: 9,
    userId: 20,
    collegeId: 2453,
    admissionTypeId: 1,
    grantFinancialNeed: 0,
    grantFinancialNeedRenewable: false,
    grantNonFinancialNeed: 3000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 3000,
    totalSubsidizedFederalLoans: 0,
    totalUnsubsidiziedFederalLoans: 0,
    totalCostOfAttendance: 23000,
    totalCostOfYou: 20000,
    workStudyProjectedEarning: null,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T21:23:02.000Z',
    updatedAt: '2020-01-23T02:53:02.000Z',
    admissionType: {
      name: 'Early Decision I',
    },
    college: {
      coaInState: 39619,
      coaOutState: 61603,
      coaPrivate: null,
      ipeds: 204796,
      name: 'Ohio State University-Main Campus',
      stateId: 35,
      state: {
        code: 'OH',
      },
    },
    user: {
      act: 35,
      athlete: false,
      efcId: 2,
      email: 'samwyatt26@aol.com',
      entranceExam: true,
      gender: null,
      gpa: 4,
      role: 'parent',
      sat: null,
      stateId: 35,
      efc: {
        name: '$15,001 - $20,000',
        min: 15001,
        max: 20000,
      },
      state: {
        code: 'OH',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Decision I',
    collegeCoaInState: 39619,
    collegeCoaOutState: 61603,
    collegeCoaPrivate: 0,
    collegeName: 'Ohio State University-Main Campus',
    collegeStateId: 35,
    collegeStateCode: 'OH',
    collegeNameWithState: 'Ohio State University-Main Campus - OH',
    userAct: 35,
    userEfcId: 2,
    userGpa: 4,
    userSat: 0,
    userStateId: 35,
    userEmail: 'samwyatt26@aol.com',
    userEfcName: '$15,001 - $20,000',
    userEfcMin: 15001,
    userEfcMax: 20000,
    userStateCode: 'OH',
    offerCoa: 23000,
    netCost: 20000,
    grantFinancialNeedRenewableLabel: 'No',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/01/22',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
  {
    id: 10,
    userId: 20,
    collegeId: 1131,
    admissionTypeId: 1,
    grantFinancialNeed: 0,
    grantFinancialNeedRenewable: false,
    grantNonFinancialNeed: 10000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 10000,
    totalSubsidizedFederalLoans: 0,
    totalUnsubsidiziedFederalLoans: 0,
    totalCostOfAttendance: 38000,
    totalCostOfYou: 28000,
    workStudyProjectedEarning: null,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T21:24:22.000Z',
    updatedAt: '2020-01-23T02:54:22.000Z',
    admissionType: {
      name: 'Early Decision I',
    },
    college: {
      coaInState: 20022,
      coaOutState: 38824,
      coaPrivate: null,
      ipeds: 243780,
      name: 'Purdue University-Main Campus',
      stateId: 14,
      state: {
        code: 'IN',
      },
    },
    user: {
      act: 35,
      athlete: false,
      efcId: 2,
      email: 'samwyatt26@aol.com',
      entranceExam: true,
      gender: null,
      gpa: 4,
      role: 'parent',
      sat: null,
      stateId: 35,
      efc: {
        name: '$15,001 - $20,000',
        min: 15001,
        max: 20000,
      },
      state: {
        code: 'OH',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Decision I',
    collegeCoaInState: 20022,
    collegeCoaOutState: 38824,
    collegeCoaPrivate: 0,
    collegeName: 'Purdue University-Main Campus',
    collegeStateId: 14,
    collegeStateCode: 'IN',
    collegeNameWithState: 'Purdue University-Main Campus - IN',
    userAct: 35,
    userEfcId: 2,
    userGpa: 4,
    userSat: 0,
    userStateId: 35,
    userEmail: 'samwyatt26@aol.com',
    userEfcName: '$15,001 - $20,000',
    userEfcMin: 15001,
    userEfcMax: 20000,
    userStateCode: 'OH',
    offerCoa: 38000,
    netCost: 28000,
    grantFinancialNeedRenewableLabel: 'No',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/01/22',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
  {
    id: 11,
    userId: 21,
    collegeId: 1381,
    admissionTypeId: 3,
    grantFinancialNeed: 0,
    grantFinancialNeedRenewable: false,
    grantNonFinancialNeed: 16000,
    grantNonFinancialNeedRenewable: true,
    stackable: false,
    totalPellAndStateGrants: 0,
    totalPrivateScholarship: null,
    totalGrantsAndScholarship: 16000,
    totalSubsidizedFederalLoans: 5500,
    totalUnsubsidiziedFederalLoans: 0,
    totalCostOfAttendance: 50769,
    totalCostOfYou: 34769,
    workStudyProjectedEarning: null,
    entraneExam: true,
    acceptMoney: false,
    honorsProgram: false,
    year: 2020,
    createdAt: '2020-01-22T21:39:47.000Z',
    updatedAt: '2020-01-23T03:09:47.000Z',
    admissionType: {
      name: 'Early Action',
    },
    college: {
      coaInState: 29871,
      coaOutState: 49859,
      coaPrivate: null,
      ipeds: 166629,
      name: 'University of Massachusetts-Amherst',
      stateId: 21,
      state: {
        code: 'MA',
      },
    },
    user: {
      act: 35,
      athlete: false,
      efcId: 11,
      email: 'Krystn1940@aol.com',
      entranceExam: true,
      gender: null,
      gpa: 3.93,
      role: 'parent',
      sat: null,
      stateId: 30,
      efc: {
        name: 'Above $60,000',
        min: 60000,
        max: null,
      },
      state: {
        code: 'NJ',
      },
      highschool: null,
    },
    admissionTypeName: 'Early Action',
    collegeCoaInState: 29871,
    collegeCoaOutState: 49859,
    collegeCoaPrivate: 0,
    collegeName: 'University of Massachusetts-Amherst',
    collegeStateId: 21,
    collegeStateCode: 'MA',
    collegeNameWithState: 'University of Massachusetts-Amherst - MA',
    userAct: 35,
    userEfcId: 11,
    userGpa: 3.93,
    userSat: 0,
    userStateId: 30,
    userEmail: 'Krystn1940@aol.com',
    userEfcName: 'Above $60,000',
    userEfcMin: 60000,
    userEfcMax: 0,
    userStateCode: 'NJ',
    offerCoa: 50769,
    netCost: 34769,
    grantFinancialNeedRenewableLabel: 'No',
    grantNonFinancialNeedRenewableLabel: 'Yes',
    stackableLabel: 'No',
    acceptMoneyLabel: 'No',
    honorsProgramLabel: 'No',
    yearLabel: '2020 - 2021',
    updatedAtLabel: '2020/01/22',
    accepted: false,
    acceptedLabel: 'Unverified',
  },
];

export const mockLoanOffers = [
  {
    collegeId: 1,
    lenderId: 1,
    interestRate: 10,
    termOfLoan: ETermOfLoan.FIXED,
    lifeOfLoan: 5,
    repaymentType: ERepaymentType.IMMEDIATE,
    coSignor: true,
    isPublic: true,
    lender: {
      id: 1,
      name: 'Test Lender',
    },
  },
  {
    collegeId: 1,
    lenderId: 1,
    interestRate: 10,
    termOfLoan: ETermOfLoan.VARIABLE,
    lifeOfLoan: 5,
    repaymentType: ERepaymentType.IMMEDIATE,
    coSignor: true,
    isPublic: true,
    lender: {
      id: 1,
      name: 'Test Lender',
    },
  },
  {
    collegeId: 1,
    lenderId: 1,
    interestRate: 10,
    termOfLoan: ETermOfLoan.FIXED,
    lifeOfLoan: 5,
    repaymentType: ERepaymentType.FULLY_DEFERRED,
    coSignor: true,
    isPublic: true,
    lender: {
      id: 1,
      name: 'Test Lender',
    },
  },
  {
    collegeId: 1,
    lenderId: 1,
    interestRate: 10,
    termOfLoan: ETermOfLoan.FIXED,
    lifeOfLoan: 5,
    repaymentType: ERepaymentType.PARTIAL_PAYMENT,
    coSignor: true,
    isPublic: true,
    lender: {
      id: 1,
      name: 'Test Lender',
    },
  },
  {
    collegeId: 1,
    lenderId: 1,
    interestRate: 10,
    termOfLoan: ETermOfLoan.VARIABLE,
    lifeOfLoan: 5,
    repaymentType: ERepaymentType.INTEREST_ONLY,
    coSignor: true,
    isPublic: true,
    lender: {
      id: 1,
      name: 'Test Lender',
    },
  },
];
