import * as React from 'react';
import { Badge, Box, Checkbox, IconButton, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilterList, Search } from '@mui/icons-material';

import { ETableFilterType } from '../enums';
import { IEnhancedTableHeadCell, ETableOrder, IEnhancedTableCheckboxFilter, IEnhancedTableTextFilter } from '../models';
import { isAppliedCheckboxFilter, isAppliedTextFilter } from '../utils';
import { EnhancedTableCheckboxFilter, EnhancedTableTextFilter } from '.';

export interface EnhancedTableHeadProps {
  checkable?: boolean;
  actionVisible?: boolean;
  columns: Array<IEnhancedTableHeadCell>;
  defaultCheckboxFilters: IEnhancedTableCheckboxFilter;
  checkboxFilters: IEnhancedTableCheckboxFilter;
  textFilters: IEnhancedTableTextFilter;
  numSelected: number;
  order: ETableOrder;
  orderBy: string;
  rowCount: number;
  onCheckboxFilterItem: (filterName: string, key: string, value: boolean) => void;
  onCheckboxFilterSelectAll: (filterName: string) => void;
  onCheckboxFilterUnselectAll: (filterName: string) => void;
  onChangeTextFilter: (filterName: string, value: string) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classNames?: any;
}

export const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const {
    checkable,
    actionVisible,
    columns,
    defaultCheckboxFilters,
    checkboxFilters,
    textFilters,
    order,
    orderBy,
    numSelected,
    rowCount,
    onCheckboxFilterItem,
    onCheckboxFilterSelectAll,
    onCheckboxFilterUnselectAll,
    onChangeTextFilter,
    onSelectAllClick,
    onRequestSort,
    classNames,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorId, setAnchorId] = React.useState<string>('');
  const [anchorFilterType, setAnchorFilterType] = React.useState<ETableFilterType | null>();
  const [anchorLabel, setAnchorLabel] = React.useState<string | React.ReactNode>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, headCell: IEnhancedTableHeadCell) => {
    setAnchorId(headCell.id);
    setAnchorEl(event.currentTarget);
    setAnchorFilterType(
      headCell.checkboxFilter ? ETableFilterType.CHECKBOX : headCell.textFilter ? ETableFilterType.TEXT : null,
    );
    setAnchorLabel(headCell.label);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead className="bg-gray-100">
        <TableRow>
          {checkable && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
          )}

          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={`whitespace-nowrap ${headCell.classes ? headCell.classes : ''}`}
              style={{
                minWidth: columns.find((column) => column.id === headCell.id)?.width
                  ? columns.find((column) => column.id === headCell.id)?.width
                  : 'undefined',
              }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    className={classNames?.tableSortLabelClassName || ''}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    sx={{
                      flexDirection: headCell.align === 'right' ? 'row-reverse' : 'unset',
                    }}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
                {headCell.checkboxFilter && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      component="span"
                      size="small"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, headCell)}
                    >
                      <Badge
                        color="secondary"
                        variant="dot"
                        invisible={!isAppliedCheckboxFilter(defaultCheckboxFilters, checkboxFilters, headCell.id)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <FilterList fontSize="inherit" />
                      </Badge>
                    </IconButton>
                  </Box>
                )}
                {headCell.textFilter && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      component="span"
                      size="small"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, headCell)}
                    >
                      <Badge
                        color="secondary"
                        variant="dot"
                        invisible={!isAppliedTextFilter(textFilters, headCell.id)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <Search fontSize="inherit" />
                      </Badge>
                    </IconButton>
                  </Box>
                )}
              </Box>
            </TableCell>
          ))}

          {actionVisible && (
            <TableCell
              style={{
                color: '#030054',
                fontFamily: 'Montserrat',
                fontSize: '16px',
              }}
              align={'center'}
            >
              Actions
            </TableCell>
          )}
        </TableRow>
      </TableHead>

      {anchorFilterType === ETableFilterType.CHECKBOX && (
        <EnhancedTableCheckboxFilter
          anchorEl={anchorEl}
          anchorId={anchorId}
          checkboxFilters={checkboxFilters}
          handleClose={handleClose}
          onItem={onCheckboxFilterItem}
          onSelectAll={onCheckboxFilterSelectAll}
          onUnselectAll={onCheckboxFilterUnselectAll}
        />
      )}

      {anchorFilterType === ETableFilterType.TEXT && (
        <EnhancedTableTextFilter
          anchorEl={anchorEl}
          anchorId={anchorId}
          anchorLabel={anchorLabel}
          textFilters={textFilters}
          handleClose={handleClose}
          updateText={onChangeTextFilter}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  visuallyHidden: {
    '&&': {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  },
});
